import { buildQueueDefinition } from '@guiker/queue'

import { config } from '../config'
import { ListingInquiry } from '../entity'

export const Queue = {
  GroupListingInquiriesToRentalInquiry: buildQueueDefinition({
    name: 'GroupListingInquiriesToRentalInquiry',
    serviceName: config.apiName,
    payload: null as ListingInquiry[],
    batchSize: 1,
  }),
} as const
