type Longitude = number
type Latitude = number

export type Geocoordinates = {
  lat: number
  lng: number
}

export type Geobox = {
  bottomLeft: Geocoordinates
  topRight: Geocoordinates
}

export type MongoGeocoordinates = [Longitude, Latitude]
export type GeocoordinatesArray = [Latitude, Longitude]

export enum GeoJSONType {
  'POINT' = 'Point',
}

export type GeoSpatialLocation = {
  type?: GeoJSONType.POINT
  coordinates: MongoGeocoordinates //[longitude, latitude] opposite of google
}
