import { routesBuilder } from '@guiker/shared-framework'

import { Stock, StockOrderStats, StockWithOrders, StockWriteModel } from '../../../../entity'
import { paths } from '../../paths'
import {
  createSeriesSchema,
  delistStockSchema,
  updateClosedSeriesSchema,
  updatePropertyBuilding,
  updatePropertyDetails,
  updateSeriesAndGenerateTargetSchema,
  updateSeriesFinancialInfoFinancialsSchema,
  updateSeriesFinancialInfoRental,
  updateSeriesSchema,
  updateStockSchema,
} from '../../schemas'

const { buildGet, buildPut, buildPost } = routesBuilder<Stock, StockWriteModel>()({
  basePath: paths.stocks.withId.path,
})

export const routes = {
  delistStock: buildPut({
    path: '/delist',
    payloadSchema: delistStockSchema,
  }),
  getStatsOverviewByStockId: buildGet({
    path: '/stats-overview',
    response: null as StockOrderStats,
    responseIsArray: true,
  }),
  readOneStock: buildGet({
    path: '/read',
    response: null as StockWithOrders,
  }),
  updateStock: buildPut({
    payloadSchema: updateStockSchema,
  }),
  updatePropertyBuilding: buildPut({
    payloadSchema: updatePropertyBuilding,
  }),
  updatePropertyDetails: buildPut({
    path: '/property-details',
    payloadSchema: updatePropertyDetails,
  }),
  updateSeriesFinancialInfoRental: buildPut({
    path: '/series-financial-rental',
    payloadSchema: updateSeriesFinancialInfoRental,
  }),
  initiateCloseSeries: buildPost({
    path: `/series/:seriesSlug/close`,
  }),
  updateSeries: buildPut({
    path: `/series/:seriesSlug`,
    payloadSchema: updateSeriesSchema,
  }),
  updateSeriesFinancialInfoFinancials: buildPut({
    path: `/series/:seriesSlug/financials`,
    payloadSchema: updateSeriesFinancialInfoFinancialsSchema,
  }),
  updateSeriesAndGenerateTarget: buildPut({
    path: `/series/:seriesSlug/update-and-generate-target`,
    payloadSchema: updateSeriesAndGenerateTargetSchema,
  }),
  updateClosedSeries: buildPut({
    path: `/series/:seriesSlug/closed`,
    payloadSchema: updateClosedSeriesSchema,
  }),
  createSeries: buildPost({
    path: `/series/new`,
    payloadSchema: createSeriesSchema,
  }),
}
