import React from 'react'

import { DepositType, FundingAccountDeposit } from '@guiker/payment-shared'
import { getColumnsBuilder, PSmaller, useDateFormatter } from '@guiker/react-framework'
import { getUserFullName, money } from '@guiker/shared-framework'

import { useT } from '../../i18n'
import { DepositStatusChip } from '../DepositStatusChip'
import { PayInMethodLabel } from '../PayInMethodLabel'

export const useGetColumns = getColumnsBuilder<FundingAccountDeposit>()((args?: { showNote?: boolean }) => {
  const { tShared, tBase } = useT({ entity: 'fundingAccount.deposit' })
  const { formatDate } = useDateFormatter()

  const getTypeLabel = (deposit: FundingAccountDeposit) => {
    switch (deposit.type) {
      case DepositType.FUNDING:
        return <PayInMethodLabel payInMethod={deposit.payInMethod} />
      case DepositType.UNITS_BUYOUT:
        return tShared('type.unitsBuyout', { tickerSymbol: deposit.metadata['tickerSymbol'] ?? 'property' })
      case DepositType.REFUND:
        return tShared('type.refund')
      case DepositType.CUSTOM:
        return deposit.metadata.note ?? tShared('noPayInMethod')
      default:
        return tShared('noPayInMethod')
    }
  }

  return [
    {
      label: tBase('user.name'),
      size: 1,
      name: 'user.lastName',
      verticalAlign: 'top',
      renderValue: (deposit) => getUserFullName(deposit.user),
    },
    {
      label: tShared('payInMethod'),
      size: 2,
      name: 'payInMethod.id',
      verticalAlign: 'top',
      renderValue: (deposit) => [
        getTypeLabel(deposit),
        args?.showNote && deposit.metadata?.note && <PSmaller mb={0}>{deposit.metadata?.note}</PSmaller>,
      ],
    },
    {
      label: tShared('amount'),
      name: 'amount',
      headerOptions: {
        textAlign: 'right',
      },
      verticalAlign: 'top',
      renderValue: (deposit) =>
        deposit.fundingAccount &&
        money
          .fromAmount(deposit.amount, money.currency[deposit.fundingAccount.currency])
          .toString({ currencySymbol: 'onlySymbol' }),
    },
    {
      name: 'status',
      label: tShared('status.title'),
      renderValue: (deposit) => {
        return <DepositStatusChip deposit={deposit} />
      },
    },
    {
      label: tShared('createdAt'),
      name: 'createdAt',
      verticalAlign: 'top',
      renderValue: (deposit) => formatDate(deposit.createdAt),
    },
  ] as const
})
