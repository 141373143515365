import { TFunction } from '@guiker/i18n'
import {
  Building,
  NEWBuilding,
  NEWProperty,
  NEWUnit,
  Orientation,
  Property,
  Unit,
  UnitType,
} from '@guiker/real-estate-shared'

export const findTotalSquareFootage = (units: (NEWUnit | Unit)[]): number =>
  units?.reduce((sum, unit) => sum + unit.squareFootage, 0)

export const findTotalNumberOfUnitType = (units: (NEWUnit | Unit)[], type: UnitType): number =>
  units?.reduce((count, unit) => {
    if (unit.type === type) return count + 1
    return count
  }, 0)

export const totalNumberOfUnits = (property: NEWProperty | Property): number => {
  const totalCalculatedUnits =
    findTotalNumberOfUnitType(property.units, UnitType.COMMERCIAL) +
    findTotalNumberOfUnitType(property.units, UnitType.RESIDENTIAL)
  return totalCalculatedUnits
}

export const showTotalNumberOfUnits = (property: NEWProperty | Property): boolean => totalNumberOfUnits(property) > 0

export const showTotalStoreys = (building: NEWBuilding | Building): boolean =>
  building.configuration?.totalStoreys && building.configuration.totalStoreys > 0

export const showBuildingOrientation = (building: NEWBuilding | Building): boolean =>
  building.configuration?.orientation && building.configuration.orientation !== Orientation.UNKNOWN

export const showTotalSquareFootage = (property: NEWProperty | Property): boolean => {
  const totalSquareFootage = (property as Property).totalSquareFootage

  return totalSquareFootage > 0 || (property.units?.length > 0 && findTotalSquareFootage(property.units) > 0)
}

export const printRoomConfiguration = ({ unit, t }: { t: TFunction; unit: NEWUnit | Unit }) => {
  const bedroomCount = (unit as NEWUnit).floorplan.bedroom || (unit as Unit).roomConfigurations.bedroomCount
  const bathroomCount = (unit as NEWUnit).floorplan.bathroom || (unit as Unit).roomConfigurations.bathroomCount

  const bedroomCountLabel =
    bedroomCount === 0
      ? t('listing:bedroomCount.studio')
      : t('listing:bedroomCount.nonStudio', {
          count: bedroomCount,
        })

  const bathroomCountLabel = t('listing:bathroomCount', {
    count: bathroomCount,
  })

  const roomCountLabel = `${bedroomCountLabel}${!!bathroomCount ? ` · ${bathroomCountLabel}` : ''}`
  return unit.squareFootage ? `${roomCountLabel} · ${unit.squareFootage} sq.ft` : roomCountLabel
}
