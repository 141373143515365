import { NEWProperty } from '@guiker/real-estate-shared'
import { CurrencyISO, EntityDocument } from '@guiker/shared-framework'

import { FinancialStatements } from './financial-statements'
import { Series } from './series'
import { StockOrder } from './stock-order'

export enum StockStatus {
  DRAFT = 'DRAFT',
  UNLISTED = 'UNLISTED', // not open on public market to transact
  LISTED = 'LISTED', // open on public market to transact
  DELISTED = 'DELISTED', // removed from public market
}

export enum StockDelistReason {
  LIQUIDATION = 'LIQUIDATION',
}

export type Stock = EntityDocument & {
  label: string
  tickerSymbol: string
  description?: string
  minPerTransaction?: number
  maxPerAccount?: number
  property: NEWProperty
  series?: Series[]
  status: StockStatus
  delistReason?: StockDelistReason
  currency: CurrencyISO
  latestFinancialStatements: FinancialStatements
}

export type StockWriteModel = Omit<Stock, 'property' | 'latestFinancialStatements'> & {
  property?: {
    id: string
    building?: {
      id: string
    }
  }
  __isCompleted?: boolean
}

export type WithStockOrders<T> = T & {
  stockOrders: StockOrder[]
}

export type StockWithOrders = WithStockOrders<Stock>
