import React, { createContext, useState } from 'react'

import { useAuthenticationModalContext } from '@guiker/authentication-components'
import { AuthType, useAuthenticationContext } from '@guiker/authentication-context'
import { User } from '@guiker/authentication-shared'
import { AuthenticatedApi, ListingInquiry } from '@guiker/listing-inquiry-shared'
import { generateUseContext, useQueryMutation, useTranslation } from '@guiker/react-framework'
import {
  initializeDropdownRentalOptions,
  isRoommatable,
  RentalListing,
  TransformedRentalOption,
} from '@guiker/rental-listing-shared'
import { DateTime, Roommate } from '@guiker/shared-framework'

import { useAuthenticatedListingInquiryApiClient, usePublicBookingApiClient } from './hooks'
import { formatInquiryMessage } from './utils'

type ListingInquiryContextProviderType = {
  listing: RentalListing
  children: React.ReactNode | RenderChildren
}

type Context = {
  selectedPriceOptionId: string
  setSelectedPriceOptionId: (priceOptionId: string) => void
  openInquiryCardModal: boolean
  setOpenInquiryCardModal: (open: boolean) => void
  inquirySubmitted: boolean
  listingInquiry: ListingInquiry
  setListingInquiry: (listingInquiry: ListingInquiry) => void
  setInquirySubmitted: (open: boolean) => void
  rentalOptions: TransformedRentalOption[]
  setRentalOptions: React.Dispatch<React.SetStateAction<TransformedRentalOption[]>>
  createListingInquiry: (
    inquiryCreationPayload: Required<AuthenticatedApi.Schema.CreateInquirySchema>,
  ) => Promise<ListingInquiry>
  checkAuthentication: () => Promise<void>
  roommates: Roommate[]
}

type RenderChildren = (context: Context) => React.ReactNode

export const ListingInquiryContext = createContext<Context>(null)
export const useListingInquiryContext = generateUseContext(ListingInquiryContext)

export const ListingInquiryContextProvider: React.FC<ListingInquiryContextProviderType> = ({ listing, children }) => {
  const { t } = useTranslation(['main-rentalListing'])
  const listingInquiryApiClient = useAuthenticatedListingInquiryApiClient()
  const { user } = useAuthenticationContext()
  const { openAuthenticationModal } = useAuthenticationModalContext()
  const [inquirySubmitted, setInquirySubmitted] = useState(false)
  const [listingInquiry, setListingInquiry] = useState<ListingInquiry>()
  const [openInquiryCardModal, setOpenInquiryCardModal] = useState(false)
  const [rentalOptions, setRentalOptions] = useState<TransformedRentalOption[]>(
    initializeDropdownRentalOptions(listing),
  )
  const [selectedPriceOptionId, setSelectedPriceOptionId] = useState<string>(
    rentalOptions?.length ? rentalOptions[0].value : undefined,
  )

  const createListingInquiry = async (
    inquiryCreationPayload: Required<AuthenticatedApi.Schema.CreateInquirySchema>,
  ) => {
    const { moveInDate, message, user, visitSchedules, rentalOptionId } = inquiryCreationPayload
    const formattedMoveInDate = moveInDate ? DateTime.fromISO(moveInDate).toFormat('dd-LLL-yyyy') : undefined
    const formattedMessage = formatInquiryMessage({
      listing,
      phone: user.phone.number,
      t,
      visitSchedules,
      message,
      moveInDate: formattedMoveInDate,
      rentalOptionId,
    })

    return checkAuthentication().then(() =>
      listingInquiryApiClient.createListingInquiry({
        payload: { ...inquiryCreationPayload, message: formattedMessage },
      }),
    )
  }

  const checkAuthentication = () =>
    new Promise<void>((resolve, reject) => {
      if (user) return resolve()

      const onSuccess = async (user: User) => {
        listingInquiryApiClient.updateApiClientToken(user.accessToken)
        return resolve()
      }

      return openAuthenticationModal(AuthType.LOGIN, {
        onSuccess,
        onDismiss: reject,
      })
    })

  const bookingApiClient = usePublicBookingApiClient()
  const { useQuery } = useQueryMutation<Roommate[]>({ queryKey: ['RoommatesByListingId', listing] })
  const { data: roommates } = useQuery(
    () => bookingApiClient.readRoommatesByListingId({ pathParams: { listingId: listing.id } }),
    { enabled: !!listing && isRoommatable(listing) },
  )

  const value = {
    selectedPriceOptionId,
    setSelectedPriceOptionId,
    openInquiryCardModal,
    setOpenInquiryCardModal,
    listingInquiry,
    setListingInquiry,
    inquirySubmitted,
    setInquirySubmitted,
    rentalOptions,
    setRentalOptions,
    checkAuthentication,
    createListingInquiry,
    roommates,
  }

  return (
    <ListingInquiryContext.Provider value={value}>
      {typeof children === 'function' ? (children as RenderChildren)(value) : children}
    </ListingInquiryContext.Provider>
  )
}
