import React from 'react'

import { AssetUploader } from '@guiker/asset-uploader'
import { FormSection2, FormSection3, TextField, useGetInputPropsWithUseT } from '@guiker/react-framework'
import { NEWProperty } from '@guiker/real-estate-shared'

import { useT } from '../../i18n'

export const PropertyDetailsFormContent: React.FC = () => {
  const { tShared, tComponents } = useT({ entity: 'property', componentName: 'PropertyDetailsFormContent' })

  const getInputProps = useGetInputPropsWithUseT<{ property: NEWProperty }>()({
    t: tShared,
    formPrefix: 'property',
  })

  return (
    <FormSection2>
      <FormSection3 title={tComponents('pictures')}>
        <AssetUploader withThumbnail {...getInputProps('pictures')} label={null} displayDirection='row' />
      </FormSection3>
      <FormSection3 title={tComponents('description')}>
        <TextField {...getInputProps('description')} label={null} multiline rowsMax={6} rows={6} />
      </FormSection3>
      <FormSection3 title={tComponents('documents')}>
        <AssetUploader
          withThumbnail
          {...getInputProps('documents')}
          label={null}
          displayNameEditable
          displayDirection='column'
          assetFlexDirection='row'
        />
      </FormSection3>
    </FormSection2>
  )
}
