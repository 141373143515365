import { routesBuilder } from '@guiker/shared-framework'

import { InvestorProfile } from '../../../../entity'
import { paths } from '../../paths'
import { approveInvestorProfileSchema } from '../../schemas'

const { buildGet, buildPost } = routesBuilder<InvestorProfile>()({
  basePath: paths.investorProfiles.withId.path,
})

export const routes = {
  revealInvestorProfileTIN: buildGet({
    path: `/reveal-TIN`,
    response: null as string,
  }),
  readOneInvestorProfile: buildGet({}),
  rejectInvestorProfile: buildPost({
    path: `/reject`,
  }),
  approveInvestorProfile: buildPost({
    path: '/approve',
    payloadSchema: approveInvestorProfileSchema,
  }),
}
