import { deprecatedGenerateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { InvestmentListing } from '../../entity'

export const InvestmentListingEventsDefinition = deprecatedGenerateEventsDefinition({
  context: 'INVESTMENT_LISTING',
  entity: 'INVESTMENT_LISTING',
  data: null as unknown as InvestmentListing,
})

export const { eventTypes: InvestmentListingEventTypes, events: InvestmentListingEvents } =
  InvestmentListingEventsDefinition
export type EventTypes = ValueOfEventTypes<typeof InvestmentListingEventsDefinition>
