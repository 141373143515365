import React from 'react'

import { FormSection2, FormSection4 } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { CalculationItemInput } from '../CalculationItem'

type FinancialOperationFormContentProps = {}

export const FinancialOperationRevenuesFormContent: React.FC<FinancialOperationFormContentProps> = () => {
  return (
    <>
      <CalculationItemInput
        name='financialInfo.financials.operation.revenues.rentalIncome'
        withYearlyGrowthRate={false}
        canChangeType={false}
        hideTypeDropdown={true}
        frequency='monthly'
        resultFrequency={'yearly'}
      />
      <CalculationItemInput
        name='financialInfo.financials.operation.revenues.vacancyRate'
        base='rentalIncome'
        canChangeType={false}
        resultFrequency={'yearly'}
      />
    </>
  )
}

export const FinancialOperationExpensesFormContent: React.FC<FinancialOperationFormContentProps> = () => {
  return (
    <>
      <CalculationItemInput
        name='financialInfo.financials.operation.expenses.costs.condoFee'
        resultFrequency={'yearly'}
      />
      <CalculationItemInput
        name='financialInfo.financials.operation.expenses.costs.insurance'
        resultFrequency={'yearly'}
      />
      <CalculationItemInput
        name='financialInfo.financials.operation.expenses.costs.utility'
        resultFrequency={'yearly'}
      />
      <CalculationItemInput
        name='financialInfo.financials.operation.expenses.costs.managementFee'
        resultFrequency={'yearly'}
      />
      <CalculationItemInput
        name='financialInfo.financials.operation.expenses.costs.repairAllowance'
        resultFrequency={'yearly'}
      />
      <CalculationItemInput
        name='financialInfo.financials.operation.expenses.taxes.schoolTax'
        resultFrequency={'yearly'}
      />
      <CalculationItemInput
        name='financialInfo.financials.operation.expenses.taxes.municipalTax'
        resultFrequency={'yearly'}
      />
    </>
  )
}

export const FinancialOperationFormSection: React.FC<FinancialOperationFormContentProps> = () => {
  const { tComponents } = useT({ componentName: 'FinancialOperationFormSection' })

  return (
    <FormSection2 title={tComponents('title')} subtitle={tComponents('description')}>
      <FormSection4 title={tComponents('revenues.title')} subtitle={tComponents('revenues.description')}>
        <FinancialOperationRevenuesFormContent />
      </FormSection4>
      <FormSection4 title={tComponents('expenses.title')} subtitle={tComponents('expenses.description')}>
        <FinancialOperationExpensesFormContent />
      </FormSection4>
    </FormSection2>
  )
}
