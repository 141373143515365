export const CommonPurchaseCost = {
  initialRenovation: {
    value: {
      type: 'flat',
      amount: 0,
    },
  },
  cashReserve: {
    value: {
      type: 'flat',
      amount: 0,
    },
  },
  closingCost: {
    value: {
      type: 'flat',
      amount: 0,
    },
  },
  offeringCost: {
    value: {
      type: 'flat',
      amount: 0,
    },
  },
} as const
