import React from 'react'

import { FinancialInfo } from '@guiker/base-entity'
import { Flex, H3, makeStyles, Table, useLayoutContext } from '@guiker/react-framework'
import { NEWUnit } from '@guiker/real-estate-shared'
import { currency as baseCurrency, CurrencyISO } from '@guiker/shared-framework'

import { useT } from '../../../i18n'
import { getColumns } from './get-columns'
import { MobileUnitBreakdown } from './MobileUnitBreakdown'

type Props = {
  units: NEWUnit[]
  occupancies: FinancialInfo.Occupancy[]
  currency: CurrencyISO
}

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
})

export const UnitBreakdown: React.FC<Props> = ({ units, occupancies, currency }) => {
  const { tShared, t } = useT({ entity: 'revenue.occupancy' })
  const { isAtMostSmallSizeDesktop } = useLayoutContext()
  const classes = useStyles()

  if (!units?.length) return <></>

  return (
    <Flex flexDirection='column' width='100%'>
      <H3>{tShared('unitBreakdown')}</H3>
      {isAtMostSmallSizeDesktop ? (
        <MobileUnitBreakdown units={units} occupancies={occupancies} currency={currency} />
      ) : (
        <Table
          showLabel={false}
          data={units}
          columns={getColumns(baseCurrency[currency], occupancies, t)}
          className={classes.table}
        />
      )}
    </Flex>
  )
}
