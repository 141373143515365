import { math } from '@guiker/shared-framework'

const findMinAndMax = (value: number, amount: number) => {
  return {
    min: value - value * amount,
    max: value + value * amount,
  }
}

const findMinAndMaxWithRange = (value: number, amount: number, range: number) => {
  const min = value - value * amount
  const minRange = value - range < 0 ? 0 : value - range
  const max = value + value * amount
  const maxRange = value + range
  return {
    min: min < minRange ? math.decimal.round(min, 2) : math.decimal.round(minRange, 2),
    max: max > maxRange ? math.decimal.round(max, 2) : math.decimal.round(maxRange, 2),
  }
}

export { findMinAndMax, findMinAndMaxWithRange }
