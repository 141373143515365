import { EntityDocument, PhoneNumber } from '@guiker/base-entity'
import { PayoutMethod } from '@guiker/payout-shared'
import { NEWPropertyOwnership, RepresentativeRole, WithPropertyOwnership } from '@guiker/real-estate-shared'

import { RentalServiceFeeByLeaseType } from './base'

export { RepresentativeRole }

export enum AgreementStatus {
  'CREATED' = 'CREATED',
  'SENT' = 'SENT',
  'SIGNED' = 'SIGNED',
}

export enum AgreementType {
  'TENANT_FINDER' = 'TENANT_FINDER',
  'FULL_MANAGEMENT' = 'FULL_MANAGEMENT',
  'PRODUCT_ONLY' = 'PRODUCT_ONLY',
}

export enum AgreementFeeType {
  'RENT_GUARANTEE' = 'RENT_GUARANTEE',
  'PERCENTAGE' = 'PERCENTAGE',
}

export type AgreementFee = {
  type: AgreementFeeType
  value: number
}

export type Agreement = {
  status: AgreementStatus
  type: AgreementType
  fee: RentalServiceFeeByLeaseType
  contract?: {
    id: number | string
  }
}

export enum ManagerRole {
  OWNER = 'OWNER',
  MANAGEMENT = 'MANAGEMENT',
  AGENT = 'AGENT',
}

export type Manager = {
  userId: string
  officeId?: string
  firstName: string
  lastName?: string
  title?: string
  emailAddress: string
  phone?: PhoneNumber
  isContactPerson: boolean
  role?: ManagerRole
}

export type PropRental = EntityDocument & {
  propertyOwnership: NEWPropertyOwnership
  onboarding: {
    started: string // date
    expired?: string // date
  }
  payoutMethod?: PayoutMethod
  managers: Manager[]
  agreement?: Agreement
  assets?: {
    id: string
    name: string
  }[]
  meta?: {
    legacyIds: number[]
  }
  updatedAt?: string
}

export type PropRentalWriteModel = Omit<PropRental, 'propertyOwnership'> & {
  propertyOwnership: {
    id: string
  }
}

export type PropRentalProjection = WithPropertyOwnership<PropRental>
