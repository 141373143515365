import * as Canada from './canada'
import * as UnitedStates from './united-states'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type IsTwoLetterString<T extends string> = T extends `${infer A}${infer B}${infer C}`
  ? C extends ''
    ? true
    : false
  : false

type ExtractCity<T> = T extends `${string}-${infer State}` ? (IsTwoLetterString<State> extends true ? T : never) : never

export type Jurisdiction = Canada.CanadaJurisdiction | UnitedStates.UnitedStatesJurisdiction
export type CityJurisdiction = ExtractCity<Jurisdiction>

export { CommonOperationCost } from './operation-cost'
export { CommonPurchaseCost } from './purchase-cost'
export { CommonPurchaseTaxes } from './purchase-taxes'

export { Canada, UnitedStates }
