import { DealType } from '../../../../deal'
import { CostArgs } from '../../../utils'
import { CanadaPurchaseTaxes } from '../purchase-taxes'

export const HST = {
  value: {
    type: 'percentage',
    base: 'purchasePrice',
    rate: 0.09,
  },
} as const

export const OntarioPurchaseTaxes = (args: CostArgs) => ({
  ...CanadaPurchaseTaxes,
  ...([DealType.NEW, DealType.PRECON].includes(args.dealType) ? { HST } : undefined),
})
