import React from 'react'

import { PayInMethod, webappRoutes } from '@guiker/payment-shared'
import {
  Chip,
  Flex,
  Inline,
  makeStyles,
  NavigationIcon,
  PSmall,
  TextButton,
  useNavigate,
  WithTooltip,
} from '@guiker/react-framework'
import { camelCase, Payment } from '@guiker/shared-framework'

import { useT } from '../../i18n'

type Props = {
  payInMethod: PayInMethod
}

const useStyles = makeStyles(
  () => ({
    icon: {
      width: 16,
      height: 16,
      minWidth: 'initial',
      minHeight: 'initial',
    },
    text: {
      textTransform: 'initial',
    },
    chip: {
      pointerEvents: 'initial',
    },
  }),
  { name: 'PayInMethodDisabledChip' },
)

const getPayInMethodResolveUrl = (args: { payInMethodId: string; disabled?: PayInMethod['disabled'] }) => {
  const { payInMethodId, disabled } = args
  const resolvable = [Payment.DisabledType.INSUFFICIENT_FUND_FLAGGED, Payment.DisabledType.PLAID_TOKEN_EXPIRED] as const

  if (!(resolvable as readonly Payment.DisabledType[]).includes(disabled)) return

  return webappRoutes.root.payInMethod.byId(payInMethodId).resolve[camelCase(disabled)]?.path
}

export const PayInMethodDisabledChip: React.FC<Props> = ({ payInMethod }) => {
  const { tShared } = useT()
  const { tMain } = useT({ screenName: 'screens.microdepositModal' })
  const classes = useStyles()
  const navigate = useNavigate()

  if (!payInMethod?.disabled) return <></>

  const { id: payInMethodId, disabled } = payInMethod
  const requiresMicrodepositVerification = disabled === Payment.DisabledType.MICRODEPOSIT_VERIFICATION_REQUIRED
  const color = requiresMicrodepositVerification ? 'warning' : 'alert'
  const resolveLink = getPayInMethodResolveUrl({ payInMethodId, disabled })
  const content = tShared(`disabled.type.${payInMethod.disabled}`)

  return (
    <Chip className={classes.chip} color={color} size='small'>
      {resolveLink ? (
        <TextButton underline={false} onClick={() => navigate(resolveLink)}>
          <Flex gap={2}>
            <Inline color='textSecondary'>{content}</Inline>
            <NavigationIcon.NextIcon className={classes.icon} color={'white'} />
          </Flex>
        </TextButton>
      ) : requiresMicrodepositVerification ? (
        <WithTooltip
          title={
            <Flex>
              <PSmall color='textSecondary'>{tMain('description')}</PSmall>
            </Flex>
          }
        >
          <Inline color='textSecondary'>{content}</Inline>
        </WithTooltip>
      ) : (
        <Inline className={classes.text} color='textSecondary'>
          {content}
        </Inline>
      )}
    </Chip>
  )
}
