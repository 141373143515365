import { IRR, NPV, PMT } from '@guiker/finance'
import { math } from '@guiker/lodash'

import {
  Bracket,
  BracketValue,
  CalculationItem,
  ComputedExpense,
  EstimatedSalePriceCalculationMethod,
  Financials,
  FlatValue,
  PercentageBase,
  PercentageValue,
  Results,
  YearlyCashflow,
} from '../../financial-info'
import { expenseValueTypeChecker } from './expense-value-type-checker'

type PercentBases = {
  purchasePrice: number
  assessedValue: number
  rentalIncome: number
}

const _monthlyMortgagePayment = (termsInYears: number, interestRate: number, loanAmount: number) => {
  if (termsInYears === 0) return 0
  return math.decimal.round(-PMT(computeMonthlyInterestRate(interestRate), termsInYears * 12, loanAmount), 0)
}

const _totalTaxes = (computedTaxes: ComputedExpense[]): number => {
  return computedTaxes.reduce((acc, curr) => acc + curr.computedValue, 0)
}

export const getPercentageBase = (base: PercentageBase, bases: PercentBases) => {
  if (base === 'maxPropertyValue') {
    return bases[bases.purchasePrice >= bases.assessedValue ? 'purchasePrice' : 'assessedValue']
  }

  return bases[base]
}

const computePercentExpense = ({
  bases,
  expense,
}: {
  bases: PercentBases
  expense: CalculationItem<PercentageValue>
}) => {
  const baseValue = getPercentageBase(expense.value.base, bases) - (expense.value.exemption || 0)

  return math.decimal.round((baseValue ?? 0) * expense.value.rate, 0)
}

const computeBracketExpense = ({ bases, expense }: { bases: PercentBases; expense: CalculationItem<BracketValue> }) => {
  const baseValue = getPercentageBase(expense.value.base, bases)
  if (baseValue <= 0) {
    return {
      amount: 0,
      rate: 0,
    }
  }

  const amount = (expense.value.brackets as Bracket[]).reduce((taxAmount, bracket, currentIndex) => {
    const lowerBracketThreshold = expense.value.brackets[currentIndex - 1]?.threshold || 0
    const upperBracketThresold = bracket.threshold == null ? baseValue : Math.min(baseValue, bracket.threshold)
    const taxable = upperBracketThresold - lowerBracketThreshold

    if (taxable < 0) return taxAmount

    taxAmount += taxable * bracket.rate

    return taxAmount
  }, 0)

  return {
    amount,
    rate: amount / baseValue,
  }
}

const computeFlatExpense = ({
  expense,
  year = 1,
  defaultYearlyGrowthRate = 0,
}: {
  expense: CalculationItem<FlatValue>
  defaultYearlyGrowthRate?: number
  year?: number
}) => {
  const yearlyGrowthRate = expense.value.yearlyGrowthRate ?? defaultYearlyGrowthRate
  if (!expense.value.amount) return 0
  if (!yearlyGrowthRate) return expense.value.amount

  return expense.value.amount * (1 + yearlyGrowthRate) ** (year - 1)
}

const computeExpense = ({
  bases,
  expense,
  year,
  defaultYearlyGrowthRate,
}: {
  bases: PercentBases
  expense: CalculationItem
  year: number
  defaultYearlyGrowthRate: number
}): number => {
  if (!expense?.value) return 0

  if (expenseValueTypeChecker.isFlatValue(expense)) {
    return computeFlatExpense({ expense, year, defaultYearlyGrowthRate })
  } else if (expenseValueTypeChecker.isPercentageValue(expense)) {
    return computePercentExpense({ bases, expense })
  } else if (expenseValueTypeChecker.isBracketValue(expense)) {
    return computeBracketExpense({ expense, bases }).amount
  }
}

const computeExpenses = ({
  bases,
  expenses,
  year = 1,
  defaultYearlyGrowthRate,
}: {
  bases: PercentBases
  expenses: Record<string, CalculationItem>
  year?: number
  defaultYearlyGrowthRate: number
}): ComputedExpense[] => {
  return Object.entries(expenses).map(([type, expense]: [type: string, expense: CalculationItem]) => ({
    ...expense,
    type,
    computedValue: computeExpense({ bases, expense, year, defaultYearlyGrowthRate }),
  }))
}

const getPercentageBases = (financials: Financials) => {
  const { purchase, operation } = financials
  const rentalIncome = computeFlatExpense({
    expense: operation.revenues.rentalIncome,
  })

  const purchasePrice = purchase.price.value.amount
  const assessedValue = purchase.assessedValue.value.amount

  return {
    rentalIncome,
    purchasePrice,
    assessedValue,
  }
}

export const computePercentageValue = ({
  financials,
  percentageValue,
}: {
  financials: Financials
  percentageValue: CalculationItem<PercentageValue>
}) => {
  const { rentalIncome, purchasePrice, assessedValue } = getPercentageBases(financials)

  return computePercentExpense({
    bases: {
      purchasePrice,
      rentalIncome,
      assessedValue,
    },
    expense: percentageValue,
  })
}

export const computeBracketValue = ({
  financials,
  bracketValue,
}: {
  financials: Financials
  bracketValue: CalculationItem<BracketValue>
}) => {
  const { rentalIncome, purchasePrice, assessedValue } = getPercentageBases(financials)

  return computeBracketExpense({
    bases: {
      purchasePrice,
      rentalIncome,
      assessedValue,
    },
    expense: bracketValue,
  })
}

const _vacancyAllowance = (rentalIncome: number, vacancyRate: number) => {
  return math.decimal.round(vacancyRate * rentalIncome)
}

const _netOperatingIncome = ({
  netRentalRevenue,
  operatingExpenses,
}: {
  netRentalRevenue: number
  operatingExpenses: ComputedExpense[]
}) => {
  const totalExpense = operatingExpenses.reduce((acc, curr) => acc - curr.computedValue, 0)

  return math.decimal.round(netRentalRevenue + totalExpense, 0)
}

const computeMonthlyInterestRate = (interestRate: number) => {
  return Math.pow(1 + interestRate, 1 / 12) - 1
}

const _monthlyPaymentsData = (
  loanAmount: number,
  interestRate: number,
  monthlyMortgagePayment: number,
  termsInYears: number,
) => {
  const round = (value: number) => math.decimal.round(value, 0)
  const monthlyInterestRate = computeMonthlyInterestRate(interestRate)

  const compute = ({ month, prevPrincipalBalance }: { month: number; prevPrincipalBalance: number }) => {
    const interestPaid = round(prevPrincipalBalance * monthlyInterestRate)
    const paymentAmount = round(Math.min(monthlyMortgagePayment, prevPrincipalBalance + interestPaid))
    const principalPaid = round(paymentAmount - interestPaid)
    const principalBalance = round(prevPrincipalBalance - principalPaid)
    return { month, paymentAmount, interestPaid, principalPaid, principalBalance }
  }

  const data = [compute({ month: 1, prevPrincipalBalance: loanAmount })]

  for (let month = 2; month <= termsInYears * 12; month++) {
    const { principalBalance } = data[data.length - 1]
    data.push(compute({ month, prevPrincipalBalance: principalBalance }))
  }

  return data
}

const _computeIRR = (targetEquity: number, netCashFlowData: number[]) => {
  return math.decimal.round(IRR([-targetEquity, ...netCashFlowData]), 4)
}

const sumMonthlyPaymentsData = (data: ReturnType<typeof _monthlyPaymentsData>, year: number) => {
  let paymentAmountSum = 0
  let interestPaidSum = 0
  let principalPaidSum = 0

  const startMonth = (year - 1) * 12 + 1
  const endMonth = year * 12

  for (let month = startMonth; month <= endMonth; month++) {
    const { paymentAmount, interestPaid, principalPaid } = data[month - 1] || {
      paymentAmount: 0,
      interestPaid: 0,
      principalPaid: 0,
    }
    paymentAmountSum += paymentAmount
    interestPaidSum += interestPaid
    principalPaidSum += principalPaid
  }

  return { paymentAmountSum, interestPaidSum, principalPaidSum }
}

const computeMorgageResults = ({
  financials,
  computedTaxes,
  bases,
}: {
  financials: Financials
  computedTaxes: ComputedExpense[]
  bases: PercentBases
}) => {
  const {
    financing: { mortgage },
    purchase: { price },
  } = financials
  const amount = price.value.amount + _totalTaxes(computedTaxes)
  const { downPayment, termsInYears, interestRate } = mortgage

  const downPaymentAmount = computeExpense({ bases, expense: downPayment, year: 0, defaultYearlyGrowthRate: 0 })
  const loanAmount = amount - downPaymentAmount

  const monthlyMortgagePayment = _monthlyMortgagePayment(termsInYears, interestRate, loanAmount)

  return {
    downPaymentAmount,
    loanAmount,
    monthlyMortgagePayment,
  }
}

const computeYearlyCashflow = ({
  financials,
  monthlyMortgagePayment,
  loanAmount,
}: {
  financials: Financials
  monthlyMortgagePayment: number
  loanAmount: number
}) => {
  const yearlyCashflows: YearlyCashflow[] = []
  const { operation, financing, exit, purchase, nonOperating } = financials
  const { holdingPeriod, estimatedSalePriceCalculationMethod, rate } = exit
  const {
    revenues: { vacancyRate, rentalIncome },
    expenses: { costs, taxes },
  } = operation

  const { mortgage } = financing
  const { interestRate, termsInYears } = mortgage
  const purchasePrice = purchase.price.value.amount
  const assessedValue = purchase.assessedValue.value.amount

  const monthlyMortgagePayments = _monthlyPaymentsData(loanAmount, interestRate, monthlyMortgagePayment, termsInYears)

  for (let year = 1; year <= holdingPeriod; year++) {
    const yearlyRentalIncome =
      year > 1
        ? yearlyCashflows[year - 2].rentalIncome *
          (1 + (rentalIncome.value.yearlyGrowthRate || financials.base.yearlyGrowthRate || 0))
        : computeFlatExpense({ expense: rentalIncome })

    const vacancyAllowance = -_vacancyAllowance(yearlyRentalIncome, vacancyRate.value.rate)

    const percentCalculationBases = {
      purchasePrice,
      assessedValue,
      rentalIncome: yearlyRentalIncome,
    }

    const nonOperatingExpenses = computeExpenses({
      expenses: nonOperating.expenses,
      bases: percentCalculationBases,
      year: year,
      defaultYearlyGrowthRate: financials.base.yearlyGrowthRate,
    })

    const netRentalRevenue = yearlyRentalIncome + vacancyAllowance

    const operatingExpenses = [
      ...computeExpenses({
        bases: percentCalculationBases,
        expenses: costs,
        year: year,
        defaultYearlyGrowthRate: financials.base.yearlyGrowthRate,
      }),
      ...computeExpenses({
        bases: percentCalculationBases,
        expenses: taxes,
        year: year,
        defaultYearlyGrowthRate: financials.base.yearlyGrowthRate,
      }),
    ]

    const netOperatingIncome = _netOperatingIncome({
      netRentalRevenue,
      operatingExpenses,
    })

    const {
      paymentAmountSum: totalDebtService,
      interestPaidSum,
      principalPaidSum,
    } = sumMonthlyPaymentsData(monthlyMortgagePayments, year)
    const debtService = { interestPaidSum, principalPaidSum }
    const totalNonOperatingExpenses = nonOperatingExpenses.reduce((acc, curr) => acc + curr.computedValue, 0)

    const netIncome = netOperatingIncome - totalDebtService - totalNonOperatingExpenses
    const prevPrincipalBalance = year === 1 ? loanAmount : yearlyCashflows[yearlyCashflows.length - 1].mortgageBalance
    const mortgageBalance = prevPrincipalBalance - principalPaidSum

    const estimatedSalesPrice =
      estimatedSalePriceCalculationMethod === EstimatedSalePriceCalculationMethod.CAP_RATE
        ? netOperatingIncome * (1 / rate)
        : math.decimal.round(
            (year === 1 ? purchasePrice : yearlyCashflows[yearlyCashflows.length - 1].estimatedSalesPrice) * (1 + rate),
            0,
          )

    const equityPosition = year === holdingPeriod ? estimatedSalesPrice - mortgageBalance : 0
    const netCashFlow = netIncome + equityPosition

    yearlyCashflows.push({
      year,
      rentalIncome: yearlyRentalIncome,
      vacancyAllowance,
      nonOperatingExpenses,
      netRentalRevenue,
      operatingExpenses,
      netOperatingIncome,
      totalDebtService: -totalDebtService,
      debtService,
      netIncome,
      mortgageBalance,
      netCashFlow,
      estimatedSalesPrice,
    })
  }

  return yearlyCashflows
}

export const computeInvestmentResult = (financials: Financials): Results => {
  if (!financials) return
  const { purchase } = financials
  const { rentalIncome, purchasePrice, assessedValue } = getPercentageBases(financials)
  const npvDiscountRate = 0

  const bases = {
    purchasePrice,
    rentalIncome,
    assessedValue,
  }

  const computedPurchaseTaxes = computeExpenses({
    bases,
    expenses: purchase.taxes || {},
    defaultYearlyGrowthRate: financials.base.yearlyGrowthRate,
  })

  const computedPurchaseCosts = computeExpenses({
    bases,
    expenses: purchase.costs || {},
    defaultYearlyGrowthRate: financials.base.yearlyGrowthRate,
  })

  const computedPurchaseTaxesAmount = computedPurchaseTaxes.reduce((acc, curr) => acc + curr.computedValue, 0)
  const computedPurchaseCostAmount = computedPurchaseCosts.reduce((acc, curr) => acc + curr.computedValue, 0)
  const { downPaymentAmount, monthlyMortgagePayment, loanAmount } = computeMorgageResults({
    financials,
    bases,
    computedTaxes: computedPurchaseTaxes.filter((tax) => tax.type === 'welcomeTax'),
  })

  const totalProjectCost = purchasePrice + computedPurchaseTaxesAmount + computedPurchaseCostAmount
  const targetEquity = math.decimal.round(totalProjectCost - loanAmount, 0)

  const yearlyCashflows = computeYearlyCashflow({ financials, monthlyMortgagePayment, loanAmount })
  const netCashFlowData = yearlyCashflows.map(({ netCashFlow }) => netCashFlow)
  const totalNetCashFlowData = netCashFlowData.reduce((sum, curr) => sum + curr, 0)

  const returnMultiple = math.decimal.round(totalNetCashFlowData / targetEquity, 4)
  const irr = _computeIRR(targetEquity, netCashFlowData)
  const npv = math.decimal.round(NPV(npvDiscountRate, -targetEquity, ...netCashFlowData), 0)
  const coc = math.decimal.round(totalNetCashFlowData / targetEquity, 4)

  const { taxesAndFees, cashReserve } = [...computedPurchaseCosts, ...computedPurchaseTaxes].reduce(
    (acc, expense) => {
      return {
        taxesAndFees: expense.type !== 'cashReserve' ? acc.taxesAndFees + expense.computedValue : acc.taxesAndFees,
        cashReserve: expense.type === 'cashReserve' ? acc.cashReserve + expense.computedValue : acc.cashReserve,
      }
    },
    {
      taxesAndFees: 0,
      cashReserve: 0,
    },
  )

  return {
    computedPurchaseTaxes,
    taxesAndFees,
    cashReserve,
    computedPurchaseCosts,
    totalProjectCost,
    estimatedSalesPrice: yearlyCashflows[yearlyCashflows.length - 1]?.estimatedSalesPrice,
    monthlyMortgagePayment,
    downPaymentAmount,
    loanAmount,
    returnMultiple,
    targetEquity,
    yearlyCashflows,
    npv,
    irr,
    coc,
  }
}
