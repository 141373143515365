import { Asset } from '@guiker/asset-shared'
import { DeepPartial, EntityDocument, ReplaceByReference } from '@guiker/shared-framework'

import { PropSharingInquiry } from './propsharing-inquiry'
import { Stock } from './stock'

export const assetScopeType = 'fractional-ownership-listing'

export type PropSharingListing = EntityDocument & {
  stock: Stock
  fundingMetrics: {
    totalRaised: number
    investorCount: number
    percentFunded: number
    totalUnitSold: number
    totalUnitQuantity: number
  }
  status: {
    isPublished: boolean
  }
  additionalFlags: {
    isTrending: boolean
  }
  pictures: Asset[]
  documents: Asset[]
}

export type PropSharingListingWriteModel = Omit<
  ReplaceByReference<PropSharingListing, 'pictures' | 'documents' | 'stock'>,
  'fundingMetrics'
> & {
  stock: {
    id: string
  }
}

export type WithInquiry<T> = T & {
  fractionalOwnershipInquiry?: DeepPartial<PropSharingInquiry>
}

export type PropSharingListingWithInquiry = WithInquiry<PropSharingListing>
