import { FinancialInfo } from '@guiker/base-entity'
import { buildQueueDefinition } from '@guiker/queue'

import { config } from '../config'

export const Queue = {
  SyncLegacyListing: buildQueueDefinition({
    name: 'SyncLegacyListing',
    serviceName: config.apiName,
    payload: null as {
      listingId: string
      userId?: string
    }[],
    isFifo: true,
    batchSize: 1,
  }),
  UpdatePropertyOccupancy: buildQueueDefinition({
    name: 'UpdatePropertyOccupancy',
    serviceName: config.apiName,
    payload: null as {
      voided: boolean
      propertyId: string
      unit: string
      lease: FinancialInfo.OccupancyLease
    }[],
    isFifo: true,
    batchSize: 1,
  }),
} as const
