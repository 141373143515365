import React from 'react'

import { FinancialInfo } from '@guiker/base-entity'
import { CurrencyTextField, numberFormatter, useGetInputPropsWithUseT, useWatch } from '@guiker/react-framework'
import { PercentNumberFormat } from '@guiker/rhf-components'

import { useT } from '../../i18n'

export type FlatValueProps = {
  name: string
  label?: string
  withYearlyGrowthRate?: boolean
  frequency?: 'yearly' | 'monthly'
}

export const FlatValueInput: React.FC<FlatValueProps> = ({ frequency, withYearlyGrowthRate = true, label, name }) => {
  const { tShared: tSharedCalculationItem } = useT({ entity: 'calculationItem' })
  const { tShared } = useT({ entity: 'calculationItem.flat' })

  const getInputProps = useGetInputPropsWithUseT<{
    [key in typeof name]: FinancialInfo.CalculationItem<FinancialInfo.FlatValue>
  }>()({
    t: tShared,
    formPrefix: name,
  })

  const { yearlyGrowthRate: baseYearlyGrowthRate } = useWatch({
    name: 'financialInfo.financials.base',
  })

  const { value } = useWatch({
    name: name,
  }) as FinancialInfo.CalculationItem<FinancialInfo.FlatValue>

  return (
    <>
      <CurrencyTextField
        {...getInputProps('value.amount')}
        label={label || tShared('amount')}
        condensed
        endAdornment={frequency && tSharedCalculationItem(`frequency.${frequency}`)}
      />
      {withYearlyGrowthRate && (
        <PercentNumberFormat
          {...getInputProps('value.yearlyGrowthRate')}
          condensed
          value={value.yearlyGrowthRate}
          label={label || tShared('yearlyGrowthRate')}
          placeholder={`${numberFormatter.percentage(2).toDisplay(baseYearlyGrowthRate)} (Default)`}
        />
      )}
    </>
  )
}
