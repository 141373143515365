import React from 'react'

import { PSmaller, TextButton, useTranslation } from '@guiker/react-framework'

type ButtonProps = {
  hide: boolean
  setHide: (value: boolean) => void
}

const CollapsibleDescriptionButton: React.FC<ButtonProps> = ({ hide, setHide }) => {
  const { t } = useTranslation('common')

  return (
    <TextButton size='small' onClick={() => setHide(!hide)}>
      <PSmaller mb={0}>{t(`actions.${hide ? 'seeMore' : 'seeLess'}`)}</PSmaller>
    </TextButton>
  )
}

export { CollapsibleDescriptionButton }
