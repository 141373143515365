import React, { useEffect } from 'react'

import {
  Checkbox,
  Collapse,
  CurrencyTextField,
  DatePicker,
  Divider,
  Flex,
  FormSection3,
  HiddenInput,
  Inline,
  lighten,
  makeStyles,
  NumberFormat,
  numberFormatter,
  P,
  TwoColumnsGridLayout,
  useFieldArray,
  useForm,
  useGetInputProps,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'
import { currency as baseCurrency, optionalConcat } from '@guiker/shared-framework'

import { AssumptionComponentProps as Props } from '../assumption-props'

const useStyles = makeStyles((theme) => ({
  unitsFieldset: {
    padding: theme.spacing(2),
    border: `1px solid ${lighten(theme.palette.background.secondary, 0.95)}`,
  },
}))

type OccupancyProps = Props & { unit?: string; type?: string; index: number }

export const OccupancyComponent: React.FC<OccupancyProps> = ({ defaultValue, unit, type, name, readOnly, index }) => {
  const { t } = useTranslation('common-investmentAssumption')

  const currency = baseCurrency[defaultValue?.price?.currency]
  const tPrefix = 'common-investmentAssumption:revenue.occupancy'
  const getInputProps = useGetInputProps({ defaultValue, formPrefix: name, tPrefix, readOnly })

  const unitInputProps = getInputProps<string>('unit')
  const typeInputProps = getInputProps<string>('type')
  const isOccupiedProps = getInputProps('isOccupied')
  const isOccupied = useWatch({
    name: isOccupiedProps.name,
    defaultValue: isOccupiedProps.defaultValue ?? false,
  }) as boolean

  return (
    <Flex flexDirection='column' gap={1}>
      <HiddenInput {...unitInputProps} defaultValue={(unitInputProps.defaultValue ?? unit) as string} />
      <HiddenInput {...typeInputProps} defaultValue={(typeInputProps.defaultValue ?? type) as string} />
      <P mb={0}>
        {optionalConcat(
          [
            index + 1,
            !!unitInputProps.defaultValue || !!unit
              ? [t(`${tPrefix}.unit`), unitInputProps.defaultValue ?? unit].join(': ')
              : undefined,
            t(`${tPrefix}.type.${type?.toLowerCase()}`),
          ],
          ' | ',
        )}
      </P>
      <TwoColumnsGridLayout>
        <CurrencyTextField
          {...getInputProps('monthlyRent', { i18nKey: 'monthlyRent.label' })}
          currency={currency}
          allowNegative={false}
        />
        <Flex height='100%' alignItems='center'>
          <Checkbox {...isOccupiedProps} defaultValue={isOccupiedProps.defaultValue ?? false} />
        </Flex>
      </TwoColumnsGridLayout>
      <Collapse in={isOccupied}>
        <TwoColumnsGridLayout>
          <DatePicker {...getInputProps('period.from')} />
          <DatePicker {...getInputProps('period.to')} />
        </TwoColumnsGridLayout>
      </Collapse>
    </Flex>
  )
}

export const RevenueAssumptions: React.FC<Props> = ({ defaultValue, name, schema, readOnly = false }) => {
  const tPrefix = 'common-investmentAssumption:revenue'
  const currency = baseCurrency[defaultValue?.price?.currency]
  const classes = useStyles()
  const formMethods = useForm()
  const inputProps = useGetInputProps({ defaultValue, formPrefix: name, schema, tPrefix, readOnly })

  const fields = {
    rentalRevenue: inputProps('rentalRevenue'),
    isEstimatedRevenue: inputProps('isEstimatedRevenue'),
    rentalGrowthRate: inputProps('rentalGrowthRate'),
    vacancyRate: inputProps('vacancyRate'),
  }

  const factorsFields = {
    rentalGrowthRate: inputProps('factors.rentalGrowthRate', { i18nKey: 'factors' }),
    vacancyRate: inputProps('factors.vacancyRate', { i18nKey: 'factors' }),
  }

  const {
    fields: occupanciesFields,
    append,
    remove,
  } = useFieldArray({ control: formMethods.control, name: `${name}.occupancies`, keyName: 'fieldKey' })

  const commonProps = {
    formatter: numberFormatter.percentage(2),
    decimalScale: 2,
    allowNegative: false,
  }

  const removeAllFields = () => {
    occupanciesFields.forEach((_f, i) => remove(i))
  }

  useEffect(() => {
    const unitsLength = defaultValue?.property?.units?.length
    const occupanciesLength = occupanciesFields?.length

    if (defaultValue?.property?.hasDetailedUnitInformation && unitsLength !== occupanciesLength) {
      removeAllFields()
      defaultValue?.property?.units?.map((unit) => {
        append({
          unit: unit.address?.unitNumber ?? unit.address?.streetNumber,
          type: unit.type,
        })
      })
      formMethods.setValue(`${name}.rentalRevenue`, 0)
      formMethods.setValue(`${name}.isEstimatedRevenue`, false)
    } else if (defaultValue?.property?.hasDetailedUnitInformation) {
      formMethods.setValue(`${name}.rentalRevenue`, 0)
    } else {
      removeAllFields()
      formMethods.setValue(`${name}.occupancies`, [])
    }
  }, [defaultValue?.property?.units])

  return (
    <FormSection3>
      {defaultValue?.property?.hasDetailedUnitInformation ? (
        <Collapse in={defaultValue?.property?.hasDetailedUnitInformation}>
          <Flex className={classes.unitsFieldset} flexDirection='column' gap={2}>
            {occupanciesFields.map((field, index) => (
              <div>
                {index > 0 && <Divider mb={2} />}
                <OccupancyComponent
                  index={index}
                  defaultValue={defaultValue}
                  unit={field['unit']}
                  type={field['type']}
                  readOnly={readOnly}
                  name={`${name}.occupancies[${index}]`}
                  key={`${name}.occupancies[${index}]`}
                />
              </div>
            ))}
          </Flex>
        </Collapse>
      ) : (
        <TwoColumnsGridLayout>
          <CurrencyTextField
            {...fields.rentalRevenue}
            currency={currency}
            allowNegative={false}
            disabled={defaultValue?.property?.hasDetailedUnitInformation}
          />
          <Flex height='100%' alignItems='center'>
            <Checkbox
              {...fields.isEstimatedRevenue}
              defaultValue={defaultValue?.assumptions?.revenue?.isEstimatedRevenue ?? false}
              disabled={defaultValue?.property?.hasDetailedUnitInformation}
            />
          </Flex>
        </TwoColumnsGridLayout>
      )}

      <TwoColumnsGridLayout>
        <NumberFormat {...fields.rentalGrowthRate} {...commonProps} endAdornment={<Inline>%</Inline>} />
        <NumberFormat
          {...factorsFields.rentalGrowthRate}
          {...commonProps}
          startAdornment={<Inline pl={2}>&plusmn;</Inline>}
          endAdornment={<Inline>%</Inline>}
        />
        <NumberFormat {...fields.vacancyRate} {...commonProps} endAdornment={<Inline>%</Inline>} />
        <NumberFormat
          {...factorsFields.vacancyRate}
          {...commonProps}
          startAdornment={<Inline pl={2}>&plusmn;</Inline>}
          endAdornment={<Inline>%</Inline>}
        />
      </TwoColumnsGridLayout>
    </FormSection3>
  )
}
