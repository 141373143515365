import { routeBuilderHelper, routesBuilder } from '@guiker/shared-framework'

import { InvestorProfile, InvestorProfileReviewalStatus } from '../../../entity'
import { paths } from '../paths'

const { buildGet } = routesBuilder<InvestorProfile>()({
  basePath: paths.investorProfiles.path,
})
export const routes = {
  readAllInvestorProfiles: buildGet(routeBuilderHelper.withStatus(Object.values(InvestorProfileReviewalStatus))),
}
