import React from 'react'

import { mainPathBuilder } from '@guiker/my-investment-shared'
import {
  computeInvoiceFees,
  computeInvoiceTotalAmount,
  Transfer,
  TransferScopeType,
  TransferStatus,
  transferTypeChecker,
} from '@guiker/payment-shared'
import { Payout, transferHelper } from '@guiker/payout-app-components'
import { isPaidOffline } from '@guiker/payout-shared'
import { ColumnConfig, Flex, P, PSmall, PSmaller, TextButton, useNavigate, useT } from '@guiker/react-framework'
import { compact, formatDate, money, optionalConcat } from '@guiker/shared-framework'
import { RentCollectionPayoutTableDataPoint } from '@guiker/statistic-shared'

export const useRentCollectionPayoutColumns = (): ColumnConfig<
  RentCollectionPayoutTableDataPoint,
  Transfer<TransferScopeType.INVOICE>
>[] => {
  const { tBase, tShared } = useT({ domain: 'components', entity: 'statistic.rentCollectionPayout.table' })
  const navigate = useNavigate()

  const hasScopeData = (transfer: Transfer) => {
    return transferTypeChecker.isInvoice(transfer) && !!transfer.scope.data
  }

  return compact([
    {
      label: tShared(`headers.destinationAccount`),
      name: 'destinationAccount',
      size: 1.6,
      sortable: false,
      renderValue: (payout) => (
        <Payout.PayoutMethodLabel payoutMethod={payout.payoutMethod} isPaidOffline={isPaidOffline(payout)} />
      ),
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: (transfer) => {
        const isOtherPayments = !hasScopeData(transfer)
        const display = transferHelper.getInfoDisplay(transfer)
        const label = isOtherPayments ? tShared('transfers.otherPayments') : display.label

        return (
          <Flex flexDirection='column'>
            <PSmall mb={0} color={isOtherPayments ? 60 : 'inherit'}>
              {label}
            </PSmall>
            {!!display.date && <PSmaller mb={0}>{display.date}</PSmaller>}
          </Flex>
        )
      },
    },
    {
      label: tShared(`headers.initiatedDate`),
      name: 'initiatedDate',
      size: 1.2,
      renderValue: ({ createdAt }) => {
        if (!createdAt) return
        return formatDate(createdAt)
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: (transfer) => {
        if (!hasScopeData(transfer)) return <></>
        return (
          <Flex flexDirection='column'>
            <PSmall mb={0}>{transfer.info.payerName}</PSmall>
            <PSmaller mb={0}>{transfer.info.payerEmailAddress}</PSmaller>
          </Flex>
        )
      },
    },
    {
      label: tShared(`headers.receptionDate`),
      name: 'receptionDate',
      size: 1.2,
      renderValue: ({ expectedPayoutDate }) => {
        if (!expectedPayoutDate) return
        return formatDate(expectedPayoutDate)
      },
    },
    {
      label: tShared(`headers.status`),
      name: 'status',
      size: 0.6,
      sortable: false,
      renderValue: (payout) => <Payout.PayoutStatusChip payout={payout} />,
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: (transfer) =>
        hasScopeData(transfer) ? <Payout.TransferStatusChip transfer={transfer} /> : <></>,
    },
    {
      label: tShared(`headers.rent`),
      name: 'rent',
      size: 1.0,
      headerOptions: { textAlign: 'end' },
      renderValue: ({ currency, transfers }) => {
        const total = transfers?.reduce((acc, transfer) => {
          const amount = hasScopeData(transfer) ? computeInvoiceTotalAmount(transfer.scope.data) : transfer.amount
          return acc + (transfer.status === TransferStatus.REVERSED ? -amount : amount)
        }, 0)
        return money.fromAmount(total ?? 0, currency).toString('onlySymbol')
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: (transfer) => {
        if (!hasScopeData(transfer)) return '-'
        const amount = computeInvoiceTotalAmount(transfer.scope.data)
        return money.fromAmount(amount, transfer.currency).toString('onlySymbol')
      },
    },
    {
      label: tShared(`headers.fee`),
      name: 'fee',
      size: 0.8,
      headerOptions: { textAlign: 'end' },
      renderValue: ({ currency, transfers }) => {
        const total = transfers?.reduce((acc, transfer) => {
          const amount = hasScopeData(transfer) ? computeInvoiceFees(transfer.scope.data) : 0
          return acc + (transfer.status === TransferStatus.REVERSED ? -amount : amount)
        }, 0)
        return money.fromAmount(total ?? 0, currency).toString('onlySymbol')
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: (transfer) => {
        if (!hasScopeData(transfer)) return '-'
        const amount = computeInvoiceFees(transfer.scope.data)
        return money.fromAmount(amount, transfer.currency).toString('onlySymbol')
      },
    },
    {
      label: tShared(`headers.receivable`),
      name: 'receivable',
      size: 1.0,
      headerOptions: { textAlign: 'end' },
      renderValue: ({ amount, currency }) => {
        return money.fromAmount(amount, currency).toString()
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: (transfer) => {
        const isOtherPayments = !hasScopeData(transfer)
        const isReverse = transfer.status === TransferStatus.REVERSED
        const amount = Math.abs(transfer.amount)
        const formattedAmount = money.fromAmount(amount, transfer.parent.currency).toString('onlySymbol')

        return (
          <P color={isOtherPayments ? 60 : 'inherit'} textAlign='right' mb={0}>
            {isReverse ? optionalConcat(['(', formattedAmount, ')'], '') : formattedAmount}
          </P>
        )
      },
    },
    {
      label: '',
      name: 'action',
      size: 0.5,
      sortable: false,
      headerOptions: { textAlign: 'end' },
      renderValue: ({ id }) => {
        const path = mainPathBuilder.root.payouts.byId(id).path
        return (
          <TextButton size='small' onClick={() => navigate(path)}>
            <PSmall mb={0}>{tBase('actions.view')}</PSmall>
          </TextButton>
        )
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: (transfer) => {
        if (!hasScopeData(transfer)) return <></>
        const id = transfer.scope.data?.scope.id
        const path = mainPathBuilder.root.rentalTenancy.tenantInstalments.byId(id).path
        return (
          <TextButton size='small' onClick={() => navigate(path)}>
            <PSmaller mb={0}>{tBase('actions.view')}</PSmaller>
          </TextButton>
        )
      },
    },
  ])
}
