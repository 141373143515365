import { routesBuilder } from '@guiker/shared-framework'

import { FundingAccountWithdrawal, FundingAccountWithdrawalWriteModel } from '../../../../../entity/funding-account'
import { paths } from '../../../paths'

const { buildGet } = routesBuilder<FundingAccountWithdrawal, FundingAccountWithdrawalWriteModel>()({
  basePath: paths.fundingAccounts.withdrawal.withId.path,
})

export const routes = {
  readOneWithdrawal: buildGet(),
} as const
