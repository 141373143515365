import { deprecatedGenerateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { Unit } from '../../entity'
import { baseEvent } from '../base'

const baseUnitEvent = {
  ...baseEvent,
  entity: 'UNIT',
  data: null as unknown as Unit,
}

export enum UnitEventTypes {}

export const UnitEventsDefinition = deprecatedGenerateEventsDefinition({
  ...baseUnitEvent,
  eventTypes: UnitEventTypes,
})

export const UnitEvents = generateEventsFromEnum(baseUnitEvent)(UnitEventTypes)
