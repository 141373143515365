import { FinancialInfo } from '@guiker/base-entity'
import { enumLike, yup } from '@guiker/yup-util'

import { NEWProperty } from '../../entity'
import { bracketValue, calculationItem, flatValue, percentageValue } from './calculation-item'

const isNewProperty = false

const purchaseTaxesSchema = yup
  .object<FinancialInfo.Financials['purchase']['taxes']>()
  .when('$property', (property: NEWProperty) => {
    if (property?.building?.mailingAddress?.city === 'montreal') {
      return yup
        .object({
          welcomeTax: bracketValue.notRequired(),
          .../* && is new */ (isNewProperty
            ? {
                QST: percentageValue.notRequired(),
                GST: percentageValue.notRequired(),
              }
            : {}),
        })
        .required()
    } else if (property?.building?.mailingAddress?.city === 'toronto' /* && is new */) {
      return yup
        .object({
          welcomeTax: bracketValue.notRequired(),
          .../* && is new */ (isNewProperty
            ? {
                HST: percentageValue.notRequired(),
              }
            : {}),
        })
        .required()
    }
  })

const exit = yup.object<FinancialInfo.Financials['exit']>({
  estimatedSalePriceCalculationMethod: enumLike(FinancialInfo.EstimatedSalePriceCalculationMethod)
    .default(FinancialInfo.EstimatedSalePriceCalculationMethod.APPRECIATION_RATE)
    .required(),
  holdingPeriod: yup.number().required(),
  rate: yup.number().required(),
})

const base = yup.object<FinancialInfo.Financials['base']>({
  yearlyGrowthRate: yup.number().required(),
})

const operationRevenues = yup.object<FinancialInfo.Financials['operation']['revenues']>({
  rentalIncome: flatValue.required(),
  vacancyRate: percentageValue.required(),
})

const operationExpenses = yup.object<FinancialInfo.Financials['operation']['expenses']>({
  costs: yup.object({
    condoFee: calculationItem,
    insurance: calculationItem,
    utility: calculationItem,
    managementFee: calculationItem,
    repairAllowance: calculationItem,
  }),
  taxes: yup.object({
    municipalTax: calculationItem,
    schoolTax: calculationItem,
  }),
})

const operation = yup.object<FinancialInfo.Financials['operation']>({
  revenues: operationRevenues.required(),
  expenses: operationExpenses.required(),
})

const mortgageSchema = yup.object<FinancialInfo.Financials['financing']['mortgage']>({
  downPayment: calculationItem,
  termsInYears: yup.number().required(),
  interestRate: yup.number().min(0).max(100).required(),
})

const financing = yup.object<FinancialInfo.Financials['financing']>({
  mortgage: mortgageSchema.required(),
})

const purchase = yup.object<FinancialInfo.Financials['purchase']>({
  assessedValue: flatValue,
  price: flatValue.required(),
  costs: yup.object<FinancialInfo.Financials['purchase']['costs']>({
    initialRenovation: calculationItem,
    cashReserve: calculationItem,
    closingCost: calculationItem,
    offeringCost: calculationItem,
  }),
  taxes: purchaseTaxesSchema,
})

const nonOperating = yup.object<FinancialInfo.Financials['nonOperating']>({
  expenses: yup.object({
    assetManagementFee: calculationItem,
    propertyImprovementReserve: calculationItem,
    cashReserve: calculationItem,
  }),
})

export const financials = yup.object<FinancialInfo.Financials>({
  base: base.required(),
  exit: exit.required(),
  purchase: purchase.required(),
  financing: financing.required(),
  operation: operation.required(),
  nonOperating: nonOperating.required(),
})
