import { routesBuilder } from '@guiker/api-definition'

import { NEWProperty } from '../../../entity'
import { updatePropertyDetails, upsertBuildingSchema, upsertPropertySchema } from '../schemas'
import { paths } from './paths'

const idNested = routesBuilder<NEWProperty>()({ basePath: paths.NEWProperty.all.withId(':id') })
const base = routesBuilder<NEWProperty>()({ basePath: paths.NEWProperty.all.base() })

export const routes = {
  readOneNEWProperty: idNested.buildGet(),
  updatePropertyDetails: idNested.buildPost({
    path: '/property-details',
    payloadSchema: updatePropertyDetails,
  }),
  upsertBuildingAndProperty: base.buildPost({
    payloadSchema: upsertBuildingSchema,
  }),
  upsertNEWPropertyBuilding: base.buildPost({
    path: '/upsert-property-building',
    payloadSchema: upsertPropertySchema,
  }),
}
