import React from 'react'

import { FinancialInfo } from '@guiker/base-entity'
import { FormSection2, ThreeColumnsGridLayout, useWatch } from '@guiker/react-framework'
import { Dropdown, NumberFormat, PercentNumberFormat } from '@guiker/rhf-components'

import { useT } from '../../i18n'

type FinancialExitFormContentProps = {}

export const FinancialExitFormContent: React.FC<FinancialExitFormContentProps> = () => {
  const name = 'financialInfo.financials.exit'
  const { tShared } = useT({ entity: name })

  const financialInfo = useWatch({ name: 'financialInfo' })
  const estimatedSalePriceCalculationMethod = financialInfo.financials.exit.estimatedSalePriceCalculationMethod

  return (
    <ThreeColumnsGridLayout>
      <Dropdown
        name={`${name}.estimatedSalePriceCalculationMethod`}
        label={tShared('estimatedSalePriceCalculationMethod.label')}
        options={Object.values(FinancialInfo.EstimatedSalePriceCalculationMethod).map((type) => ({
          label: tShared(`estimatedSalePriceCalculationMethod.options.${type}`),
          value: type,
        }))}
        condensed
        menuMaxWidth={200}
      />
      <PercentNumberFormat
        name={`${name}.rate`}
        condensed
        label={tShared(`rate.${estimatedSalePriceCalculationMethod}`)}
      />
      <NumberFormat
        decimalScale={0}
        condensed
        label={tShared('holdingPeriod.label')}
        name={`${name}.holdingPeriod`}
        endAdornment={tShared('holdingPeriod.duration')}
      />
    </ThreeColumnsGridLayout>
  )
}

export const FinancialExitFormSection: React.FC<FinancialExitFormContentProps> = () => {
  const { tComponents } = useT({ componentName: 'FinancialExitFormSection' })

  return (
    <FormSection2 title={tComponents('title')} subtitle={tComponents('description')}>
      <FinancialExitFormContent />
    </FormSection2>
  )
}
