import { deprecatedGenerateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { PayoutProviderName } from '../../entity'

const baseEvent = {
  context: 'PAYOUT',
  data: null as unknown as {
    userId: string
    providerAccountId: string
    providerName: PayoutProviderName
  },
  entity: 'LEGACY_PAYOUT_METHOD',
} as const

export enum EventType {
  PAYOUT_PROVIDER_TERMINATED = 'PAYOUT_PROVIDER_TERMINATED',
}

export const EventsDefinition = deprecatedGenerateEventsDefinition(baseEvent)
export const Events = generateEventsFromEnum(baseEvent)(EventType)
