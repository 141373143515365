import { NEWPropertyOwnership } from '@guiker/real-estate-shared'

export const getPropertyAddress = (property: NEWPropertyOwnership['property']) => {
  const { mailingAddress: address } = property.building

  return {
    ...address,
    suite: property.units?.[0].suite,
    streetNumber: address.streetNumber,
    postalCode: address.zipcode,
  }
}
