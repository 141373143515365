import React, { useMemo, useState } from 'react'

import { Flex, getContentBlocks, makeStyles, RichTextEditor } from '@guiker/react-framework'

import { CollapsibleDescriptionButton } from './CollapsibleButton'

type CollapsibleDescriptionProp = {
  description: string
  defaultCollapsed?: boolean
  lengthBeforeHiding?: number
  align?: 'left' | 'right'
}

const useStyles = makeStyles({
  description: {
    padding: 0,
  },
})

const CollapsibleDescription: React.FC<CollapsibleDescriptionProp> = ({
  description,
  defaultCollapsed = true,
  lengthBeforeHiding = 110,
  align = 'left',
}) => {
  const { isHidable } = useMemo(() => getContentBlocks(description, true, 110), [description])
  const [hideDescription, setHideDescription] = useState<boolean>(defaultCollapsed && isHidable)
  const classes = useStyles()

  return (
    <Flex flexDirection='column' gap={3}>
      <RichTextEditor
        readOnly={true}
        defaultValue={description}
        hideLongText={hideDescription}
        maxLengthBeforeHiding={lengthBeforeHiding}
        editorClassName={classes.description}
      />
      <Flex width='100%' justifyContent={align === 'left' ? 'flex-start' : 'flex-end'}>
        {isHidable && <CollapsibleDescriptionButton hide={hideDescription} setHide={setHideDescription} />}
      </Flex>
    </Flex>
  )
}

export { CollapsibleDescription }
