import { deprecatedGenerateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { Payout, PayoutMethod } from '../../entity'

export const EventsDefinition = deprecatedGenerateEventsDefinition({
  context: 'PAYOUT',
  entity: 'PAYOUT',
  data: null as Payout,
  eventTypes: {
    PENDING: 'PENDING',
    FAILED: 'FAILED',
    PAID: 'PAID',
    CANCELLED: 'CANCELLED',
    IN_TRANSIT: {
      type: 'IN_TRANSIT',
      eventData: {
        payoutMethod: null as PayoutMethod,
      },
    },
  } as const,
})
export const { events: Events, eventTypes: EventTypes } = EventsDefinition
export type EventType = ValueOfEventTypes<typeof EventsDefinition>
