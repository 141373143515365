import { deprecatedGenerateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { Lease } from '../../entity'
import { baseEvent } from '../base'
import { eventTypes } from './event-types'

const baseLeaseEvent = {
  ...baseEvent,
  entity: 'LEASE',
  data: null as unknown as Lease,
}

export const EventsDefinition = deprecatedGenerateEventsDefinition({
  ...baseLeaseEvent,
  eventTypes: { ...eventTypes } as const,
})

export const { events: Events, eventTypes: EventTypes } = EventsDefinition
export type EventTypes = ValueOfEventTypes<typeof EventsDefinition>
