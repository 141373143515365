import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'
import { AdminApiClient } from '@guiker/real-estate-shared'

export const {
  useContext: useAdminRealEstateContext,
  Context: AdminRealEstateContext,
  NestedRouter: AdminRealEstateNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.ADMIN,
  apiClientGen: AdminApiClient,
})

export const { useContext: useAdminDeveloperIdNestedContext, NestedRouter: AdminDeveloperIdNestedRouter } =
  generateBaseApiContext({
    authLevel: AuthLevel.ADMIN,
    apiClientGen: AdminApiClient,
    cacheKey: ({ developerId }: { developerId: string }) => `readOneDeveloper-${developerId}`,
    fetcher: (apiClient, { developerId }: { developerId: string }) =>
      apiClient.readOneDeveloper({ pathParams: { id: developerId } }),
  })

export const { useContext: useAdminProjectIdNestedContext, NestedRouter: AdminProjectIdNestedRouter } =
  generateBaseApiContext({
    authLevel: AuthLevel.ADMIN,
    apiClientGen: AdminApiClient,
    cacheKey: ({ projectId }: { projectId: string }) => `readOneProject-${projectId}`,
    fetcher: (apiClient, { projectId }: { projectId: string }) =>
      apiClient.readOneProject({ pathParams: { id: projectId } }),
  })
