import React, { useState } from 'react'

import { SearchAddress, SearchAddressWithFields } from '@guiker/address-autocomplete-component'
import { FormSection4 } from '@guiker/react-framework'
import { CountryCode } from '@guiker/shared-framework'

import { AdditionalAddressContainer } from './AdditionalAddressContainer'

type PropertyBuildingAddressFormContentProps = {}

export const PropertyBuildingAddressFormContent: React.FC<PropertyBuildingAddressFormContentProps> = () => {
  const [selectedAddress, setSelectedAddress] = useState<SearchAddress['address']>(undefined)

  const onAddressChange = ({ address }: SearchAddress) => {
    setSelectedAddress(address)
  }

  return (
    <FormSection4 hasDivider>
      <SearchAddressWithFields
        name={'property.building.mailingAddress'}
        countryRestrictions={[CountryCode.CA]}
        onAddressChange={onAddressChange}
        withOperatingCity
        withZipcode
      />
      <AdditionalAddressContainer selectedAddress={selectedAddress} />
    </FormSection4>
  )
}
