import { DeepPartial } from '@guiker/ts-utils'
import { yup } from '@guiker/yup-util'

import { BuildingTypes, NEWBuilding, Orientation } from '../../../entity'

export const updateNEWPropertyBuildingDetailSchema = yup.object<
  DeepPartial<{
    type: NEWBuilding['type']
    configuration: NEWBuilding['configuration']
    construction: Omit<NEWBuilding['construction'], 'project' | 'developer'> & {
      project: { id: string }
      developer: { id: string }
    }
  }>
>({
  type: yup.mixed<BuildingTypes>().oneOf(Object.values(BuildingTypes)).required(),
  configuration: yup.object({
    totalSquareFootage: yup.number().min(0).nullable(),
    frontageFootage: yup.number().min(0).nullable(),
    totalNumberOfUnits: yup.number().nullable(),
    totalStoreys: yup.number().min(1).nullable(),
    orientation: yup
      .mixed<Orientation>()
      .oneOf([...Object.values(Orientation), null])
      .nullable(),
  }),
  construction: yup
    .object({
      developer: yup.object({ id: yup.string().nullable() }),
      project: yup.object({ id: yup.string().nullable() }),
      year: yup.number().nullable().min(1500).max(2200),
    })
    .optional(),
})

export type UpdateNEWPropertyBuildingDetailSchema = yup.InferType<typeof updateNEWPropertyBuildingDetailSchema>
