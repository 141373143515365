import { deprecatedGenerateEventsDefinition, User } from '@guiker/shared-framework'

import { MultipostListingInquiry, MultipostListingInquiryProcessBatch } from '../../entity'

export const MultipostListingInquiryEventsDefinition = deprecatedGenerateEventsDefinition({
  context: 'LISTING',
  entity: 'MULTIPOST_LISTING_INQUIRY',
  data: null as unknown as MultipostListingInquiry,
  eventTypes: {
    RENTSYNC_INQUIRY_CREATED: {
      type: 'RENTSYNC_INQUIRY_CREATED',
      eventData: null as {
        firstName: string
        lastName: string
        fullName: string
        phone: string
        source: string
        email: string
        comment: string
        sentAt: string
        adUrl: string
        recipientEmail: string
        inboundEmailId: number
        buildingId: string
        propertyName: string
        listingId: string
      },
    },
    BATCH_PROCESSED: {
      type: 'BATCH_PROCESSED',
      eventData: null as { user: User } & MultipostListingInquiryProcessBatch,
    },
  } as const,
})

export const { eventTypes: MultipostListingInquiryEventTypes, events: MultipostListingInquiryEvents } =
  MultipostListingInquiryEventsDefinition
