import { deprecatedGenerateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { FundingAccount } from '../../entity'
import { baseEvent } from '../base'

export const definition = deprecatedGenerateEventsDefinition({
  ...baseEvent,
  entity: 'FUNDING_ACCOUNT',
  data: null as FundingAccount,
})

export const { eventTypes: EventTypes, events: Events } = definition
export type EventTypes = ValueOfEventTypes<typeof definition>
