import { enumArrayLike, enumLike, TypeOf, yup } from '@guiker/shared-framework'

import {
  BuildingTypes,
  FloorPlan,
  NEWUnit,
  Orientation,
  Parking,
  ParkingTypes,
  UnitAmenities,
  UnitType,
} from '../../../entity'

export const floorplanSchema = yup.object<FloorPlan>({
  bedroom: yup.number().default(0).nullable(),
  den: yup.number().default(0).nullable(),
  bathroom: yup.number().default(0).nullable(),
  halfBathroom: yup.number().default(0).nullable(),
  kitchen: yup.number().default(0).nullable(),
  livingRoom: yup.number().default(0).nullable(),
})

export const parkingSchema = yup.object<Parking>({
  type: enumLike(ParkingTypes),
  chargingStation: yup.boolean().nullable().default(false),
})

export const parkingsSchema = yup.array().of(parkingSchema).nullable()

export const amenitiesSchema = enumArrayLike(UnitAmenities).nullable()

export const unitSchema = (buildingTypeContextKey: string) =>
  yup
    .object<
      Pick<NEWUnit, 'type' | 'squareFootage' | 'floorplan' | 'amenities' | 'parkings' | 'floorLevel' | 'orientation'>
    >({
      type: enumLike(UnitType).required(),
      squareFootage: yup.number().required(),
      floorplan: floorplanSchema.required(),
      amenities: amenitiesSchema.required(),
      parkings: parkingsSchema.required(),
      floorLevel: yup.number().nullable(),
      orientation: enumLike(Orientation).required(),
    })
    .when(buildingTypeContextKey, (type: BuildingTypes, schema) => {
      if (type === BuildingTypes.PLEX) {
        return schema.concat(yup.object({ streetNumber: yup.string().required() }))
      } else if (type === BuildingTypes.MIXED) {
        return schema.concat(yup.object({ suite: yup.string().required(), streetNumber: yup.string() }))
      } else {
        return schema.concat(yup.object({ suite: yup.string().required() }))
      }
    })

export const getUnitsSchema = (buildingTypeContextKey: string) =>
  yup
    .object({
      units: yup.array().of(unitSchema(buildingTypeContextKey)).min(1).required(),
    })
    .required()

export const unitsSchema = getUnitsSchema('$building.type')

export type UnitsSchema = TypeOf<typeof unitsSchema>
