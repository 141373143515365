import React from 'react'

import { FinancialInfo } from '@guiker/base-entity'
import { money } from '@guiker/money'
import {
  CurrencyTextField,
  Flex,
  Grid,
  H3,
  Inline,
  NumberFormat,
  numberFormatter,
  PSmall,
  TwoColumnsGridLayout,
  useGetInputPropsWithUseT,
  useScreenSize,
} from '@guiker/react-framework'

import { useFinancialInfoContext } from '../../context'
import { useT } from '../../i18n'

export const FinancingAssumptions: React.FC = () => {
  const { tShared, tMain } = useT()
  const { results, currency } = useFinancialInfoContext()
  const { isSm } = useScreenSize()

  const inputProps = useGetInputPropsWithUseT<{ financialInfo: FinancialInfo.FinancialInfo }>()({
    t: tShared,
    formPrefix: 'financialInfo',
  })

  return (
    <Flex flexDirection={isSm ? 'column-reverse' : 'column'} gap={6}>
      <Grid container spacing={6}>
        <Grid item md={4} xs={12}>
          <Flex flexDirection='column' alignItems='center'>
            <PSmall>{tMain(`mortgage.mortgageRequired`)}</PSmall>
            <H3 mb={0}>{money.fromAmount(results.loanAmount, currency).toString('onlySymbol', true)}</H3>
          </Flex>
        </Grid>
        <Grid item md={4} xs={12}>
          <Flex flexDirection='column' alignItems='center'>
            <PSmall>{tMain(`mortgage.downPayment`)}</PSmall>
            <H3 mb={0}>{money.fromAmount(results.downPaymentAmount, currency).toString('onlySymbol', true)}</H3>
          </Flex>
        </Grid>
        <Grid item md={4} xs={12}>
          <Flex flexDirection='column' alignItems='center'>
            <PSmall>{tMain(`mortgage.monthlyPayment`)}</PSmall>
            <H3 mb={0}>{money.fromAmount(results.monthlyMortgagePayment, currency).toString('onlySymbol', true)}</H3>
          </Flex>
        </Grid>
      </Grid>
      <TwoColumnsGridLayout>
        <CurrencyTextField
          {...inputProps('financials.purchase.price.value.amount')}
          label={tShared('financials.purchase.price')}
          currency={currency}
          allowNegative={false}
        />
        <NumberFormat
          {...inputProps('financials.financing.mortgage.downPayment.value.rate')}
          endAdornment={<Inline>%</Inline>}
          decimalScale={2}
          allowNegative={false}
          max={100}
          formatter={numberFormatter.percentage(2)}
        />
        <NumberFormat
          {...inputProps('financials.financing.mortgage.termsInYears')}
          endAdornment={<Inline>{tMain('common:duration.years')}</Inline>}
          decimalScale={0}
          allowNegative={false}
        />
        <NumberFormat
          {...inputProps('financials.financing.mortgage.interestRate')}
          endAdornment={<Inline>%</Inline>}
          decimalScale={4}
          allowNegative={false}
          formatter={numberFormatter.percentage(4)}
        />
      </TwoColumnsGridLayout>
    </Flex>
  )
}
