import React from 'react'

import { Flex, FlexProps, H2, P, PBold, PSmall, PSmaller } from '@guiker/react-framework'
import { Currency, CurrencyISO, isString, math, money } from '@guiker/shared-framework'

export type LabeledValueProps = {
  align?: 'right'
  size?: 'large' | 'medium' | 'small'
  justifyContent?: FlexProps['justifyContent']
  alignItems?: FlexProps['alignItems']
  direction?: 'column' | 'row'
  label: string
  value: string | React.ReactNode
}

export const LabeledValue: React.FC<LabeledValueProps> = ({ label, align, value, size, direction = 'column' }) => {
  const flexProps: FlexProps =
    direction === 'row'
      ? {
          alignItems: 'baseline',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }
      : {
          flexDirection: 'column',
          alignItems: align === 'right' ? 'flex-end' : 'flex-start',
          gap: 0.5,
        }

  const LabelComponent = size === 'small' ? PSmaller : PSmall
  const ValueComponent = size === 'large' ? H2 : size === 'small' ? P : PBold

  return (
    <Flex {...flexProps}>
      <LabelComponent mb={0}>{label}</LabelComponent>
      {isString(value) ? <ValueComponent mb={0}>{value}</ValueComponent> : value}
    </Flex>
  )
}

type LabeledMonetaryValueProps = Omit<LabeledValueProps, 'value'> & {
  currencySymbol?: boolean | 'onlySymbol'
  noDecimal?: boolean
  value: {
    value: number
    currency: Currency | CurrencyISO
  }
}

export const LabeledMonetaryValue: React.FC<LabeledMonetaryValueProps> = ({
  label,
  currencySymbol,
  noDecimal = true,
  value,
  ...props
}) => {
  const formatted = money.fromAmount(value.value, value.currency).toString(currencySymbol, noDecimal)

  return <LabeledValue label={label} value={formatted} {...props} />
}

type LabeledPercentValueProps = Omit<LabeledValueProps, 'value'> & {
  value: number
}

export const LabeledPercentValue: React.FC<LabeledPercentValueProps> = ({ label, value, ...props }) => {
  return <LabeledValue label={label} value={`${math.decimal.round(value * 100, 2)}%`} {...props} />
}
