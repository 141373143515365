import React from 'react'

import { FinancialInfo } from '@guiker/base-entity'
import { ColumnConfig, P, TFunction } from '@guiker/react-framework'
import { NEWUnit } from '@guiker/real-estate-shared'
import { Currency } from '@guiker/shared-framework'

import { RentalInfo, UnitConfiguration } from './BaseComponents'

export const getColumns = (
  currency: Currency,
  occupancies: FinancialInfo.Occupancy[],
  t: TFunction,
): ColumnConfig<NEWUnit>[] => {
  return [
    {
      name: 'unit',
      size: 1.5,
      label: t('revenue.occupancy.unit'),
      verticalAlign: 'top',
      renderValue: (unit) => <P mb={0}>{unit.label}</P>,
    },
    {
      name: 'leaseTerm',
      size: 3,
      label: t('revenue.occupancy.leaseTerm.label'),
      verticalAlign: 'top',
      renderValue: (unit) => {
        return <UnitConfiguration unit={unit} occupancies={occupancies} />
      },
    },
    {
      name: 'monthlyRent',
      size: 2,
      label: t('revenue.occupancy.monthlyRent.label'),
      verticalAlign: 'top',
      renderValue: (unit) => {
        return <RentalInfo unit={unit} occupancies={occupancies} currency={currency.iso} />
      },
    },
  ]
}
