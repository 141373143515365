import { BuildingIcon, HouseIcon } from '@guiker/icons'
import { BuildingTypes, NEWProperty } from '@guiker/real-estate-shared'

import { showBuildingOrientation, showTotalNumberOfUnits, showTotalSquareFootage, showTotalStoreys } from '../../utils'

export const findBuildingIcon = (buildingType: BuildingTypes) => {
  const tallBuildings = [BuildingTypes.COMMERCIAL, BuildingTypes.CONDO, BuildingTypes.MIXED]
  return tallBuildings.includes(buildingType) ? BuildingIcon : HouseIcon
}

export const hasTwoOrMoreAttributes = (property: NEWProperty): boolean => {
  let count = 0
  if (property.building.type) count++
  if (!property.building.configuration && property.units?.length < 1) return false
  if (showTotalSquareFootage(property)) count++
  if (showBuildingOrientation(property.building)) count++
  if (showTotalNumberOfUnits(property)) count++
  if (showTotalStoreys(property.building)) count++
  return count > 2
}
