import { get } from 'lodash'

import { DeepGetDotNotation, KeyOfDotNotation } from '@guiker/ts-utils'

import { camelCase, kebabCase, pascalCase, snakeCase } from './casing'

type HeaderCasing = 'snakeCase' | 'camelCase' | 'kebabCase' | 'pascalCase'
const safeSnakeCase = (key: string) => {
  return key.split('.').map(snakeCase).join('.')
}

const getHeaderCasingTransformer = (headerCasing?: HeaderCasing) => {
  switch (headerCasing) {
    case 'camelCase':
      return camelCase
    case 'kebabCase':
      return kebabCase
    case 'pascalCase':
      return pascalCase
    case 'snakeCase':
      return safeSnakeCase
    default:
      return (key: string) => key
  }
}

export const buildHeaders = (args: { headers: string[]; headerCasing?: HeaderCasing }) => {
  const headers: string[] = []
  const headerCasingTransformer = getHeaderCasingTransformer(args.headerCasing)

  for (const key of args.headers) {
    headers.push(headerCasingTransformer(key))
  }

  return headers
}

export const toCSV = <T, Headers extends KeyOfDotNotation<T>>(
  entities: T[],
  {
    headers,
    transformers,
    headerCasing = 'snakeCase',
  }: {
    headers: Headers[]
    transformers?: Partial<{
      [K in Headers]: (value: DeepGetDotNotation<T, K>) => string | number
    }>
    headerCasing?: HeaderCasing
  },
): string[][] => {
  const rows = []

  rows.push(buildHeaders({ headers, headerCasing }))

  for (const entity of entities) {
    const row: string[] = []
    for (const header of headers) {
      const key = header
      let value = get(entity, key)
      const transformer = transformers[header as keyof typeof transformers]

      if (transformer) {
        value = transformer(value)
      }

      row.push(value)
    }

    rows.push(row)
  }

  return rows
}

export const csvToString = (rows: string[][]): string => {
  return rows.map((row) => row.map((f) => (f?.includes?.(',') ? `"${f}"` : f || '')).join(',')).join('\n')
}
