import { routesBuilder } from '@guiker/shared-framework'

import { PropSharingListing } from '../../../../entity'
import { paths } from '../../paths'

const { buildGet } = routesBuilder<PropSharingListing>()({
  basePath: paths.listings.withId.path,
})

export const routes = {
  readOneListing: buildGet(),
}
