import { CamelCase } from './casing'

export * from 'ts-essentials'
export * from './casing'
export * from './dot-notation'
export * from './infer'
export * from './path'

export type StrictReturnType<T extends (...args: any) => any> = T extends (...args: any) => infer R ? R : never

export type AsyncFunction<T = any> = (...args: any) => Promise<T>

export type AsyncReturnType<T extends AsyncFunction> = T extends AsyncFunction<infer R> ? R : never

export type ObjectReturnType<T extends (...args: any) => any, K extends keyof StrictReturnType<T>> = T extends (
  ...args: any
) => any
  ? StrictReturnType<T>[K]
  : never

export type Unpacked<T> = T extends (infer U)[] ? U : T
export type KeysOfUnion<T> = T extends T ? keyof T : never
export type ValueOfUnion<T> = T extends T ? T[keyof T] : never
export type Replace<T, K extends keyof T, TReplace> = Omit<T, K> & { [P in K]: TReplace }
export type ReplaceByReference<T, K extends keyof T> = Omit<T, K> & { [P in K]: { id: string } }

export type Modify<T, R> = Omit<T, keyof R> & R
export type Mutable<T> = { -readonly [P in keyof T]: T[P] }
export type Dedup<K extends string, Delimiter extends string> = K extends `${infer A}${Delimiter}${Delimiter}${infer B}`
  ? DedupSlash<`${A}${Delimiter}${B}`>
  : K
export type DedupSlash<K extends string> = Dedup<K, '/'>

export type RemoveTrailingDelimiter<
  S extends string,
  Delimiter extends string = '/',
> = S extends `${infer T}${Delimiter}` ? (T extends '' ? S : T) : S
export type Concat<P extends string, M extends string, Delimiter extends string = '/'> = `${P}${Delimiter}${M}`

export type SafeConcat<P extends string, M extends string, Delimiter extends string = '/'> = RemoveTrailingDelimiter<
  DedupSlash<Concat<P, M, Delimiter>>
>

export type ExtractKeyOf<O extends object> = Extract<keyof O, string>

export type OmitNeverPropertyNames<T> = { [K in keyof T]: T[K] extends never ? never : K }[keyof T]
export type OmitNeverPick<T> = Pick<T, OmitNeverPropertyNames<T>>
export type StrictOmitNeverPick<T> = OmitNeverPropertyNames<T> extends never ? never : OmitNeverPick<T>

export type IsParameter<
  Part,
  StartToken extends string = ':',
  EndToken extends string = '',
> = Part extends `${StartToken}${infer ParamName}${EndToken}` ? CamelCase<ParamName> : never

export type TokenizePath<
  Path,
  StartToken extends string = ':',
  EndToken extends string = '',
> = Path extends `${infer PartA}/${infer PartB}`
  ? IsParameter<PartA, StartToken, EndToken> | TokenizePath<PartB, StartToken, EndToken>
  : IsParameter<Path, StartToken, EndToken>

export type GetByDotNotation<TObject, TPath extends string> = TPath extends `${infer TKey extends keyof TObject &
  string}.${infer TRest}`
  ? GetByDotNotation<TObject[TKey], TRest>
  : TObject extends Array<unknown>
  ? TPath extends `${infer Num extends number}`
    ? TObject[Num]
    : TPath extends `${infer Num extends number}.${infer TRest extends string}`
    ? GetByDotNotation<TObject[Num], TRest>
    : never
  : TObject extends object
  ? TPath extends keyof TObject
    ? TObject[TPath]
    : never
  : never

export type PickByDotNotation<T, K extends string> = {
  [P in keyof T as P extends (K extends `${infer K0}.${string}` ? K0 : K) ? P : never]: P extends K
    ? T[P]
    : PickByDotNotation<T[P], K extends `${Exclude<P, symbol>}.${infer R}` ? R : never>
} & {}

export const castType = <T>(): T => null as T
