import { Transfer, TransferScopeType, TransferWriteModel } from '../entity'

export const transferTypeChecker = {
  isfundingAccountWithdrawal: (
    transfer: TransferWriteModel | Transfer,
  ): transfer is Transfer<TransferScopeType.FUNDING_ACCOUNT_WITHDRAWAL> => {
    return transfer?.scope?.type === TransferScopeType.FUNDING_ACCOUNT_WITHDRAWAL
  },
  isInvoice: (transfer: TransferWriteModel | Transfer): transfer is Transfer<TransferScopeType.INVOICE> => {
    return transfer?.scope?.type === TransferScopeType.INVOICE
  },
}
