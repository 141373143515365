import { CurrencyISO } from '@guiker/money'
import { TypeOf, yup } from '@guiker/shared-framework'

import { PayoutProviderName, TransferWriteModel } from '../../../entity'

export const createTransferPayloadSchema = yup.object({
  providerName: yup.string().oneOf(Object.values(PayoutProviderName)).required(),
  amount: yup.number().required(),
  currency: yup.string().oneOf(Object.values(CurrencyISO)).required(),
  payoutMethodId: yup.string().required(),
  info: yup
    .object<TransferWriteModel['info']>({
      label: yup.string().required(),
      description: yup.string().nullable().notRequired(),
      payerUserId: yup.string().nullable().notRequired(),
      payerEmailAddress: yup.string().nullable().notRequired(),
      payerName: yup.string().nullable().notRequired(),
    })
    .required(),
})

export type CreateTransferPayloadSchema = TypeOf<typeof createTransferPayloadSchema>
