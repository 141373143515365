import { routesBuilder } from '@guiker/api-definition'

import { NEWBuilding, SimilarAddressBuildings, SplitBuilding } from '../../../entity'
import {
  mergeBuildingsPayloadSchema,
  paginationSearchStringQueryParamsSchema,
  splitBuildingsPayloadSchema,
  thresholdQueryParamsSchema,
  updateNEWBuildingDetailsPayload,
  upsertBuildingSchema,
} from '../schemas'
import { paths } from './paths'

const base = routesBuilder<NEWBuilding>()({ basePath: paths.NEWBuilding.all.base() })
const idNested = routesBuilder<NEWBuilding>()({ basePath: paths.NEWBuilding.all.withId(':id') })

export const routes = {
  readOneNEWBuilding: idNested.buildGet(),
  upsertBuilding: base.buildPost({
    payloadSchema: upsertBuildingSchema,
  }),
  updateNEWBuildingDetails: idNested.buildPut({
    path: '/building-details',
    payloadSchema: updateNEWBuildingDetailsPayload,
  }),
  readAllNEWBuildings: base.buildGet({
    path: '/search/new',
    paginated: true,
    queryParamsSchema: paginationSearchStringQueryParamsSchema,
  }),
  readAllSplitNEWBuildings: base.buildGet({
    path: '/split/building',
    responseIsArray: true,
    response: null as SplitBuilding,
  }),
  readAllMergeNEWBuildings: base.buildGet({
    path: '/merge/building',
    responseIsArray: true,
    queryParamsSchema: thresholdQueryParamsSchema,
    response: null as SimilarAddressBuildings,
  }),
  mergeBuildings: base.buildPut({
    path: '/merge-buildings',
    payloadSchema: mergeBuildingsPayloadSchema,
  }),
  splitBuildings: base.buildPut({
    path: '/split-buildings',
    payloadSchema: splitBuildingsPayloadSchema,
  }),
}
