import React from 'react'

import { BaseTable, BaseTD, BaseTR, clsx, makeStyles, PBold, PSmall } from '@guiker/react-framework'
import { Currency, money } from '@guiker/shared-framework'

import { useFinancialInfoContext } from '../../context'
import { useT } from '../../i18n'

const AmountComponent: React.FC<React.PropsWithChildren & { currency: Currency; bold?: boolean }> = ({
  bold,
  children,
  currency,
}) => {
  const Component = bold ? PBold : PSmall

  return (
    <Component whiteSpace='pre' textAlign='right'>
      {money.fromAmount(Number(children), currency).toString('onlySymbol', true)}
    </Component>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'auto',
  },
  row: {
    display: 'table-row-group',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
  },
  emptyRow: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  rowHeader: ({ withFrozenHeaders }: { withFrozenHeaders: boolean }) => ({
    ...(withFrozenHeaders
      ? {
          width: 200,
          position: 'absolute',
          display: 'block',
          borderBottom: 'none',
          paddingLeft: 0,
        }
      : {}),
    '& > p': {
      paddingLeft: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
  }),
  shifted: {
    paddingLeft: 230,
  },
  td: {
    minWidth: 100,
  },
}))

type RowProps = {
  data: any
  currency: Currency
  label: string
  bold?: boolean
  withFrozenHeaders?: boolean
}

const Row: React.FC<RowProps> = ({ data, bold, currency, label, withFrozenHeaders }) => {
  const classes = useStyles({ withFrozenHeaders })

  const Component = bold ? PBold : PSmall

  return (
    <BaseTR className={clsx(classes.row)}>
      <BaseTD className={classes.rowHeader}>
        <Component whiteSpace='pre'>{label}</Component>
      </BaseTD>
      {data.map((item, index) => (
        <BaseTD key={index} className={index === 0 && withFrozenHeaders && classes.shifted}>
          <AmountComponent bold={bold} currency={currency}>
            {item}
          </AmountComponent>
        </BaseTD>
      ))}
    </BaseTR>
  )
}

const EmptyRow: React.FC<{ colSpan: number; withFrozenHeaders: boolean }> = ({ colSpan, withFrozenHeaders }) => {
  const classes = useStyles()

  return (
    <BaseTR className={clsx(classes.row, classes.emptyRow)}>
      {withFrozenHeaders && <BaseTD className={classes.rowHeader}>&nbsp;</BaseTD>}
      {Array(colSpan)
        .fill(0)
        .map((_, index) => (
          <BaseTD key={index} className={clsx(withFrozenHeaders && index === 0 && classes.shifted, classes.td)}>
            &nbsp;
          </BaseTD>
        ))}
    </BaseTR>
  )
}

type YearlyPAndLProps = {
  withFrozenHeaders?: boolean
}

const YearlyPAndL: React.FC<YearlyPAndLProps> = ({ withFrozenHeaders = true }) => {
  const { tShared } = useT({ entity: 'property.financialInfo' })
  const { financials, results, currency } = useFinancialInfoContext()
  const classes = useStyles({ withFrozenHeaders })

  return (
    <>
      <BaseTable className={classes.root} fullWidth={false}>
        <tbody>
          <BaseTR className={classes.row}>
            <BaseTD className={classes.rowHeader}>
              <PBold whiteSpace='pre'>&nbsp;</PBold>
            </BaseTD>
            {results.yearlyCashflows.map(({ year }, index) => (
              <BaseTD key={index} className={clsx(withFrozenHeaders && index === 0 && classes.shifted, classes.td)}>
                <PBold textAlign='right' whiteSpace='pre'>
                  {tShared('results.year', { year })}
                </PBold>
              </BaseTD>
            ))}
          </BaseTR>
          <Row
            label={tShared('financials.operation.revenues.rentalIncome')}
            currency={currency}
            data={results.yearlyCashflows.map((d) => d.rentalIncome)}
            withFrozenHeaders={withFrozenHeaders}
          />
          <Row
            label={tShared('financials.operation.revenues.vacancyRate')}
            currency={currency}
            data={results.yearlyCashflows.map((d) => d.vacancyAllowance)}
            withFrozenHeaders={withFrozenHeaders}
          />
          <Row
            label={tShared('results.netRentalRevenue')}
            currency={currency}
            bold
            data={results.yearlyCashflows.map((d) => d.netRentalRevenue)}
            withFrozenHeaders={withFrozenHeaders}
          />
          {Object.keys(financials.operation.expenses.costs).map((cost, index) => (
            <Row
              key={index}
              label={tShared(`financials.operation.expenses.costs.${cost}`)}
              currency={currency}
              data={results.yearlyCashflows.map((d) => -d.operatingExpenses.find((e) => e.type === cost).computedValue)}
              withFrozenHeaders={withFrozenHeaders}
            />
          ))}
          {Object.keys(financials.operation.expenses.taxes).map((tax, index) => (
            <Row
              key={index}
              label={tShared(`financials.operation.expenses.taxes.${tax}`)}
              currency={currency}
              data={results.yearlyCashflows.map((d) => -d.operatingExpenses.find((e) => e.type === tax).computedValue)}
              withFrozenHeaders={withFrozenHeaders}
            />
          ))}
          <Row
            label={tShared('results.netOperatingIncome')}
            currency={currency}
            bold
            data={results.yearlyCashflows.map((d) => d.netOperatingIncome)}
            withFrozenHeaders={withFrozenHeaders}
          />
          <Row
            label={tShared('results.totalDebtService')}
            currency={currency}
            data={results.yearlyCashflows.map((d) => d.totalDebtService)}
            withFrozenHeaders={withFrozenHeaders}
          />
          {Object.keys(financials.nonOperating.expenses).map((cost) => (
            <Row
              key={`financials.nonOperating.expenses.${cost}`}
              label={tShared(`financials.nonOperating.expenses.${cost}`)}
              currency={currency}
              data={results.yearlyCashflows.map(
                (d) => -d.nonOperatingExpenses.find((exp) => exp.type === cost).computedValue,
              )}
              withFrozenHeaders={withFrozenHeaders}
            />
          ))}
          <Row
            label={tShared('results.netIncome')}
            bold
            currency={currency}
            data={results.yearlyCashflows.map((d) => d.netIncome)}
            withFrozenHeaders={withFrozenHeaders}
          />
          <EmptyRow colSpan={results.yearlyCashflows.length} withFrozenHeaders={withFrozenHeaders} />
          <Row
            label={tShared('results.mortgageBalance')}
            bold
            currency={currency}
            data={results.yearlyCashflows.map((d) => -d.mortgageBalance)}
            withFrozenHeaders={withFrozenHeaders}
          />
          <Row
            label={tShared('results.estimatedSalesPrice')}
            bold
            currency={currency}
            data={results.yearlyCashflows.map((d) => d.estimatedSalesPrice)}
            withFrozenHeaders={withFrozenHeaders}
          />
          <Row
            label={tShared('results.netCashFlow')}
            bold
            currency={currency}
            data={results.yearlyCashflows.map((d) => d.netCashFlow)}
            withFrozenHeaders={withFrozenHeaders}
          />
        </tbody>
      </BaseTable>
    </>
  )
}

export { YearlyPAndL }
