import { transferTypeChecker } from '@guiker/payment-shared'
import {
  Payout,
  PayoutStatus,
  Transfer as TransferEntity,
  TransferStatus,
  TransferWriteModel,
} from '@guiker/payout-shared'
import { AnyColor } from '@guiker/react-framework'
import { formatDate, last, money, optionalConcat } from '@guiker/shared-framework'

type Transfer = TransferEntity | TransferWriteModel

const getTransfersTotal = (transfers: Transfer[]) => {
  if (!transfers?.length) return 0

  return transfers.reduce((acc, transfer) => {
    const isReverse = transfer.status === TransferStatus.REVERSED
    return isReverse ? acc - transfer.amount : acc + transfer.amount
  }, 0)
}

export const transferHelper = {
  getFullTransfers: <T extends Transfer>(payout: Payout & { transfers?: T[] }) => {
    if (payout.status !== PayoutStatus.PAID) return payout.transfers

    const inaccessibleTotal = payout.amount - getTransfersTotal(payout.transfers)
    if (inaccessibleTotal > 0 && !!payout.transfers?.length) {
      const { scope, status, createdAt, ...sourceTransfer } = last(payout.transfers)
      return [
        ...payout.transfers,
        {
          ...sourceTransfer,
          scope: { type: scope.type, id: scope.id },
          amount: inaccessibleTotal,
        } as T,
      ]
    }

    return payout.transfers
  },
  getInfoDisplay: (transfer: Transfer) => {
    const { info, createdAt } = transfer
    let secondaryInfo = info?.description
    if (secondaryInfo.indexOf('/') !== -1) {
      const [firstLine, secondLine] = secondaryInfo.split('/')
      const [city] = secondLine?.split(',') || [secondLine]
      secondaryInfo = `${firstLine}, ${city}`
    }

    const dateComponent = !!createdAt ? formatDate(createdAt) : undefined
    return { label: secondaryInfo, date: dateComponent }
  },
  formatAmount: (transfer: Transfer): { amount: string; color: AnyColor | 'inherit' } => {
    const isOtherPayments = !(transfer as TransferEntity).scope.data
    const isReverse = transfer.status === TransferStatus.REVERSED
    const amount = Math.abs(transfer.amount)
    const formattedAmount = money.fromAmount(amount, transfer.currency).toString('onlySymbol')

    return {
      amount: isReverse ? optionalConcat(['(', formattedAmount, ')'], '') : formattedAmount,
      color: isOtherPayments ? 60 : 'inherit',
    }
  },
  hasScopedData: (transfer: Transfer) => transferTypeChecker.isInvoice(transfer) && !!transfer.scope.data,
}
