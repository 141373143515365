import React from 'react'

import { FinancialInfo } from '@guiker/base-entity'
import { useGetInputPropsWithUseT, useWatch } from '@guiker/react-framework'
import {
  Flex,
  FormSection3,
  FormSection4,
  Grid,
  H3,
  PSmall,
  Slider,
  sliderNumberFormatter,
  Spinner,
  TwoColumnsGridLayout,
  useScreenSize,
} from '@guiker/react-framework'
import { math, money } from '@guiker/shared-framework'

import { useFinancialInfoContext } from '../../context'
import { useT } from '../../i18n'

export const InvestmentAssumptions: React.FC = () => {
  const { tShared, tMain } = useT()
  const { results, currency, rentalIncomeMinMax, rentalGrowthRateMinMax, exitRateMinMax } = useFinancialInfoContext()
  const financialInfo = useWatch({ name: 'financialInfo' })
  const { isSm } = useScreenSize()

  const inputProps = useGetInputPropsWithUseT<{ financialInfo: FinancialInfo.FinancialInfo }>()({
    t: tShared,
    formPrefix: 'financialInfo',
  })

  if (!financialInfo?.financials) {
    return <Spinner />
  }

  const content = (
    <Grid container spacing={6}>
      <Grid item md={6} xs={12}>
        <Flex flexDirection='column' alignItems='center'>
          <PSmall>{tMain(`financialInfo.results.irr`)}</PSmall>
          <H3 mb={0}>{`${math.decimal.round(results.irr * 100, 2)}%`}</H3>
        </Flex>
      </Grid>
      <Grid item md={6} xs={12}>
        <Flex flexDirection='column' alignItems='center'>
          <PSmall>{tMain(`financialInfo.results.estimatedSalesPrice`)}</PSmall>
          <H3 mb={0}>{money.fromAmount(results.estimatedSalesPrice, currency).toString('onlySymbol', true)}</H3>
        </Flex>
      </Grid>
    </Grid>
  )

  return (
    <FormSection3>
      {isSm && content}
      <FormSection4>
        <TwoColumnsGridLayout>
          <Slider
            {...inputProps('financials.operation.revenues.rentalIncome.value')}
            track={false}
            min={rentalIncomeMinMax.min}
            max={rentalIncomeMinMax.max}
            formatter={sliderNumberFormatter.currency(currency)}
            step={1000}
            marks={[
              {
                value: financialInfo.financials.operation.revenues.rentalIncome.value,
              },
            ]}
          />
          <Slider
            {...inputProps('financials.operation.revenues.rentalIncome.value.yearlyGrowthRate')}
            track={false}
            min={rentalGrowthRateMinMax.min}
            max={rentalGrowthRateMinMax.max}
            formatter={sliderNumberFormatter.percentage(2)}
            step={0.0025}
            marks={[
              {
                value: financialInfo.financials.operation.revenues.rentalIncome.value.yearlyGrowthRate,
              },
            ]}
          />
        </TwoColumnsGridLayout>
      </FormSection4>
      <FormSection4>
        <TwoColumnsGridLayout>
          <Slider
            {...inputProps('financials.exit.holdingPeriod')}
            track={false}
            min={1}
            max={25}
            formatter={sliderNumberFormatter.year()}
            step={1}
            marks={[
              {
                value: financialInfo.financials.exit.holdingPeriod,
              },
            ]}
          />
          <Slider
            {...inputProps('financials.exit.rate')}
            track={false}
            min={exitRateMinMax.min}
            max={exitRateMinMax.max}
            step={0.0025}
            formatter={sliderNumberFormatter.percentage(2)}
            marks={[
              {
                value: financialInfo.financials.base.propertyAppreciationRate,
              },
            ]}
          />
        </TwoColumnsGridLayout>
      </FormSection4>
      {!isSm && content}
    </FormSection3>
  )
}
