export const CommonOperationCost = {
  condoFee: {
    value: {
      type: 'flat',
      amount: 0,
    },
  },
  insurance: {
    value: {
      type: 'flat',
      amount: 0,
    },
  },
  utility: {
    value: {
      type: 'flat',
      amount: 0,
    },
  },
  managementFee: {
    value: {
      type: 'percentage',
      base: 'rentalIncome',
      rate: 0.05,
    },
  },
  repairAllowance: {
    value: {
      type: 'percentage',
      base: 'rentalIncome',
      rate: 0.05,
    },
  },
} as const
