import { AdminApi } from '@guiker/real-estate-shared'
import { TypeOf, yup } from '@guiker/shared-framework'

export const updatePropertyBuildingDetail = yup.object({
  property: yup
    .object({
      building: AdminApi.Schema.updateNEWPropertyBuildingDetailSchema.required(),
    })
    .required(),
})

export type UpdatePropertyBuildingDetail = TypeOf<typeof updatePropertyBuildingDetail>
