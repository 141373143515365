import { InvestmentAssumptions } from '@guiker/base-listing-shared'
import { money } from '@guiker/shared-framework'

import { PropSharingListing, StockAllowance, stockOrderTransactionFee } from '../entity'
import { getStockHelper, totalUnitQuantity } from './stock'

export const getListingHelpers = (listing: PropSharingListing, stockAllowance: StockAllowance) => {
  const stockHelper = getStockHelper(listing)

  return {
    currency: money.currency[listing.stock.currency],
    canPurchaseStock: stockHelper.hasOpenSeries && !stockHelper.isFullyFunded && !stockAllowance?.unavailableReason,
    allowance: {
      maxQuantity: stockAllowance?.maxQuantity ?? listing.stock.maxPerAccount,
      minQuantity: stockAllowance?.minQuantity ?? listing.stock.minPerTransaction,
    },
    stock: { ...listing.stock, ...stockHelper },
    getPerInquiryHelpers: (results: InvestmentAssumptions.Results, quantity: number) => {
      const total = totalUnitQuantity(listing.stock)
      const pricePerUnit = stockHelper.pricePerUnit
      const expectedRevenue = (results?.npv / total) * quantity - stockOrderTransactionFee[listing.stock.currency]
      const gainPerUnit = results?.npv / total - (pricePerUnit ?? 0)
      const totalGain = quantity * gainPerUnit

      return {
        expectedRevenue,
        gainPerUnit,
        totalGain,
      }
    },
  }
}
