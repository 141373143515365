import { parseJwtToken } from '@guiker/base-jwt'
import { isArray } from '@guiker/lodash'
import { buildPaginationMeta, buildPaginationQueryParamSchema, Paginate } from '@guiker/paginate'
import { yup } from '@guiker/yup-util'

export const validatorBuilder = {
  buildPayloadValidator: <T extends object>(schema: yup.ObjectSchema<T>) => {
    return (payload: unknown) => schema.validate(payload) as Promise<T>
  },
  buildParamsValidator: <T extends object>(schema: yup.ObjectSchema<T>) => {
    return (params: unknown) => schema.validate(params) as Promise<T>
  },
  buildResponseValidator: <R>() => {
    return (response: unknown) => Promise.resolve(response as R)
  },
  buildPaginatedResponseValidator: <R, AM extends {}>() => {
    return (response: unknown) =>
      Promise.resolve(
        response as {
          data: R[]
          meta: ReturnType<typeof buildPaginationMeta> & AM
        },
      )
  },
  buildPaginationQueryParamValidator: <T extends object = {}>(schema?: yup.ObjectSchema<T>) => {
    return (payload: unknown) => {
      const fullSchema = schema ? schema.concat(buildPaginationQueryParamSchema()) : buildPaginationQueryParamSchema()
      return fullSchema.validate(payload) as Promise<T & Partial<Paginate>>
    }
  },
  buildAccessControlValidator: <C extends object>(schema: yup.ObjectSchema<C>) => {
    return async (
      token: string,
    ): Promise<{
      token: string
      claims?: C
    }> => {
      const claims = parseJwtToken(token as string)

      return { token, claims: await schema.validate(claims) }
    }
  },
}
export type ValidatorBuilder = typeof validatorBuilder

export const baseParamsSchema = <T extends string>(keys: T | Readonly<T[]>) =>
  yup
    .object(
      (isArray(keys) ? keys : [keys]).reduce(
        (acc, key) => ({
          ...acc,
          [key]: yup.string().required(),
        }),
        {},
      ),
    )
    .required() as yup.ObjectSchema<{ [key in T]: string }>

export const baseParamsValidator = <T extends string>(key: T | T[]) =>
  validatorBuilder.buildParamsValidator(baseParamsSchema(key))

export const idNestedParamsValidator = baseParamsValidator('id')
export const idNestedParamsSchema = baseParamsSchema('id')
export const geocoordinatesSchema = yup.object({
  lat: yup.number().min(-90).max(90).required(),
  lng: yup.number().min(-180).max(180).required(),
})
