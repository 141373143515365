import { DealType } from '../../../../deal'
import { Bracket } from '../../../calculation-item'
import { CostArgs } from '../../../utils'
import { CanadaPurchaseTaxes } from '../purchase-taxes'

export const WelcomeTax = {
  value: {
    base: 'maxPropertyValue',
    type: 'bracket',
    brackets: null as Bracket[],
  },
} as const

export const GST = {
  value: {
    type: 'percentage',
    base: 'purchasePrice',
    rate: 0.05,
  },
} as const

export const QST = {
  value: {
    type: 'percentage',
    base: 'purchasePrice',
    rate: 0.09975,
  },
} as const

export const QuebecPurchaseTaxes = (args: CostArgs) => ({
  ...CanadaPurchaseTaxes,
  ...([DealType.NEW, DealType.PRECON].includes(args.dealType) ? { QST, GST } : undefined),
  welcomeTax: WelcomeTax,
})
