import React from 'react'

import {
  Checkbox,
  Dropdown,
  Link,
  MinusSmallIcon,
  TwoColumnsGridLayout,
  useGetInputPropsWithUseT,
} from '@guiker/react-framework'
import { NEWProperty, ParkingTypes } from '@guiker/real-estate-shared'

import { useT } from '../../i18n'

type ParkingComponentProps = {
  name: `property.units.${number}.parkings.${number}`
  onRemove: () => void
}

export const ParkingComponent: React.FC<ParkingComponentProps> = ({ onRemove, name }) => {
  const { tShared, tComponents } = useT({
    componentName: 'PropertyUnitsFormContent.parkings',
    entity: 'unit.parking',
  })

  const getInputProps = useGetInputPropsWithUseT<{ property: NEWProperty }>()({
    t: tShared,
    formPrefix: name,
  })

  return (
    <TwoColumnsGridLayout>
      <Dropdown
        {...getInputProps('type', { i18nKey: 'type.label' })}
        options={Object.keys(ParkingTypes).map((type) => ({
          value: ParkingTypes[type],
          label: tShared(`type.options.${type}`),
        }))}
      />
      <Checkbox
        {...getInputProps('chargingStation', { i18nKey: 'type.label' })}
        label={tComponents('chargingStation')}
        withLabelPlaceholder={true}
      />
      <Link onClick={onRemove} underline={false} adornment={<MinusSmallIcon />}>
        {tComponents('removeParking')}
      </Link>
    </TwoColumnsGridLayout>
  )
}
