import { math, money } from '@guiker/shared-framework'

import { useFinancialInfoContext } from '../../context'
import { useT } from '../../i18n'

export const buildKpiGrid = () => {
  const { tMain } = useT({ screenName: 'screens.listingScreen.tab1.sections.offeringDetails.kpi' })
  const { results, financials, currency } = useFinancialInfoContext()
  const moneyFormatter = money.formatter(currency, 'onlySymbol', true)
  const propertyValue = financials.purchase.price.value.amount
  const mortgage = financials.financing.mortgage

  return [
    {
      label: tMain('totalPurchaseCost'),
      tip: tMain('totalPurchaseCostTip'),
      description: moneyFormatter(money.fromAmount(results.totalProjectCost, currency).monetized),
    },
    {
      label: tMain('purchasePrice'),
      tip: tMain('purchasePriceTip'),
      description: moneyFormatter(money.fromAmount(propertyValue, currency).monetized),
    },
    {
      label: tMain('leverage'),
      tip: tMain('leverageTip'),
      description: moneyFormatter(money.fromAmount(results.downPaymentAmount, currency).monetized),
    },
    {
      label: tMain('interest'),
      description: `${math.decimal.round(mortgage.interestRate * 100, 2)}%`,
    },
  ]
}

export const buildPurchaseBreakdown = () => {
  const { results, financials } = useFinancialInfoContext()
  const { tShared } = useT({ entity: 'purchase.costs' })
  const cashReserve = results?.computedPurchaseCosts.find((c) => c.type === 'cashReserve')?.computedValue ?? 0
  const reno = results?.computedPurchaseCosts.find((c) => c.type === 'initialRenovation')?.computedValue ?? 0
  const totalRenoReserve = cashReserve + reno
  const offeringCost = results?.computedPurchaseCosts.find((c) => c.type === 'offeringCost')?.computedValue ?? 0
  const closingCost = results?.computedPurchaseCosts.find((c) => c.type === 'closingCost')?.computedValue ?? 0
  const taxes = results?.computedPurchaseTaxes?.reduce((acc, cur) => cur.computedValue + acc, 0)
  const otherCosts = offeringCost + closingCost + taxes

  return [
    {
      label: tShared('purchasePrice'),
      value: financials.purchase.price.value.amount,
    },
    {
      label: tShared('renovation'),
      value: totalRenoReserve,
      collapsibleItems: [
        {
          label: tShared('propertyImprovement'),
          value: reno,
        },
        {
          label: tShared('cashReserve'),
          value: cashReserve,
        },
      ],
    },
    {
      label: tShared('otherCosts'),
      value: otherCosts,
      collapsibleItems: [
        {
          label: tShared('taxes'),
          value: taxes,
        },
        {
          label: tShared('closingCost'),
          value: closingCost,
        },
        {
          label: tShared('offeringCost'),
          value: offeringCost,
        },
      ],
    },
  ]
}
