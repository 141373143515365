import { yup } from '@guiker/shared-framework'

import { Building, BuildingTypes, Orientation } from '../../../entity'

export const updatePropertyBuildingDetailSchema = yup.object<{
  type: Building['type']
  configuration: Building['configuration']
  construction: Omit<Building['construction'], 'project' | 'developer'> & {
    project?: { id: string }
    developer?: { id: string }
  }
}>({
  type: yup.mixed<BuildingTypes>().oneOf(Object.values(BuildingTypes)).required(),
  configuration: yup.object({
    frontageFootage: yup.number().min(0).nullable(),
    totalNumberOfUnits: yup.number().nullable(),
    totalStoreys: yup.number().min(1).nullable(),
    orientation: yup
      .mixed<Orientation>()
      .oneOf([...Object.values(Orientation), null])
      .nullable(),
  }),
  construction: yup
    .object({
      developer: yup.object({ id: yup.string().nullable() }),
      project: yup.object({ id: yup.string().nullable() }),
      year: yup.number().nullable().min(1500).max(2200),
    })
    .optional(),
})

export const assetsSchema = yup.array().of(yup.object({ id: yup.string().required() }))

export type UpdatePropertyBuildingDetailSchema = yup.InferType<typeof updatePropertyBuildingDetailSchema>
