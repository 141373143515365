import { EntityDocument, Geocoordinates, GeoSpatialLocation, SequentialId } from '@guiker/base-entity'

import { Address } from './address'
import { BuildingConfiguration, Configuration } from './configuration'
import { Construction } from './construction'
import { Document } from './document'
import { Picture } from './picture'

export enum BuildingTypes {
  COMMERCIAL = 'commercial',
  CONDO = 'condo',
  MIXED = 'mixed',
  PLEX = 'plex',
  SINGLE_FAMILY = 'single-family',
}

export type Building = EntityDocument & {
  id: string
  externalId: string
  type: BuildingTypes
  address: Address & { streetNumbers: string[] }
  geocoordinates: Geocoordinates
  construction?: Construction
  configuration: Configuration
} //deprecate

//////////

export enum BuildingAmenities {
  CONCIERGE = 'CONCIERGE',
  BIKE_PARKING = 'BIKE_PARKING',
  COMMUNAL_OUTDOOR_SPACE = 'COMMUNAL_OUTDOOR_SPACE',
  ELEVATOR = 'ELEVATOR',
  GYM = 'GYM',
  LAUNDRY_ROOM = 'LAUNDRY_ROOM',
  STORAGE = 'STORAGE',
  INDOOR_PARKING = 'INDOOR_PARKING',
  OUTDOOR_PARKING = 'OUTDOOR_PARKING',
  POOL = 'POOL',
  RECREATION_ROOM = 'RECREATION_ROOM',
  SECURITY = 'SECURITY',
  WHEEL_CHAIR_ACCESSIBLE = 'WHEEL_CHAIR_ACCESSIBLE',
  JANITOR = 'JANITOR',
}

export type BuildingMeta = {
  legacyIds?: number[] //legacy listing ids from migration
  sanitizedLegacyAddress?: string[] //streetName__cityName__longitude__latitude // round to 4 decimal places
  unitNumbers?: { unitNumber: string; listingId: number }[]
}

export type BuildingLocation = GeoSpatialLocation & {
  externalId?: string // google map id
  region?: string // our operational cities; ie: montreal / boston / toronto ..etc
}

export type SimilarAddressBuilding = {
  id: string
  address: NEWBuilding['mailingAddress']
  location: NEWBuilding['location']
}

export type SimilarAddressBuildings = {
  zipcode: string
  streetNumber: string
  buildings: SimilarAddressBuilding[]
  legacyIds: number[]
}

export type LegacyAddress = Address & { listingId: string; latitude: number; longitude: number }

export type SplitBuilding = NEWBuilding & {
  legacyAddresses: LegacyAddress[]
}

export type NEWBuilding = EntityDocument &
  SequentialId & {
    type: BuildingTypes
    location: BuildingLocation
    mailingAddress: Address
    addresses?: {
      streetNumber: string
      street: string
      zipcode: string
    }[]
    amenities?: BuildingAmenities[]
    pictures?: Picture[]
    documents?: Document[]
    meta?: BuildingMeta
    construction?: Construction
    configuration?: BuildingConfiguration
  }

export type WithBuilding<T> = T & {
  building: NEWBuilding
}
