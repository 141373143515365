import { HttpMethod } from '@guiker/http'

import { Legacy } from '../../entity'
import { listingIdNested, listingUnitIdNested } from '../schemas'
import { listingUidNested } from './listing-uid-nested'
import { uidQueryParamsSchema } from './uid-query-params-schema'

export const LISTING_PATH = '/auth/listing'
export const LISTING_WITH_ID_PATH = `${LISTING_PATH}/:listingId`
export const LISTING_WITH_ID_UNIT_ID_PATH = `${LISTING_WITH_ID_PATH}/unit/:unitId`
export const LISTING_WITH_UID_PATH = `${LISTING_PATH}/:listingUid`

const responseValidator = (payload: unknown) => Promise.resolve(payload as Legacy.Listing)

export const routes = {
  readOneListingUnitById: {
    path: LISTING_WITH_ID_UNIT_ID_PATH,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as any),
    pathParamsValidator: (params: unknown) => listingUnitIdNested.validate(params),
  },
  readOneListing: {
    path: LISTING_WITH_ID_PATH,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (params: unknown) => listingIdNested.validate(params),
  },
  readOneListingByUid: {
    path: `${LISTING_PATH}/read-one-by-uid`,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator,
    queryParamsValidator: (params: unknown) => uidQueryParamsSchema.validate(params),
  },
  reviveListing: {
    path: LISTING_WITH_ID_PATH,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (params: unknown) => listingIdNested.validate(params),
  },
  publishListing: {
    path: `${LISTING_WITH_UID_PATH}/publish`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (params: unknown) => listingUidNested.validate(params),
  },
}
