import { CostArgs } from '../../../../utils'
import { QuebecPurchaseTaxes } from '../purchase-taxes'

export const MontrealWelcomeTax = {
  value: {
    type: 'bracket',
    base: 'maxPropertyValue',
    brackets: [
      { rate: 0.005, threshold: 5890000 },
      { rate: 0.01, threshold: 29460000 },
      { rate: 0.015, threshold: 55230000 },
      { rate: 0.02, threshold: 110470000 },
      { rate: 0.025, threshold: 213650000 },
      { rate: 0.035, threshold: 311300000 },
      { rate: 0.04 },
    ],
  },
} as const

export const PurchaseTaxes = (args: CostArgs) =>
  ({
    ...QuebecPurchaseTaxes(args),
    welcomeTax: MontrealWelcomeTax,
  } as const)
