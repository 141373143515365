import { deprecatedGenerateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { RentalInquiry } from '../../entity'

export const definition = deprecatedGenerateEventsDefinition({
  context: 'LISTING_INQUIRY',
  entity: 'RENTAL_INQUIRY',
  data: null as unknown as RentalInquiry,
  eventTypes: {
    INQUIRY_UPDATED: 'INQUIRY_UPDATED',
    INQUIRY_ADDED: 'INQUIRY_ADDED',
    COMPLETED: 'COMPLETED',
  } as const,
})

export const { eventTypes: EventTypes, events: Events } = definition
export type EventTypes = ValueOfEventTypes<typeof definition>
