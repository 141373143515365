import { routesBuilder } from '@guiker/shared-framework'

import { StockAllowance } from '../../../../../entity'
import { paths } from '../../../paths'

const { buildGet } = routesBuilder<StockAllowance>()({
  basePath: paths.stocks.withId.allowance.path,
})

export const routes = {
  getAllowanceForStock: buildGet({}),
}
