import { pascalCase } from '@guiker/lodash'
import { PascalCase, ValueOf } from '@guiker/ts-utils'

import { BaseEventTypes } from './base-event-types'
import { EventType } from './event-type'
import { GeneratedEvents, generateEventsFromEnum } from './generate-events-from-enum'

type BaseEntity = { id: string }

type EventTypes<ET> = {
  [key in keyof ET]: ET[key] extends string
    ? ET[key]
    : ET[key] extends { type: string; eventData: unknown }
    ? ET[key]['type']
    : never
}

export type ValueOfEventTypes<T extends ReturnType<typeof deprecatedGenerateEventsDefinition>> =
  | ValueOf<typeof BaseEventTypes>
  | ValueOf<T['eventTypes']>

export type NamedReadWriteEventDefinition<
  Context extends string,
  EntityType extends string,
  Entity extends BaseEntity = BaseEntity,
  EventTypes extends Record<string, EventType> = Record<string, EventType>,
  WriteEntityType extends string = `${EntityType}_WRITE_MODEL`,
  WriteEntity extends BaseEntity = BaseEntity,
  WriteEventTypes extends Record<string, EventType> = EventTypes,
> = {
  [K in `${PascalCase<EntityType>}EventsDefinition`]: ReadWriteEventDefinition<
    Context,
    EntityType,
    Entity,
    EventTypes,
    WriteEntityType,
    WriteEntity,
    WriteEventTypes
  >
}

export type ReadWriteEventDefinition<
  Context extends string,
  EntityType extends string,
  Entity extends BaseEntity = BaseEntity,
  EventTypes extends Record<string, EventType> = Record<string, EventType>,
  WriteEntityType extends string = `${EntityType}_WRITE_MODEL`,
  WriteEntity extends BaseEntity = BaseEntity,
  WriteEventTypes extends Record<string, EventType> = EventTypes,
> = EventsDefinition<EventTypes, Context, EntityType, Entity> & {
  _write: EventsDefinition<WriteEventTypes, Context, WriteEntityType, WriteEntity>
}

export type EventsDefinition<ET extends Record<string, EventType>, C extends string, E extends string, D> = {
  context: C
  entity: E
  eventTypes?: EventTypes<ET>
  events: GeneratedEvents<typeof BaseEventTypes & ET, C, E, D>
}

export const deprecatedGenerateEventsDefinition = <
  ET extends Record<string, EventType>,
  C extends string,
  E extends string,
  D,
>({
  context,
  entity,
  eventTypes = {} as ET,
  data,
}: {
  context: C
  entity: E
  eventTypes?: ET
  data: D
}): EventsDefinition<typeof BaseEventTypes & ET, C, E, D> => {
  const allEventTypes = {
    ...BaseEventTypes,
    ...eventTypes,
  }

  const events = generateEventsFromEnum({ context, entity, data })(allEventTypes)

  return {
    context,
    entity,
    eventTypes: Object.entries(events).reduce(
      (acc, [type, event]) => ({
        ...acc,
        ...{
          [type]: event.type || type,
        },
      }),
      {},
    ) as EventTypes<typeof BaseEventTypes & ET>,
    events,
  }
}

export const generateEventsDefinition = <
  Context extends string,
  EntityType extends string,
  Entity extends BaseEntity,
  EventTypes extends Record<string, EventType> = {},
  WriteEntityType extends string = never,
  WriteEntity extends BaseEntity = never,
  WriteEventTypes extends Record<string, EventType> = never,
>({
  context,
  entity,
  data,
  eventTypes,
  write,
}: {
  context: Context
  entity: EntityType
  data: Entity
  eventTypes?: EventTypes
  write?: { entity?: WriteEntityType; data?: WriteEntity; eventTypes?: WriteEventTypes }
}) => {
  type _WriteEntityType = [WriteEntityType] extends [never] ? `${EntityType}_WRITE_MODEL` : WriteEntityType
  type _WriteEntity = [WriteEntity] extends [never] ? Entity : WriteEntity
  type _WriteEventTypes = [WriteEventTypes] extends [never] ? EventTypes : WriteEventTypes

  const writeEntity = (write?.entity || `${entity}_WRITE_MODEL`) as _WriteEntityType
  const writeData = (write?.data || data) as _WriteEntity
  const writeEventTypes = (write?.eventTypes || eventTypes) as _WriteEventTypes

  return {
    [`${pascalCase(entity)}EventsDefinition`]: {
      ...deprecatedGenerateEventsDefinition({
        context,
        entity,
        data,
        eventTypes,
      }),
      _write: deprecatedGenerateEventsDefinition({
        context,
        entity: writeEntity,
        data: writeData,
        eventTypes: writeEventTypes,
      }),
    },
  } as NamedReadWriteEventDefinition<
    Context,
    EntityType,
    Entity,
    typeof BaseEventTypes & EventTypes,
    _WriteEntityType,
    _WriteEntity,
    typeof BaseEventTypes & _WriteEventTypes
  >
}
