import React from 'react'

import { FinancialInfo } from '@guiker/base-entity'
import {
  InvestmentPerformanceIcon,
  Note,
  PageSection2,
  Slider,
  sliderNumberFormatter,
  TwoColumnsGridLayout,
  useGetInputPropsWithUseT,
} from '@guiker/react-framework'

import { useFinancialInfoContext, usePropertyContext } from '../../context'
import { useT } from '../../i18n'
import { KpiGrid } from '../KpiGrid'
import { buildKpiGrid } from './utils'

export const InvestmentPerformance: React.FC = () => {
  const { tMain, tShared } = useT({ screenName: 'screens.listingScreen.tab1.sections.investmentPerformance' })
  const { property } = usePropertyContext()
  const { rentalGrowthRateMinMax, exitRateMinMax } = useFinancialInfoContext()
  const readOnly = false

  const inputProps = useGetInputPropsWithUseT<{ financialInfo: FinancialInfo.FinancialInfo }>()({
    formPrefix: 'financialInfo.financials',
    t: tShared,
  })

  return (
    <PageSection2 title={tMain('header')}>
      <Note
        type='collapsible'
        title={tMain('note.title')}
        icon={<InvestmentPerformanceIcon />}
        statusColor='alabaster'
        isCollapsed={true}
        openAsModalOnMobile
      >
        {tMain('note.description')}
      </Note>
      <KpiGrid items={buildKpiGrid()} />
      <TwoColumnsGridLayout>
        <Slider
          {...inputProps('operation.revenues.rentalIncome.value.yearlyGrowthRate')}
          track={false}
          min={rentalGrowthRateMinMax.min}
          max={rentalGrowthRateMinMax.max}
          formatter={sliderNumberFormatter.percentage(2)}
          disabled={readOnly}
          step={0.0025}
          marks={[
            {
              value: property.financialInfo.financials.operation.revenues.rentalIncome.value.yearlyGrowthRate,
            },
          ]}
        />
        <Slider
          {...inputProps('exit.rate')}
          track={false}
          min={exitRateMinMax.min}
          max={exitRateMinMax.max}
          step={0.0025}
          formatter={sliderNumberFormatter.percentage(2)}
          marks={[
            {
              value: property.financialInfo.financials.exit.rate,
            },
          ]}
        />
      </TwoColumnsGridLayout>
    </PageSection2>
  )
}
