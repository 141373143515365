import { deprecatedGenerateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { BatchCharge } from '../../entity'
import { baseEvent } from '../base'

export const definition = deprecatedGenerateEventsDefinition({
  ...baseEvent,
  entity: 'BATCH_CHARGE',
  data: null as BatchCharge,
})

export const { eventTypes: EventTypes, events: Events } = definition
export type EventTypes = ValueOfEventTypes<typeof definition>
