import { FinancialInfo } from '@guiker/base-entity'

const defaultFlat = {
  value: {
    type: 'flat',
    amount: 0,
  },
} as const

export const getDefaultFinancials = (
  jurisdiction: FinancialInfo.CityJurisdiction,
  dealType: FinancialInfo.DealType = FinancialInfo.DealType.RESALE,
): FinancialInfo.Financials => {
  return {
    base: {
      yearlyGrowthRate: 0.03,
    },
    purchase: {
      assessedValue: defaultFlat,
      price: defaultFlat,
      ...FinancialInfo.buildPurchaseConfiguration({ dealType })[jurisdiction],
    },
    financing: {
      mortgage: {
        downPayment: {
          value: {
            type: 'percentage',
            rate: 0.05,
            base: 'purchasePrice',
          },
        },
        interestRate: 0.055,
        termsInYears: 25,
      },
    },
    operation: FinancialInfo.OperationConfiguration[jurisdiction],
    nonOperating: {
      expenses: {
        assetManagementFee: {
          value: {
            type: 'percentage',
            rate: 0.02,
            base: 'rentalIncome',
          },
        },
        propertyImprovementReserve: {
          value: {
            type: 'percentage',
            rate: 0.02,
            base: 'rentalIncome',
          },
        },
        cashReserve: {
          value: {
            type: 'percentage',
            rate: 0.02,
            base: 'rentalIncome',
          },
        },
      },
    },
    exit: {
      estimatedSalePriceCalculationMethod: FinancialInfo.EstimatedSalePriceCalculationMethod.CAP_RATE,
      rate: 0.05,
      holdingPeriod: 10,
    },
  }
}
