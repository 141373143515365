import { deprecatedGenerateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { Task } from '../entity'
import { baseEvent } from './base'

export const definition = deprecatedGenerateEventsDefinition({
  ...baseEvent,
  entity: 'TASK',
  data: null as Task,
  eventTypes: {
    CANCELLED: 'CANCELLED',
    COMPLETED: 'COMPLETED',
    ASSIGNEE_ADDED: 'ASSIGNEE_ADDED',
    ASSIGNEE_UNASSIGNED: 'ASSIGNEE_UNASSIGNED',
    RENT_PAYMENT_CONTRIBUTION_RESPLIT_REQUESTED: 'RENT_PAYMENT_CONTRIBUTION_RESPLIT_REQUESTED',
    RENT_PAYMENT_CONTRIBUTION_RESPLIT_COMPLETED: 'RENT_PAYMENT_CONTRIBUTION_RESPLIT_COMPLETED',
  } as const,
})

export const { eventTypes: EventTypes, events: Events } = definition
export type EventTypes = ValueOfEventTypes<typeof definition>
