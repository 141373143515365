import React from 'react'

import { FinancialInfo } from '@guiker/base-entity'
import { FormSection2, ThreeColumnsGridLayout } from '@guiker/react-framework'
import { NumberFormat, PercentNumberFormat } from '@guiker/rhf-components'

import { useT } from '../../i18n'
import { CalculationItemInput } from '../CalculationItem'

type FinancialFinancingFormContentProps = {}

export const FinancialFinancingFormContent: React.FC<FinancialFinancingFormContentProps> = () => {
  const { tShared } = useT({ entity: 'property.financialInfo.financials.financing.mortgage' })

  return (
    <>
      <CalculationItemInput
        name='financialInfo.financials.financing.mortgage.downPayment'
        allowedTypes={[FinancialInfo.CalculationMethodTypes.flat, FinancialInfo.CalculationMethodTypes.percentage]}
      />
      <ThreeColumnsGridLayout>
        <PercentNumberFormat
          name='financialInfo.financials.financing.mortgage.interestRate'
          label={tShared('interestRate')}
          condensed
        />
        <NumberFormat
          decimalScale={0}
          condensed
          name='financialInfo.financials.financing.mortgage.termsInYears'
          label={tShared('termsInYears')}
          min={0}
          max={100}
          step={1}
        />
      </ThreeColumnsGridLayout>
    </>
  )
}

export const FinancialFinancingFormSection: React.FC<FinancialFinancingFormContentProps> = () => {
  const { tComponents } = useT({ componentName: 'FinancialFinancingFormSection' })

  return (
    <FormSection2 title={tComponents('title')} subtitle={tComponents('description')}>
      <FinancialFinancingFormContent />
    </FormSection2>
  )
}
