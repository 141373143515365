import { yup } from '@guiker/yup-util'

export const searchListingQuerySchema = yup.object({
  sort: yup.string().nullable().default('results.irr'),
  sortOrder: yup.number().oneOf([1, -1]).nullable().default(-1),
  page: yup.number().min(1).default(1),
  perPage: yup.number().min(1).max(100).default(9),
})

export type SearchListingQuerySchema = yup.InferType<typeof searchListingQuerySchema>
