import { generateEventsDefinition } from '@guiker/event'
import { NEWUnit } from '@guiker/real-estate-shared'

import { Manager, PropRental, PropRentalWriteModel } from '../../entity'
import { baseEvent } from '../base'

export const { PropRentalEventsDefinition } = generateEventsDefinition({
  ...baseEvent,
  entity: 'PROP_RENTAL',
  data: null as unknown as PropRental,
  eventTypes: {
    DEACTIVATED: 'DEACTIVATED',
    PAYOUT_METHOD_UPDATED: {
      type: 'PAYOUT_METHOD_UPDATED',
      eventData: null as {
        payoutMethodId: string
        receiver: {
          userId: string
          firstName: string
          lastName: string
          emailAddress: string
        }
      },
    },
    MERGE_PROP_RENTAL_DELETED: {
      type: 'MERGE_PROPERTY_RENTAL_DELETED',
      eventData: null as {
        mainBuildingId: string
        upsertedUnit: NEWUnit
        upsertedPropertyId: string
        upsertedPropertyOwnershipId: string
      },
    },
    OWNERSHIP_TRANSFERRED: {
      type: 'OWNERSHIP_TRANSFERRED',
      eventData: null as {
        from: {
          propertyOwnershipId: string
          propRentalId: string
        }
      },
    },
    MANAGER_ADDED: {
      type: 'MANAGER_ADDED',
      eventData: null as {
        manager: Manager
        inviter: string
      },
    },
    MANAGER_UPDATED: {
      type: 'MANAGER_UPDATED',
      eventData: null as {
        manager: Manager
      },
    },
    MANAGER_REMOVED: {
      type: 'MANAGER_REMOVED',
      eventData: null as {
        manager: Manager
      },
    },
    UPDATE_TO_REAL_OWNER: {
      type: 'UPDATE_TO_REAL_OWNER',
      eventData: null as {
        newOwner: {
          userId: string
          firstName: string
          lastName: string
          emailAddress: string
        }
      },
    },
  } as const,
  write: {
    data: null as unknown as PropRentalWriteModel,
  },
})
