import { EntityDocument, FinancialInfo } from '@guiker/base-entity'

import { Building, NEWBuilding, WithBuilding } from './building'
import { Document } from './document'
import { Picture } from './picture'
import { NEWUnit, Unit } from './unit'

export enum CreationStatus {
  DRAFTED = 'DRAFTED',
  COMPLETED = 'COMPLETED',
}

export type Property = EntityDocument & {
  status?: {
    creationStatus: CreationStatus
    isPublished: boolean
  }
  building?: Building
  units?: Unit[]
  totalSquareFootage?: number
  repartitionOfUnits?: {
    commercial: number
    residential: number
  }
  hasDetailedUnitInformation?: boolean
} // deprecate

/////////
export enum PropertyType {
  BUILDING = 'BUILDING',
  UNIT = 'UNIT',
}

export type NEWProperty = {
  id: string
  type: PropertyType
  building: NEWBuilding
  deal?: FinancialInfo.Deal
  description: string
  documents: Document[]
  financialInfo?: FinancialInfo.FinancialInfo
  meta?: {
    legacyIds: number[]
  }
  pictures: Picture[]
  units?: NEWUnit[]
  createdAt?: string
  updatedAt?: string
}

export type WithPropertyHasBuilding<T> = T & {
  property: WithBuilding<NEWProperty>
}
