import { buildWebappRoutes } from '@guiker/base-listing-shared'
import { slugifyListingProperty } from '@guiker/real-estate-shared'
import { DeepPartial } from '@guiker/ts-utils'

import { config } from '../config'
import { PropSharingListing } from '../entity'

export const webappRoutes = {
  findOrCreateInvestorProfile: {
    path: `/${config.domainName}/find-investor-profile`,
  },
  investorProfile: {
    path: `/${config.domainName}/investor-profile`,
    onboard: `/${config.domainName}/investor-profile/onboard`,
    requestTIN: `/${config.domainName}/investor-profile/request-tin`,
    byId: function (profileId: string) {
      return {
        path: `${this.path}s/${profileId}`,
        get review() {
          return {
            path: `${this.path}/review`,
          }
        },
      }
    },
  },
  stockOrder: {
    path: `/${config.domainName}/stock-order`,
    byId: function (stockOrderId: string) {
      return {
        path: `${this.path}/${stockOrderId}`,
        get checkout() {
          return {
            path: `${this.path}/checkout`,
          }
        },
        get claimPromotion() {
          return {
            path: `${this.path}/claim-promotion`,
          }
        },
        get confirmRisk() {
          return {
            path: `${this.path}/confirm-risk`,
          }
        },
      }
    },
  },
  inquiry: {
    path: `/${config.domainName}/inquiry`,
  },
  ...buildWebappRoutes({ rootPath: `/${config.domainName}/listings` }),
}

export const slugifyListing = (listing: DeepPartial<PropSharingListing>) => {
  const { id: objectId, stock } = listing
  const slug = slugifyListingProperty(stock.property)
  return `${stock?.tickerSymbol ?? objectId}/${slug}`
}

export const buildNestedListingRoutes = (listing: PropSharingListing) => {
  const address = listing.stock.property.building.mailingAddress
  const countryNested = webappRoutes.listings.countryNested(address.country)
  const cityNested = countryNested.cityNested(address.city, address.state)
  const neighbourhoodNested = cityNested.neighbourhoodNested(address.neighbourhood)
  const listingPath = neighbourhoodNested.withId(slugifyListing(listing) ?? listing.id)

  return {
    countryNested: countryNested.path,
    cityNested: cityNested.path,
    neighbourhoodNested: neighbourhoodNested.path,
    listing: listingPath.path,
  }
}
