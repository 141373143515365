import React from 'react'

import { Flex, Note, PageSection2, PageSection3, ProcessCostIcon } from '@guiker/react-framework'

import { useFinancialInfoContext } from '../../context'
import { useT } from '../../i18n'
import { FinancialBreakdown } from '../FinancialBreakdown'
import { KpiGrid } from '../KpiGrid'
import { buildKpiGrid, buildPurchaseBreakdown } from './utils'

const PurchaseBreakdown: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.listingScreen.tab1.sections.offeringDetails' })
  const { results, currency } = useFinancialInfoContext()

  return (
    <PageSection3 title={tMain('breakdown.title')}>
      <Flex flexDirection='column' width='100%' maxWidth={700}>
        <FinancialBreakdown
          currency={currency}
          items={buildPurchaseBreakdown()}
          total={{
            label: tMain('kpi.totalPurchaseCost'),
            value: results.totalProjectCost,
          }}
        />
      </Flex>
    </PageSection3>
  )
}

export const OfferingDetails: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.listingScreen' })

  return (
    <PageSection2 title={tMain(`tab1.sections.offeringDetails.title`)}>
      <Note
        type='collapsible'
        title={tMain(`tab1.sections.offeringDetails.note.title`)}
        icon={<ProcessCostIcon />}
        statusColor='alabaster'
        isCollapsed={true}
      >
        {tMain(`tab1.sections.offeringDetails.note.description`)}
      </Note>
      <KpiGrid items={buildKpiGrid()} />
      <PurchaseBreakdown />
    </PageSection2>
  )
}
