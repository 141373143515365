import { deprecatedGenerateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { PropertySaleListing } from '../../entity'
import { baseEvent } from '../base'

const basePropertySaleListingEvent = {
  ...baseEvent,
  entity: 'PROPERTY_SALE_LISTING',
  data: null as unknown as PropertySaleListing,
}

export enum PropertySaleListingEventTypes {
  DISPLAY_INFORMATION_UPDATED = 'DISPLAY_INFORMATION_UPDATED',
  PROPERTY_UPDATED = 'PROPERTY_UPDATED',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  PROPERTY_SALE_ADDED = 'PROPERTY_SALE_ADDED',
}

export const PropertySaleListingEventsDefinition = deprecatedGenerateEventsDefinition({
  ...basePropertySaleListingEvent,
  eventTypes: PropertySaleListingEventTypes,
})

export const PropertySaleListingEvents =
  generateEventsFromEnum(basePropertySaleListingEvent)(PropertySaleListingEventTypes)
