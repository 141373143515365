import * as Ontario from './ontario'
import * as Quebec from './quebec'

export type CanadaJurisdiction = Jurisdiction | Ontario.OntarioJurisdiction | Quebec.QuebecJurisdiction
export type Jurisdiction = 'canada'

export * from './operation-cost'
export * from './purchase-base'
export * from './purchase-cost'
export * from './purchase-taxes'

export { Ontario, Quebec }
