import { PropSharingListing } from '@guiker/propsharing-shared'
import { ColumnConfig, useDateFormatter, useT } from '@guiker/react-framework'
import { addressFormatter } from '@guiker/shared-framework'

export const useGetListingColumns = (): ColumnConfig<PropSharingListing>[] => {
  const { tShared } = useT({ domain: 'propsharing', entity: 'listing' })
  const { formatDate } = useDateFormatter()

  return [
    {
      label: tShared('building.address'),
      name: 'building.address',
      size: 4,
      renderValue: ({ stock: { property } }) =>
        property?.building?.mailingAddress &&
        addressFormatter.printShortAddress({
          ...property.building.mailingAddress,
          suite: property.units && property.units[0]?.suite,
          streetNumber: property.building.mailingAddress.streetNumber,
        }),
    },
    {
      label: tShared('createdAt'),
      name: 'createdAt',
      size: 1,
      renderValue: ({ createdAt }) => formatDate(createdAt),
    },
  ]
}
