import { CostArgs } from '../../../../utils'
import { OperationRevenues } from '../../../operation-revenues'
import { OperationCost } from './operation-cost'
import { OperationTaxes } from './operation-taxes'
import { PurchaseCost } from './purchase-cost'
import { PurchaseTaxes } from './purchase-taxes'

export type Jurisdiction = 'chicago-il'

export const Chicago = {
  purchase: (_: CostArgs) => ({
    costs: PurchaseCost,
    taxes: PurchaseTaxes,
  }),
  operation: {
    revenues: OperationRevenues,
    expenses: {
      costs: OperationCost,
      taxes: OperationTaxes,
    },
  },
} as const
