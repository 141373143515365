import { camelCase } from 'lodash'

import { parseJwtToken } from '@guiker/base-jwt'
import { HttpMethod, StatusCodes } from '@guiker/http'
import { Paginate } from '@guiker/paginate'
import { TokenizePath } from '@guiker/ts-utils'
import { yup } from '@guiker/yup-util'

import { DataAndMeta } from './metadata'
import { AccessControlType } from './route-definition'
import { baseParamsSchema, validatorBuilder } from './validator-builder'

type BaseClaims = Record<string, unknown>

type BaseQueryParams = Record<string, string | boolean | number | string[]>

type RouteArg<
  Response,
  Path extends string,
  Method extends HttpMethod,
  Payload extends object,
  QueryParams extends BaseQueryParams,
  Paginated extends boolean,
  Claims extends BaseClaims,
  ResponseArray extends boolean,
  AdditionalMeta extends object,
  ActionsPermissions extends string[] = string[],
> = {
  path?: Path
  method: Method
  paginated?: Paginated
  response?: Response
  additionalMeta?: AdditionalMeta
  payloadSchema?: yup.ObjectSchema<Payload>
  queryParamsSchema?: yup.ObjectSchema<QueryParams>
  accessControlSchema?: yup.ObjectSchema<Claims>
  responseIsArray?: ResponseArray
  successStatusCode?: StatusCodes
  actionPermissions?: ActionsPermissions
}

const extractParam = (path: string) =>
  path
    ? path
        .split('/')
        .filter((p) => p.startsWith(':'))
        .map((p) => camelCase(p.replace(':', '')))
    : []

export const routesBuilder =
  <Response, WriteModelResponse = Response>() =>
  <BasePath extends string>({ basePath }: { basePath: BasePath }) => {
    const buildRoute = <
      SpecificResponse,
      Path extends string,
      Method extends HttpMethod,
      Payload extends object,
      QueryParams extends BaseQueryParams,
      Paginated extends boolean,
      Claims extends BaseClaims,
      ResponseArray extends boolean,
      AdditionalMeta extends object,
      ActionsPermissions extends string[] = string[],
    >({
      accessControlSchema,
      actionPermissions,
      method,
      paginated,
      path,
      payloadSchema,
      queryParamsSchema,
      successStatusCode,
    }: RouteArg<
      SpecificResponse,
      Path,
      Method,
      Payload,
      QueryParams,
      Paginated,
      Claims,
      ResponseArray,
      AdditionalMeta,
      ActionsPermissions
    >) => {
      type FullPath = Path extends undefined ? BasePath : `${BasePath}${Path}`
      type PathParams = TokenizePath<FullPath>
      type ResponseType = ResponseArray extends true
        ? SpecificResponse[]
        : Paginated extends true
        ? DataAndMeta<SpecificResponse[], AdditionalMeta>
        : SpecificResponse

      type QueryParamsType = Paginated extends true
        ? QueryParams extends undefined
          ? Paginate
          : QueryParams & Paginate
        : QueryParams extends undefined
        ? never
        : QueryParams

      const fullPath = (path ? `${basePath}${path}` : basePath) as FullPath
      const pathParams = extractParam(fullPath) as PathParams[]

      const responseValidator = (
        paginated
          ? validatorBuilder.buildPaginatedResponseValidator<SpecificResponse, AdditionalMeta>()
          : validatorBuilder.buildResponseValidator<
              ResponseArray extends true ? SpecificResponse[] : SpecificResponse
            >()
      ) as (payload?: unknown) => Promise<ResponseType>

      const queryParamsValidator = (paginated
        ? validatorBuilder.buildPaginationQueryParamValidator(queryParamsSchema)
        : queryParamsSchema
        ? validatorBuilder.buildParamsValidator(queryParamsSchema)
        : null) as unknown as (params?: unknown) => Promise<QueryParamsType>

      const accessControlValidator = async (
        token: string,
      ): Promise<{
        token: string
        claims?: Claims
      }> => {
        const claims = parseJwtToken(token as string)
        return { token, claims: await accessControlSchema.validate(claims) }
      }

      return {
        path: fullPath,
        method,
        successStatusCode,
        actionPermissions,
        responseValidator,
        queryParamsValidator,
        payloadValidator: (payloadSchema
          ? validatorBuilder.buildPayloadValidator(payloadSchema)
          : null) as Payload extends never
          ? (params: unknown) => Promise<never>
          : (params: unknown) => Promise<Payload>,
        pathParamsValidator: (pathParams
          ? validatorBuilder.buildParamsValidator(baseParamsSchema(pathParams))
          : null) as PathParams extends never
          ? (params: unknown) => Promise<never>
          : (params: unknown) => Promise<{ [key in PathParams]: string }>,
        accessControlValidator: (accessControlSchema ? accessControlValidator : null) as Claims extends never
          ? (params: unknown) => Promise<never>
          : (params: unknown) => Promise<AccessControlType<Claims>>,
      }
    }

    return {
      buildGet: <
        SpecificResponse = Response,
        Path extends string = undefined,
        Payload extends object = never,
        QueryParams extends BaseQueryParams = undefined,
        Claims extends BaseClaims = never,
        Paginated extends boolean = false,
        ResponseArray extends boolean = false,
        AdditionalMeta extends object = {},
        ActionsPermissions extends string[] = string[],
      >(
        route: Omit<
          RouteArg<
            SpecificResponse,
            Path,
            HttpMethod.GET,
            Payload,
            QueryParams,
            Paginated,
            Claims,
            ResponseArray,
            AdditionalMeta,
            ActionsPermissions
          >,
          'method'
        > = {},
      ) => {
        return buildRoute({ method: HttpMethod.GET, ...route })
      },
      buildPost: <
        SpecificResponse = WriteModelResponse,
        Path extends string = undefined,
        Payload extends object = never,
        QueryParams extends BaseQueryParams = undefined,
        Claims extends BaseClaims = never,
        Paginated extends boolean = false,
        ResponseArray extends boolean = false,
        AdditionalMeta extends object = {},
        ActionsPermissions extends string[] = string[],
      >(
        route: Omit<
          RouteArg<
            SpecificResponse,
            Path,
            HttpMethod.POST,
            Payload,
            QueryParams,
            Paginated,
            Claims,
            ResponseArray,
            AdditionalMeta,
            ActionsPermissions
          >,
          'method'
        > = {},
      ) => {
        return buildRoute({ method: HttpMethod.POST, ...route })
      },
      buildPut: <
        SpecificResponse = WriteModelResponse,
        Path extends string = undefined,
        Payload extends object = never,
        QueryParams extends BaseQueryParams = undefined,
        Claims extends BaseClaims = never,
        Paginated extends boolean = false,
        ResponseArray extends boolean = false,
        AdditionalMeta extends object = {},
        ActionsPermissions extends string[] = string[],
      >(
        route: Omit<
          RouteArg<
            SpecificResponse,
            Path,
            HttpMethod.PUT,
            Payload,
            QueryParams,
            Paginated,
            Claims,
            ResponseArray,
            AdditionalMeta,
            ActionsPermissions
          >,
          'method'
        > = {},
      ) => {
        return buildRoute({ method: HttpMethod.PUT, ...route })
      },
      buildPatch: <
        SpecificResponse = WriteModelResponse,
        Path extends string = undefined,
        Payload extends object = never,
        QueryParams extends BaseQueryParams = undefined,
        Claims extends BaseClaims = never,
        Paginated extends boolean = false,
        ResponseArray extends boolean = false,
        AdditionalMeta extends object = {},
        ActionsPermissions extends string[] = string[],
      >(
        route: Omit<
          RouteArg<
            SpecificResponse,
            Path,
            HttpMethod.PATCH,
            Payload,
            QueryParams,
            Paginated,
            Claims,
            ResponseArray,
            AdditionalMeta,
            ActionsPermissions
          >,
          'method'
        > = {},
      ) => {
        return buildRoute({ method: HttpMethod.PATCH, ...route })
      },
      buildDelete: <
        SpecificResponse = WriteModelResponse,
        Path extends string = undefined,
        Payload extends object = never,
        QueryParams extends BaseQueryParams = undefined,
        Claims extends BaseClaims = never,
        Paginated extends boolean = false,
        ResponseArray extends boolean = false,
        AdditionalMeta extends object = {},
        ActionsPermissions extends string[] = string[],
      >(
        route: Omit<
          RouteArg<
            SpecificResponse,
            Path,
            HttpMethod.DELETE,
            Payload,
            QueryParams,
            Paginated,
            Claims,
            ResponseArray,
            AdditionalMeta,
            ActionsPermissions
          >,
          'method'
        > = {},
      ) => {
        return buildRoute({ method: HttpMethod.DELETE, ...route })
      },
    }
  }
