import { generateEventsDefinition } from '@guiker/shared-framework'

import { FinancialStatements, FinancialStatementsWriteModel } from '../../entity'
import { baseEvent } from '../base'

export const { FinancialStatementsEventsDefinition } = generateEventsDefinition({
  ...baseEvent,
  entity: 'FINANCIAL_STATEMENTS',
  data: null as unknown as FinancialStatements,
  write: {
    data: null as unknown as FinancialStatementsWriteModel,
  },
})
