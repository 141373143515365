import { deprecatedGenerateEventsDefinition } from '@guiker/event'

import { NEWBuilding, NEWProperty } from '../../entity'
import { baseEvent } from '../base'

type SyncedEventData = {
  legacyId: number
  property: NEWProperty
}

const baseBuildingEvent = {
  ...baseEvent,
  entity: 'BUILDING',
  data: null as unknown as NEWBuilding,
}

export const BuildingEventsDefinition = deprecatedGenerateEventsDefinition({
  ...baseBuildingEvent,
  eventTypes: {
    MERGE_BUILDING_DELETED: {
      type: 'MERGE_BUILDING_DELETED',
      eventData: null as {
        mainBuildingId: string
      },
    },
    META_LEGACY_IDS_UPDATED: 'META_LEGACY_IDS_UPDATED',
    SYNCED: {
      type: 'SYNCED',
      eventData: null as SyncedEventData,
    },
    TRIGGER_SYNC_QUEUE: {
      type: 'TRIGGER_SYNC_QUEUE',
      eventData: null as {
        lastQueried?: number
      },
    },
  } as const,
})

export const Events = BuildingEventsDefinition.events
export const EventTypes = BuildingEventsDefinition.eventTypes
export type BuildingEventTypes = typeof EventTypes
