import { generateEventsDefinition } from '@guiker/shared-framework'

import { Series, Stock, StockWriteModel } from '../../entity'
import { baseEvent } from '../base'

export const { StockEventsDefinition } = generateEventsDefinition({
  ...baseEvent,
  entity: 'STOCK',
  data: null as unknown as Stock,
  eventTypes: {
    DESCRIPTION_UPDATED: 'DESCRIPTION_UPDATED',
    FINANCIAL_INFO_OCCUPANCIES_UPDATED: 'FINANCIAL_INFO_OCCUPANCIES_UPDATED',
    SERIES_UPDATED: 'SERIES_UPDATED',
    STOCK_CREATION_COMPLETED: 'STOCK_CREATION_COMPLETED',
    SERIES_CLOSED: {
      type: 'SERIES_CLOSED',
      eventData: null as { series: { slug: string }[] },
    },
    SERIES_CANCELLED: {
      type: 'SERIES_CANCELLED',
      eventData: null as { series: { slug: string }[] },
    },
    SERIES_CLOSING_EQUITY_DATA_UPDATED: {
      type: 'SERIES_CLOSING_EQUITY_DATA_UPDATED',
      eventData: null as { series: Series[] },
    },
    SERIES_CLOSE_INITIATED: {
      type: 'SERIES_CLOSE_INITIATED',
      eventData: null as { series: { slug: string }[] },
    },
    SERIES_ADDED: {
      type: 'SERIES_ADDED',
      eventData: null as { series: Series[] },
    },
    LIQUIDATION_DELISTED: {
      type: 'LIQUIDATION_DELISTED',
      eventData: null as { lastProcessedUserId?: string },
    },
  },
  write: {
    data: null as unknown as StockWriteModel,
  },
})
