import { Path, useGetInputPropsWithUseT } from '@guiker/react-framework'
import { NEWProperty } from '@guiker/real-estate-shared'

type WithProperty = { property: NEWProperty }

export const useGetInputPropsProperty = <FormPrefix extends Path<WithProperty>>({
  t,
  formPrefix,
}: {
  t
  formPrefix: FormPrefix
}) =>
  useGetInputPropsWithUseT<WithProperty>()({
    t,
    formPrefix,
  })
