import { routesBuilder } from '@guiker/shared-framework'

import { FinancialStatements, FinancialStatementsWriteModel } from '../../../entity'
import { paths } from '../paths'

const { buildGet } = routesBuilder<FinancialStatements, FinancialStatementsWriteModel>()({
  basePath: paths.stocks.withId.financialStatements.path,
})

export const routes = {
  readLatestFinancialStatementsByStockId: buildGet({}),
}
