import React from 'react'

import { FinancialInfo } from '@guiker/base-entity'
import { Flex, PBig, PSmall, PSmaller } from '@guiker/react-framework'
import { NEWUnit, unitOccupancyHelper, UnitType } from '@guiker/real-estate-shared'
import { CurrencyISO, money } from '@guiker/shared-framework'

import { useT } from '../../../i18n'
import { printRoomConfiguration } from '../../../utils'
import { RentPeriod } from './RentPeriod'

type UnitConfigurationProp = {
  unit: NEWUnit
  occupancies: FinancialInfo.Occupancy[]
  className?: string
}

export const UnitConfiguration: React.FC<UnitConfigurationProp> = ({ occupancies, unit, className }) => {
  const { tShared, t } = useT({ entity: 'unit' })
  const { isOccupied, period } = unitOccupancyHelper(unit, occupancies)

  return (
    <Flex flexDirection='column' alignItems='flex-start' gap={0.5} className={className}>
      <Flex gap={2} alignItems='center'>
        <PBig mb={0} fontWeight={500}>
          {tShared(`revenue.occupancy.leaseTerm.${isOccupied ? 'rented' : 'vacant'}`)}
        </PBig>
        {isOccupied && <RentPeriod period={period} />}
      </Flex>
      <PSmall mb={0} color={60}>
        {unit.type === UnitType.COMMERCIAL ? tShared(`type.options.${unit.type}`) : printRoomConfiguration({ unit, t })}
      </PSmall>
    </Flex>
  )
}

type RentalInfoProp = {
  unit: NEWUnit
  occupancies: FinancialInfo.Occupancy[]
  currency: CurrencyISO
}

export const RentalInfo: React.FC<RentalInfoProp> = ({ unit, occupancies, currency }) => {
  const { isOccupied, monthlyRent } = unitOccupancyHelper(unit, occupancies)

  const { SharedTrans, tShared } = useT({
    entity: 'revenue.occupancy',
  })

  return (
    <Flex flexDirection='column' alignItems='flex-end'>
      <Flex gap={1} alignItems='flex-end'>
        <SharedTrans
          i18nKey='monthlyRent.value'
          values={{ price: money.fromAmount(monthlyRent, currency).toString('onlySymbol', true) }}
        >
          <PBig textAlign='right' mb={0} fontWeight={500}></PBig>
        </SharedTrans>
      </Flex>
      {!isOccupied && (
        <PSmaller mb={0} textAlign='right'>
          {tShared('monthlyRent.estimated')}
        </PSmaller>
      )}
    </Flex>
  )
}
