import { routesBuilder } from '@guiker/shared-framework'

import {
  FundingAccount,
  FundingAccountDeposit,
  FundingAccountDepositWriteModel,
  FundingAccountWithdrawal,
  FundingAccountWithdrawalWriteModel,
} from '../../../entity'
import { paths } from '../paths'
import { createDepositSchema, createPayoutWithdrawalSchema } from './schemas'

const FUNDING_ACCOUNT_PATH = paths.fundingAccount.path
const FUNDING_ACCOUNT_ID_PATH = paths.fundingAccount.deposits.withId.path
const FUNDING_ACCOUNT_DEPOSIT_PATH = paths.fundingAccount.withId.deposits.path
const FUNDING_ACCOUNT_WITHDRAWAL_PATH = paths.fundingAccount.withId.withdrawals.path

const builder = {
  fundingAccount: {
    base: routesBuilder<FundingAccount>()({ basePath: FUNDING_ACCOUNT_PATH }),
    idNested: routesBuilder<FundingAccount>()({ basePath: FUNDING_ACCOUNT_ID_PATH }),
  },
  fundingAccountWithdrawal: routesBuilder<FundingAccountWithdrawal, FundingAccountWithdrawalWriteModel>()({
    basePath: FUNDING_ACCOUNT_WITHDRAWAL_PATH,
  }),
  deposit: routesBuilder<FundingAccountDeposit, FundingAccountDepositWriteModel>()({
    basePath: FUNDING_ACCOUNT_DEPOSIT_PATH,
  }),
}

export const fundingAccountDepositRoutes = {
  depositFundingAccount: builder.deposit.buildPost({
    payloadSchema: createDepositSchema,
  }),
  readAllDeposits: builder.deposit.buildGet({
    response: null as FundingAccountDeposit,
    responseIsArray: true,
  }),
}

export const fundingAccountWithdrawalRoutes = {
  createPayoutWithdrawal: builder.fundingAccountWithdrawal.buildPost({
    payloadSchema: createPayoutWithdrawalSchema,
  }),
  readAllWithdrawals: builder.fundingAccountWithdrawal.buildGet({
    responseIsArray: true,
  }),
}

export const fundingAccountRoutes = {
  ...fundingAccountDepositRoutes,
  ...fundingAccountWithdrawalRoutes,
  readOneFundingAccountByUser: builder.fundingAccount.base.buildGet({}),
}
