import { routesBuilder } from '@guiker/shared-framework'

import { PropSharingListing } from '../../../../../entity'
import { paths } from '../../../paths'

const { buildGet } = routesBuilder<PropSharingListing>()({
  basePath: paths.stocks.withId.listings.path,
})

export const routes = {
  readAllListingsByStock: buildGet({
    paginated: true,
  }),
}
