import React, { useMemo } from 'react'

import {
  Collapse,
  Dropdown,
  FormSection2,
  FormSection3,
  TextField,
  TwoColumnsGridLayout,
  useGetInputPropsWithUseT,
  useQuery,
} from '@guiker/react-framework'
import { useAdminRealEstateContext } from '@guiker/real-estate-context'
import { BuildingTypes, NEWProperty, Orientation } from '@guiker/real-estate-shared'

import { useT } from '../../i18n'
import { useWatchProperty } from '../../utils'

type PropertyBuildingDetailsFormContentProps = {}

const maxConstructionYear = new Date()
maxConstructionYear.setFullYear(new Date().getFullYear() + 10)
const minConstructionYear = new Date()
minConstructionYear.setFullYear(1500)

export const PropertyBuildingDetailsFormContent: React.FC<PropertyBuildingDetailsFormContentProps> = () => {
  const { tShared, tComponents } = useT({ entity: 'building', componentName: 'PropertyBuildingDetailsFormContent' })
  const { tShared: tSharedOrientation } = useT({ entity: 'orientation' })
  const { apiClient: realEstateApiClient } = useAdminRealEstateContext()
  const { data: developers } = useQuery('readAllDevelopers', () =>
    realEstateApiClient.readAllDevelopers({ queryParams: {} }).then((response) => response.data),
  )

  const getInputProps = useGetInputPropsWithUseT<{ property: NEWProperty }>()({
    t: tShared,
    formPrefix: 'property.building',
  })

  const developerId = useWatchProperty({
    name: getInputProps('construction.developer.id').name,
  })

  const developer = useMemo(
    () => developers?.find((developer) => developer.id === developerId),
    [developers, developerId],
  )

  return (
    <FormSection2>
      <TwoColumnsGridLayout>
        <Dropdown
          {...getInputProps('type', { i18nKey: 'type.label' })}
          options={Object.keys(BuildingTypes).map((type) => ({
            value: BuildingTypes[type],
            label: tShared(`type.options.${type}`),
          }))}
        />
        <TextField {...getInputProps('construction.year')} type='number' />
        <TextField {...getInputProps('configuration.totalStoreys')} type='number' />
        <Dropdown
          {...getInputProps('configuration.orientation')}
          label={tSharedOrientation('label')}
          options={Object.keys(Orientation).map((direction) => ({
            value: Orientation[direction],
            label: tSharedOrientation(`options.${direction}`),
          }))}
        />
        <TextField {...getInputProps('configuration.totalSquareFootage')} type='number' />
        <TextField {...getInputProps('configuration.frontageFootage')} type='number' />
      </TwoColumnsGridLayout>
      <Collapse in={!!developers} unmountOnExit>
        <FormSection3 title={tComponents('developer.title')}>
          <TwoColumnsGridLayout>
            <Dropdown
              {...getInputProps('construction.developer.id')}
              options={[
                ...(developers?.map((developer) => ({
                  value: developer.id,
                  label: developer.name,
                })) ?? []),
                {
                  value: null,
                  label: tShared('construction.noProjectId'),
                },
              ]}
            />
            <Dropdown
              {...getInputProps('construction.project.id')}
              options={[
                ...(developer?.projects?.map((project) => ({
                  value: project.id,
                  label: project.name,
                })) ?? []),
                {
                  value: null,
                  label: tShared('construction.noProjectId'),
                },
              ]}
            />
          </TwoColumnsGridLayout>
        </FormSection3>
      </Collapse>
    </FormSection2>
  )
}
