import { deprecatedGenerateEventsDefinition } from '@guiker/event'

import { Property } from '../../entity'
import { baseEvent } from '../base'

export const PropertyEventsDefinition = deprecatedGenerateEventsDefinition({
  ...baseEvent,
  entity: 'PROPERTY',
  data: null as unknown as Property,
  eventTypes: {
    CREATION_DRAFTED: 'CREATION_DRAFTED',
    CREATION_COMPLETED: 'CREATION_COMPLETED',
    PUBLISHED: 'PUBLISHED',
    UNPUBLISHED: 'UNPUBLISHED',
    BUILDING_UPDATED: 'BUILDING_UPDATED',
  } as const,
})
export const PropertyEvents = PropertyEventsDefinition.events
export const PropertyEventTypes = PropertyEventsDefinition.eventTypes
export type PropertyEventTypes = typeof PropertyEventTypes
