import React from 'react'

import { FinancialInfo } from '@guiker/base-entity'
import { useGetInputPropsWithUseT, useWatch } from '@guiker/react-framework'
import {
  CurrencyTextField,
  FormSection3,
  FormSection4,
  Inline,
  NumberFormat,
  numberFormatter,
  Spinner,
  TwoColumnsGridLayout,
} from '@guiker/react-framework'

import { useFinancialInfoContext } from '../../context'
import { useT } from '../../i18n'

const InvestmentAssumptionsMobile: React.FC = () => {
  const { tShared, tMain, tBase } = useT()
  const { results, currency } = useFinancialInfoContext()
  const financialInfo = useWatch({ name: 'financialInfo' })

  const inputProps = useGetInputPropsWithUseT<{ financialInfo: FinancialInfo.FinancialInfo }>()({
    t: tShared,
    formPrefix: 'financialInfo',
  })

  if (!financialInfo?.financials || !results) {
    return <Spinner />
  }

  return (
    <FormSection3>
      <FormSection4 title={tMain('screens.listingScreen.tab1.sections.financials.rentalIncome')}>
        <TwoColumnsGridLayout>
          <CurrencyTextField
            {...inputProps('financials.operation.revenues.rentalIncome.value')}
            currency={currency}
            allowNegative={false}
          />
          <NumberFormat
            {...inputProps('financials.operation.revenues.rentalIncome.value.yearlyGrowthRate')}
            endAdornment={<Inline>%</Inline>}
            decimalScale={2}
            max={100}
            allowNegative={false}
            formatter={numberFormatter.percentage(2)}
          />
        </TwoColumnsGridLayout>
      </FormSection4>
      <FormSection4 title={tMain('screens.listingScreen.tab1.sections.financials.propertyValueGrowth')}>
        <TwoColumnsGridLayout>
          <NumberFormat
            {...inputProps('financials.exit.holdingPeriod')}
            allowNegative={false}
            endAdornment={<Inline>{tBase('common:duration.year')}</Inline>}
          />
          <NumberFormat
            {...inputProps('financials.exit.rate')}
            endAdornment={<Inline>%</Inline>}
            decimalScale={2}
            max={100}
            allowNegative={false}
            formatter={numberFormatter.percentage(2)}
          />
        </TwoColumnsGridLayout>
      </FormSection4>
    </FormSection3>
  )
}

export { InvestmentAssumptionsMobile }
