import { enumLike, yup } from '@guiker/shared-framework'

import { NEWUnit, Orientation, UnitType } from '../../../entity'
import { amenitiesSchema, floorplanSchema, parkingsSchema } from './units-schema'

const unitSchema = yup
  .object<
    Pick<NEWUnit, 'type' | 'squareFootage' | 'floorplan' | 'amenities' | 'parkings' | 'floorLevel' | 'orientation'>
  >({
    type: enumLike(UnitType).required(),
    squareFootage: yup.number().required(),
    floorplan: floorplanSchema.required(),
    amenities: amenitiesSchema.required(),
    parkings: parkingsSchema.required(),
    floorLevel: yup.number().nullable(),
    orientation: enumLike(Orientation).required(),
  })
  .concat(
    yup.object<{
      suite?: string
      streetNumber?: string
    }>({ suite: yup.string().nullable(), streetNumber: yup.string().nullable() }),
  )

export const createUnitsSchema = yup.object({
  units: yup.array().of(unitSchema).min(1).required(),
  buildingId: yup.string().required(),
  propertyId: yup.string().required(),
})
