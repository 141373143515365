import { AdminApi } from '@guiker/real-estate-shared'
import { TypeOf, yup } from '@guiker/shared-framework'

export const updatePropertyBuilding = yup.object({
  property: yup.object({
    building: AdminApi.Schema.upsertBuildingSchema,
  }),
})

export type UpdatePropertyBuilding = TypeOf<typeof updatePropertyBuilding>
