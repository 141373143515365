import { Geocoordinates, geocoordinatesSchema, yup } from '@guiker/shared-framework'

import { NEWBuilding, PropertyType } from '../../../entity'

export const upsertBuildingSchema = yup.object<{
  externalId: string
  geocoordinates: Geocoordinates
  mailingAddress: NEWBuilding['mailingAddress'] & { operatingCity?: string }
  addresses: NEWBuilding['addresses']
}>({
  externalId: yup.string().required(),
  geocoordinates: geocoordinatesSchema.required(),
  mailingAddress: yup.object({
    neighbourhood: yup.string().required(),
    streetNumber: yup.string().required(),
    street: yup.string().required(),
    zipcode: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    country: yup.string().required(),
    cityName: yup.string().nullable(),
    operatingCity: yup.string().nullable(),
  }),
  addresses: yup
    .array()
    .of(
      yup.object({
        streetNumber: yup.string().required(),
        street: yup.string().required(),
        zipcode: yup.string().required(),
      }),
    )
    .nullable()
    .default([]),
})

export const upsertPropertySchema = yup
  .object<{
    type: PropertyType
    unitDesignation?: {
      key: 'streetNumber' | 'suite'
      values: string[]
    }
  }>({
    type: yup.string().oneOf(Object.values(PropertyType)).required(),
    unitDesignation: yup
      .object({
        key: yup.string().oneOf(['streetNumber', 'suite']).required(),
        values: yup.array<string>().of(yup.string()).min(1).required(),
      })
      .nullable(),
  })
  .required()
