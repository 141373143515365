import { deprecatedGenerateEventsDefinition } from '@guiker/shared-framework'

import { RentCharge } from '../../entity'
import { baseEvent as domainBaseEvent } from '../base'

export const EventsDefinition = deprecatedGenerateEventsDefinition({
  ...domainBaseEvent,
  entity: 'RENT_CHARGE',
  data: null as unknown as RentCharge,
  eventTypes: {
    CREATED: {
      type: 'CREATED',
      eventData: null as {
        batchCharge: {
          id: string
          chargeDate: string
        }
      },
    },
    CHARGE_INITIATED: {
      type: 'CHARGE_INITIATED',
      eventData: null as {
        batchCharge: {
          id: string
          chargeDate: string
        }
      },
    },
    CHARGE_REMINDER_PROCESSED: 'CHARGE_REMINDER_PROCESSED',
  },
})
export const Events = EventsDefinition.events
