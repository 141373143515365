import React from 'react'

import { isCrawlerUserAgent } from '@guiker/prerender'
import { FormSection3, safelyGetWindow, useTranslation } from '@guiker/react-framework'
import { NEWProperty, projectHasDocument, ProjectWithDeveloper } from '@guiker/real-estate-shared'
import { pascalCase } from '@guiker/shared-framework'

import { CollapsibleDescription } from '../CollapsibleDescription'
import { ProjectDocuments } from '../Documents/ProjectDocuments'
import { Location } from '../Location'
import { ListOfProjectAttributes } from '../ProjectAttribute'

type ProjectAndDocumentsProps = {
  project: ProjectWithDeveloper
  property: NEWProperty
}

export const ProjectAndDocuments: React.FC<ProjectAndDocumentsProps> = ({ project, property }) => {
  const { t } = useTranslation('main-propsharing')
  const userAgent = safelyGetWindow()?.navigator.userAgent
  const isCrawler = userAgent && isCrawlerUserAgent(userAgent, true)

  return (
    <>
      {project && (
        <>
          <FormSection3 title={t('components.projectTab.aboutProject', { projectName: project.name })}>
            <CollapsibleDescription description={project.description} />
          </FormSection3>
          <ListOfProjectAttributes project={project} property={property} />
          {!isCrawler && (
            <FormSection3 title={t('components.projectTab.location')}>
              <Location
                address={property?.building?.mailingAddress}
                coordinates={property?.building?.location.coordinates}
              />
            </FormSection3>
          )}
          <FormSection3
            title={t('components.projectTab.aboutDeveloper', {
              developerName: pascalCase(project.developer.name),
            })}
          >
            <CollapsibleDescription defaultCollapsed={false} description={project.developer.description} />
          </FormSection3>
        </>
      )}
      {projectHasDocument(project) && (
        <FormSection3 title={t('components.projectTab.projectDocuments.header')}>
          <ProjectDocuments project={project} />
        </FormSection3>
      )}
    </>
  )
}
