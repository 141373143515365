import { deprecatedGenerateEventsDefinition } from '@guiker/event'

import { NEWProperty, NEWUnit } from '../../entity'
import { baseEvent } from '../base'

type SyncedEventData = {
  legacyId: number
}

const basePropertyEvent = {
  ...baseEvent,
  entity: 'PROPERTY',
  data: null as unknown as NEWProperty,
}

export const PropertyEventsDefinition = deprecatedGenerateEventsDefinition({
  ...basePropertyEvent,
  eventTypes: {
    SYNCED: {
      type: 'SYNCED',
      eventData: null as SyncedEventData,
    },
    CREATION_DRAFTED: 'CREATION_DRAFTED',
    CREATION_COMPLETED: 'CREATION_COMPLETED',
    PUBLISHED: 'PUBLISHED',
    UNPUBLISHED: 'UNPUBLISHED',
    BUILDING_UPDATED: 'BUILDING_UPDATED',
    OCCUPANCY_UPDATED: 'OCCUPANCY_UPDATED',
    SPLIT: 'SPLIT',
    MERGE_PROPERTY_DELETED: {
      type: 'MERGE_PROPERTY_DELETED',
      eventData: null as {
        mainBuildingId: string
        upsertedUnit?: NEWUnit
        upsertedProperty: NEWProperty
      },
    },
  } as const,
})

export const Events = PropertyEventsDefinition.events
export const EventTypes = PropertyEventsDefinition.eventTypes
export type PropertyEventTypes = typeof EventTypes
