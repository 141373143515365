import { routes as financialStatementsRoutes } from './financial-statements'
import { routes as investorProfileRoutes } from './investor-profiles'
import { routes as listingRoutes } from './listings'
import { routes as stockRoutes } from './stocks'
import { routes as userRoutes } from './users'

export const routes = {
  ...financialStatementsRoutes,
  ...investorProfileRoutes,
  ...listingRoutes,
  ...stockRoutes,
  ...userRoutes,
}
