import { computeInvestmentResult, FinancialInfo } from '@guiker/base-entity'

import { Deal, FinancialStatements, LoanType, Series, SeriesDocument } from '../entity'

export const hydrateSeries = (series: SeriesDocument): Series => ({ ...series })

export const draftDealFromFinancialStatements = (financialStatements: FinancialStatements): Deal => {
  const { assets, totalLiabilityValues } = financialStatements.balanceSheet
  const findAsset = (label: string) => assets?.find((asset) => asset.label === label)

  return {
    liabilities: {
      loan: {
        loanType: LoanType.MORTGAGE,
        amount: totalLiabilityValues,
        termsInYear: 25,
        rate: 0,
      },
    },
    assets: {
      taxesAndFees: findAsset('taxesAndFees')?.amount,
      propertyValue: findAsset('propertyValue')?.amount,
      roundCashValue: findAsset('roundCashValue')?.amount,
      cashValue: findAsset('cashValue')?.amount,
    },
  }
}

export const getSeriesTargetFromFinancials = (
  financialInfo: Pick<FinancialInfo.FinancialInfo, 'financials'>,
  unitQuantity = 100000,
) => {
  const { financials } = financialInfo
  const results = computeInvestmentResult(financials)

  const assets = {
    propertyValue: financials.purchase.price.value.amount,
    taxesAndFees: results.taxesAndFees,
    cashValue: results.cashReserve,
    roundCashValue: 0,
  }

  const liabilities = {
    loan: {
      amount: results.loanAmount,
      rate: financials.financing.mortgage.interestRate,
      termsInYear: financials.financing.mortgage.termsInYears,
      loanType: LoanType.MORTGAGE,
    },
  }

  const { nav, roundCashValue } = getSeriesMetrics({ unitQuantity, deal: { assets, liabilities } })

  return {
    equity: nav,
    unitQuantity,
    deal: {
      assets: {
        ...assets,
        roundCashValue,
      },
      liabilities,
    },
  }
}

export const getSeriesMetrics = ({
  unitQuantity,
  deal: { assets: _assets, liabilities },
}: {
  unitQuantity: number
  deal: {
    assets?: Series['target']['deal']['assets']
    liabilities?: { loan: Pick<Series['target']['deal']['liabilities']['loan'], 'amount'> }
  }
}) => {
  const { roundCashValue: _, ...assets } = _assets || {}

  const rawTotalAssets = Object.values(assets).reduce((acc, asset) => acc + (asset || 0), 0)
  const totalLiabilities = liabilities?.loan?.amount || 0

  const rawNav = rawTotalAssets - totalLiabilities
  const rawNavPerUnit = rawNav / unitQuantity
  const navPerUnit = Math.ceil(rawNavPerUnit)
  const roundCashValuePerUnit = navPerUnit - rawNavPerUnit
  const roundCashValue = roundCashValuePerUnit * unitQuantity

  const totalAssets = rawTotalAssets + roundCashValue
  const nav = totalAssets - totalLiabilities

  return {
    totalAssets,
    totalLiabilities,
    nav,
    navPerUnit,
    roundCashValue,
  }
}
