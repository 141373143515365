import { QuebecOperationTaxes } from '../operation-taxes'

export const OperationTaxes = {
  ...QuebecOperationTaxes,
  municipalTax: {
    value: {
      type: 'percentage',
      base: 'assessedValue',
      rate: 0.00717,
      exempetion: 2500000,
    },
  },
  schoolTax: {
    value: {
      type: 'percentage',
      base: 'assessedValue',
      rate: 0.0009152,
      exempetion: 2500000,
    },
  },
} as const
