import { routesBuilder } from '@guiker/shared-framework'

import { PropSharingListing } from '../../../entity'
import { searchListingQuerySchema, searchListingSchema } from '../../schemas'
import { paths } from '../paths'

const { buildPost } = routesBuilder<PropSharingListing>()({
  basePath: paths.listings.path,
})

export const routes = {
  readAllListing: buildPost({
    queryParamsSchema: searchListingQuerySchema,
    payloadSchema: searchListingSchema,
    paginated: true,
  }),
}
