import React from 'react'

import { SearchAddress, SearchAddressWithFields } from '@guiker/address-autocomplete-component'
import {
  Button,
  Divider,
  ErrorOption,
  Flex,
  Link,
  MinusSmallIcon,
  PlusSmallIcon,
  useFieldArray,
  useFormContext,
  useT,
} from '@guiker/react-framework'
import { NEWBuilding } from '@guiker/real-estate-shared'
import { CountryCode } from '@guiker/shared-framework'

type AdditionalAddressFormContentProps = {
  index: number
  defaultAddress: SearchAddress['address']
  onRemove: () => void
}

const validateMatchingBaseAddress = ({
  selectedAddress,
  baseAddress,
  name,
  fieldRestrictions,
  matchingFields,
  errorMessage,
  errorCode,
  setError,
}: {
  selectedAddress: SearchAddress['address']
  baseAddress: SearchAddress['address']
  name: string
  matchingFields: string[]
  fieldRestrictions: string[]
  errorMessage: string
  errorCode: string
  setError: (name: string, error: ErrorOption, options?: { shouldFocus: boolean }) => void
}) => {
  const matching = matchingFields.every((field) => selectedAddress[field] === baseAddress[field])

  if (!matching) {
    setError(name, { message: errorMessage, type: errorCode })
    fieldRestrictions.forEach((field) => {
      setError(`${name}.${field}`, {
        message: errorMessage,
        type: errorCode,
      })
    })
  }

  return matching
}

const AdditionalAddressFormContent: React.FC<AdditionalAddressFormContentProps> = ({
  index,
  defaultAddress,
  onRemove: propsOnRemove,
}) => {
  const name = `property.building.addresses[${index}]`
  const fieldRestrictions = ['streetNumber', 'street', 'zipcode']
  const matchingFields = ['country', 'state', 'city']
  const { t, tBase } = useT({
    domain: 'components',
    basePrefix: 'address',
    entity: 'addressComponent',
  })
  const { setValue, setError, clearErrors } = useFormContext()

  const onAddressChange = ({ address }: SearchAddress) => {
    if (
      !!defaultAddress &&
      validateMatchingBaseAddress({
        selectedAddress: address,
        matchingFields,
        fieldRestrictions,
        name,
        errorMessage: t('address.mismatch'),
        errorCode: t('address.mismatchCode'),
        baseAddress: defaultAddress,
        setError,
      })
    ) {
      setValue(name, address, {
        shouldDirty: true,
        shouldValidate: true,
      })
    } else {
      setValue(name, {})
    }
  }

  const onRemove = () => {
    clearErrors()
    propsOnRemove?.()
  }

  return (
    <Flex flexDirection='column' gap={1}>
      <SearchAddressWithFields
        name={name}
        countryRestrictions={[CountryCode.CA]}
        fieldRestrictions={fieldRestrictions}
        onAddressChange={onAddressChange}
        runDefaultAddressChange={false}
        withZipcode
      />
      <Link onClick={onRemove} underline={false} adornment={<MinusSmallIcon />}>
        {tBase('removeAddress')}
      </Link>
    </Flex>
  )
}

export const AdditionalAddressContainer: React.FC<{
  selectedAddress: SearchAddress['address']
}> = ({ selectedAddress }) => {
  const { tBase } = useT({
    domain: 'components',
    basePrefix: 'address',
    entity: 'addressComponent',
  })
  const formMethods = useFormContext<{
    property: { building: { addresses: NEWBuilding['addresses'] } }
  }>()
  const {
    fields: additionalAddressFields,
    append: addNewAdditionalAddress,
    remove: removeAdditionalAddress,
  } = useFieldArray({
    control: formMethods.control,
    name: 'property.building.addresses',
  })

  return (
    <>
      {additionalAddressFields.map((additionalAddress, i) => (
        <>
          <AdditionalAddressFormContent
            key={additionalAddress.id}
            index={i}
            defaultAddress={selectedAddress}
            onRemove={() => removeAdditionalAddress(i)}
          />
          <Divider />
        </>
      ))}
      {!!selectedAddress && (
        <Button
          startIcon={<PlusSmallIcon />}
          onClick={() => addNewAdditionalAddress({ streetNumber: '', street: '', zipcode: '' })}
        >
          {tBase('addAddress')}
        </Button>
      )}
    </>
  )
}
