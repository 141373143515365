import { routesBuilder } from '@guiker/shared-framework'

import { PropSharingListing } from '../../../entity'
import { searchListingQuerySchema, searchListingSchema } from '../../schemas'
import { paths } from '../paths'

const { buildGet, buildPost } = routesBuilder<PropSharingListing>()({
  basePath: paths.listings.path,
})

export const routes = {
  readAllListings: buildPost({
    path: '/search',
    paginated: true,
    queryParamsSchema: searchListingQuerySchema,
    payloadSchema: searchListingSchema,
  }),
  readAllPublishedListings: buildGet({
    path: '/published',
    paginated: true,
    queryParamsSchema: searchListingQuerySchema,
  }),
}
