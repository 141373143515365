import React from 'react'

import { getLocalizedNeighbourhood, Grid, useTranslation } from '@guiker/react-framework'
import { NEWProperty, ProjectWithDeveloper } from '@guiker/real-estate-shared'
import { pascalCase } from '@guiker/shared-framework'

import { ProjectAttribute } from './ProjectAttribute'

type ListOfProjectAttributesProps = {
  project: ProjectWithDeveloper
  property: NEWProperty
}

const ListOfProjectAttributes: React.FC<ListOfProjectAttributesProps> = ({ project, property }) => {
  const { t } = useTranslation('common-baseListing')
  const address = property?.building?.mailingAddress

  return (
    <Grid container spacing={6}>
      <Grid item sm={4} xs={12}>
        <ProjectAttribute value={pascalCase(project.developer?.name)} label={t('projectAttributes.developer')} />
      </Grid>

      {address?.neighbourhood && (
        <Grid item sm={4} xs={12}>
          <ProjectAttribute
            value={getLocalizedNeighbourhood(t, {
              countryCode: address?.country,
              citySlug: address?.city,
              neighbourhoodSlug: address?.neighbourhood,
            })}
            label={t('projectAttributes.neighborhood')}
          />
        </Grid>
      )}

      {/* <Grid item sm={4} xs={12}>
        <ProjectAttribute value={pascalCase(listing.deal?.type)} label={t('projectAttributes.status')} />
      </Grid> */}

      <Grid item sm={4} xs={12}>
        <ProjectAttribute
          value={t(`buildingType.${property?.building?.type}`)}
          label={t('projectAttributes.buildingType')}
        />
      </Grid>

      {property?.building?.configuration?.totalNumberOfUnits && (
        <Grid item sm={4} xs={12}>
          <ProjectAttribute
            value={property.building.configuration.totalNumberOfUnits}
            label={t('projectAttributes.numberOfUnits')}
          />
        </Grid>
      )}

      {property?.building?.configuration?.totalStoreys && (
        <Grid item sm={4} xs={12}>
          <ProjectAttribute
            value={property?.building?.configuration?.totalStoreys}
            label={t('projectAttributes.numberOfStoreys')}
          />
        </Grid>
      )}

      {property?.building?.construction?.year && (
        <Grid item sm={4} xs={12}>
          <ProjectAttribute
            value={property?.building?.construction?.year}
            label={t('projectAttributes.buildCompleted')}
          />
        </Grid>
      )}
    </Grid>
  )
}

export { ListOfProjectAttributes }
