import React from 'react'

import { Flex, makeStyles, Modal, numberFormatter, SecondaryButton, useModal } from '@guiker/react-framework'
import { money } from '@guiker/shared-framework'

import { useFinancialInfoContext } from '../../context'
import { useT } from '../../i18n'
import { KpiItemContent } from '../KpiGrid'
import { YearlyPAndL } from '../YearlyPAndL'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'fixed',
      bottom: 98,
      left: 80,
      right: 24,
      backgroundColor: theme.palette.common.white,
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.grey[15]}`,
      borderRadius: 8,
      overflow: 'hidden',
    },
    pAndL: {
      height: 600,
      maxHeight: 600,
      overflow: 'scroll',
      position: 'absolute',
    },
  }),
  { name: 'FloatinFinancialKPIs' },
)

export const FloatingFinancialKPIs: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <FinancialKPIs />
    </div>
  )
}

export const FinancialKPIs = () => {
  const { results, currency } = useFinancialInfoContext()
  const { isOpen, openModal, closeModal } = useModal()
  const { tShared, tComponents } = useT({ componentName: 'FinancialKPIs', entity: 'property.financialInfo.results' })

  const items = [
    {
      label: tShared('totalProjectCost'),
      description: money.fromAmount(results.totalProjectCost, currency).toString('onlySymbol', true),
    },
    {
      label: tComponents('firstYearCashFlow'),
      description: money.fromAmount(results.yearlyCashflows[0].netCashFlow, currency).toString('onlySymbol', true),
    },
    {
      label: tShared('estimatedSalesPrice'),
      description: money.fromAmount(results.estimatedSalesPrice, currency).toString('onlySymbol', true),
    },
    {
      label: tShared('irr'),
      description: `${numberFormatter.percentage(2).toDisplay(results.irr)}%`,
    },
  ]

  return (
    <Flex flexDirection='column'>
      <Modal open={isOpen} maxWidth={'90%'} onClose={closeModal}>
        <YearlyPAndL withFrozenHeaders={false} />
      </Modal>
      <Flex flexDirection='row'>
        {items.map((item) => (
          <KpiItemContent item={item} />
        ))}
        <Flex flexDirection='column' flex={1} px={4} py={2}>
          <SecondaryButton onClick={openModal}>{tComponents('seePAndL')}</SecondaryButton>
        </Flex>
      </Flex>
    </Flex>
  )
}
