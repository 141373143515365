import { deprecatedGenerateEventsDefinition } from '@guiker/shared-framework'

import { RentPaymentsPlan } from '../../entity'
import { baseEvent as domainBaseEvent } from '../base'
import { RentPaymentsPlanEventTypes } from './event-types'

export const EventsDefinition = deprecatedGenerateEventsDefinition({
  ...domainBaseEvent,
  entity: 'RENT_PAYMENTS_PLAN',
  data: null as unknown as RentPaymentsPlan,
  eventTypes: {
    ...RentPaymentsPlanEventTypes,
  } as const,
})
export const Events = EventsDefinition.events
export const EventTypes = EventsDefinition.eventTypes
