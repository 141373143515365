import { FinancialInfo } from '@guiker/base-entity'

import { NEWUnit, Unit } from '../entity'

export const isActiveOccupancyLease = (lease: FinancialInfo.OccupancyLease) => {
  const today = new Date()
  const from = new Date(lease?.period?.from || '')
  const to = new Date(lease?.period?.to || '')
  return from <= today && today <= to
}

export const unitOccupancyHelper = (unit: NEWUnit | Unit, occupancies: FinancialInfo.Occupancy[]) => {
  const occupancy = occupancies.find((o) => o.unit === (unit as NEWUnit).label || (unit as Unit).address?.unitNumber)
  const lastLease = occupancy?.leases?.sort(
    (a: FinancialInfo.OccupancyLease, b: FinancialInfo.OccupancyLease) =>
      new Date(a?.period?.to).getTime() - new Date(b?.period?.to).getTime(),
  )[0]

  const lastMonthlyRent = lastLease?.price?.amount || 0

  const isOccupied = isActiveOccupancyLease(lastLease)
  const period = lastLease?.period
  const monthlyRent = lastLease?.price?.amount || 0

  return {
    activeLease: isOccupied ? lastLease : null,
    isOccupied,
    period,
    monthlyRent,
    lastMonthlyRent,
  }
}
