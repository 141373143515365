import { generateEventsDefinition } from '@guiker/event'

import { ShareJournalEntry } from '../../entity'
import { baseEvent } from '../base'

export const { ShareJournalEntryEventsDefinition } = generateEventsDefinition({
  ...baseEvent,
  entity: 'SHARE_JOURNAL_ENTRY',
  data: null as ShareJournalEntry,
  eventTypes: {
    RETIRED: {
      type: 'RETIRED',
      eventData: null as {
        tickerSymbol: string
        userId: string
        stockOrderExecutionId: string
        debit: ShareJournalEntry
        credit: ShareJournalEntry
      },
    },
    FAILED_FROM_STOCK_ORDER_EXECUTION: {
      type: 'FAILED_FROM_STOCK_ORDER_EXECUTION',
      eventData: null as {
        stockOrderExecutionId: string
      },
    },
    INSERTED_FROM_STOCK_ORDER_EXECUTION: {
      type: 'INSERTED_FROM_STOCK_ORDER_EXECUTION',
      eventData: null as {
        stockOrderExecutionId: string
        debit: ShareJournalEntry
        credit: ShareJournalEntry
      },
    },
  },
} as const)
