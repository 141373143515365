import { AuthLevel, buildPaths } from '@guiker/api-definition'

export const paths = buildPaths(
  {
    listing: {},
    stock: {
      allowance: {},
    },
  },
  AuthLevel.PUBLIC,
)
