import { EntityDocument, User } from '@guiker/base-entity'
import { ValueOf } from '@guiker/ts-utils'

import { PayoutMethod, WithPayoutMethod } from '../payout-method'
import { FundingAccount, WithFundingAccount, WithUser } from './entity'

export const FundingAccountWithdrawalStatus = {
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
} as const

export type FundingAccountWithdrawalStatus = ValueOf<typeof FundingAccountWithdrawalStatus>

export enum FundingAccountWithdrawalType {
  WITHDRAWAL = 'WITHDRAWAL',
  CHARGE = 'CHARGE',
}

export type FundingAccountWithdrawalWriteModel = Omit<FundingAccountWithdrawal, 'payoutMethod' | 'user'> & {
  payoutMethod?: {
    id: string
  }
}

export type FundingAccountWithdrawal = EntityDocument & {
  fundingAccountId: string
  fundingAccount: FundingAccount
  type: FundingAccountWithdrawalType
  payoutMethod?: PayoutMethod
  amount: number
  status: FundingAccountWithdrawalStatus
  metadata?: { note?: string } & Record<string, any>
  user: User
}

export type WithChargeType<T> = Omit<T, 'metadata'> & {
  metadata: { invoiceId: string; note: string } & Record<string, any>
}

export type FundingAccountWithdrawalProjection = WithUser<
  WithFundingAccount<WithPayoutMethod<Omit<FundingAccountWithdrawal, 'payoutMethod'>>>
>
