import { merge } from 'lodash'

export const upsert = <T extends object>(
  array: T[],
  predicate: (value: T, index: number) => boolean,
  updator: (curr: T) => T,
) => {
  const found = array.find(predicate)

  if (!found) {
    array.push(updator({} as T))

    return array
  } else {
    array = array.map((element, index) => {
      if (predicate(element, index)) {
        return updator(element)
      }

      return element
    })

    return array
  }
}

export const mergeArrays = <T extends object>(
  arrayA: T[],
  arrayB: T[],
  predicate: (valueA: T, valueB: T, index: number) => boolean,
  updator: (valueA: T, valueB: T) => T = merge,
) => {
  arrayB.forEach((valueB) => {
    upsert(
      arrayA,
      (valueA, index) => predicate(valueA, valueB, index),
      (valueA) => updator(valueA, valueB),
    )
  })

  return arrayA
}
