import React, { useState } from 'react'

import { makeStyles, theme } from '@guiker/components-core'
import { Flex, Spinner, TwoColumnsGridLayout, useMediaQuery } from '@guiker/components-library'
import { MapLocation, PointOfInterest, PointOfInterestType } from '@guiker/map-shared'
import { GoogleMap, Markers } from '@guiker/react-google-maps'
import { useQuery } from '@guiker/react-query'
import { NEWBuilding } from '@guiker/real-estate-shared'

import { useMapApiClient } from '../../hooks'
import { PointOfInterestContainer } from '../PointOfInterest'

type LocationProps = {
  address: NEWBuilding['mailingAddress']
  coordinates: NEWBuilding['location']['coordinates']
}

const useStyle = makeStyles(
  {
    mapContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: 500,
      maxWidth: 900,
      overflow: 'hidden',
    },
  },
  {
    name: 'Location',
  },
)

const Location: React.FC<LocationProps> = ({ address, coordinates }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyle({ isMobile })
  const apiClient = useMapApiClient()
  const [subwayList, setSubwayList] = useState<PointOfInterest[]>()
  const [universityList, setUniversityList] = useState<PointOfInterest[]>()

  const { isLoading } = useQuery<MapLocation>(
    ['locate-poi', address.zipcode],
    () => apiClient.locatePointsOfInterest({ payload: { lng: coordinates[0], lat: coordinates[1] } }),
    {
      onSuccess: (result: MapLocation) => {
        setSubwayList(result.pointsOfInterests.filter((poi) => poi.type === PointOfInterestType.SUBWAY_STATION))
        setUniversityList(result.pointsOfInterests.filter((poi) => poi.type === PointOfInterestType.UNIVERSITY))
      },
      enabled: !!address,
      retry: false,
    },
  )

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <Flex gap={4} flexDirection='column' justifyContent='space-between' flexWrap='wrap'>
          <Flex className={classes.mapContainer}>
            <GoogleMap center={{ lng: coordinates[0], lat: coordinates[1] }} height={300} width='100%' zoom={15}>
              {subwayList?.length &&
                subwayList.map((subwayStation) => {
                  return <Markers.Subway position={subwayStation.location} key={subwayStation.name} />
                })}
              {universityList?.length &&
                universityList.map((university) => {
                  return <Markers.University position={university.location} key={university.name} />
                })}
              <Markers.Guiker position={{ lng: coordinates[0], lat: coordinates[1] }} />
            </GoogleMap>
          </Flex>
          {(!!subwayList?.length || !!universityList?.length) && (
            <TwoColumnsGridLayout>
              {!!subwayList?.length && (
                <PointOfInterestContainer pointOfInterests={subwayList} type={PointOfInterestType.SUBWAY_STATION} />
              )}
              {!!universityList?.length && (
                <PointOfInterestContainer pointOfInterests={universityList} type={PointOfInterestType.UNIVERSITY} />
              )}
            </TwoColumnsGridLayout>
          )}
        </Flex>
      )}
    </div>
  )
}

export { Location }
