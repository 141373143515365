import React from 'react'

import { PieChart } from '@guiker/react-chart'
import {
  isAtMostTablette,
  makeStyles,
  Note,
  PageSection3,
  RentalProcessIcon,
  toPx,
  TwoColumnsGridLayout,
  useLayoutContext,
} from '@guiker/react-framework'
import { NEWProperty } from '@guiker/real-estate-shared'
import { money } from '@guiker/shared-framework'

import { useFinancialInfoContext } from '../../context'
import { useT } from '../../i18n'
import { useRentalDetails } from '../../utils'
import { FinancialBreakdown } from '../FinancialBreakdown'
import { KpiGrid } from '../KpiGrid'
import { UnitBreakdown } from './UnitBreakdown'

const useStyles = makeStyles(() => ({
  occupancyTable: {
    '& > table': {
      [isAtMostTablette]: {
        width: '100%',
        maxWidth: 600,
      },
    },
  },
  pieChart: {
    height: 260,
    width: '100%',
    fontSize: toPx(14),
    marginBottom: 200,
  },
}))

type Props = {
  property: NEWProperty
}

export const RentalDetails: React.FC<Props> = ({ property }) => {
  const classes = useStyles()
  const { tShared } = useT({ entity: 'rentalDetails' })
  const { results, currency } = useFinancialInfoContext()
  const yearlyCashflows = results.yearlyCashflows[0]
  const { isAtMostTablette } = useLayoutContext()

  const { financialItems, kpiItems, totalExpenses, pieChartItems } = useRentalDetails({
    yearlyCashflows,
    currency,
    property,
  })

  const moneyFormatter = money.formatter(currency, 'onlySymbol', true)

  return (
    <>
      <Note
        type='collapsible'
        title={tShared('title')}
        icon={<RentalProcessIcon color='textPrimary' />}
        statusColor='alabaster'
        isCollapsed={true}
        openAsModalOnMobile
      >
        {tShared('description')}
      </Note>
      <KpiGrid items={kpiItems} />
      <UnitBreakdown units={property.units} currency={currency.iso} occupancies={property.financialInfo.occupancies} />
      <TwoColumnsGridLayout gap={isAtMostTablette ? 4 : 8}>
        <PageSection3 title={tShared('cashFlow.title')}>
          <FinancialBreakdown
            currency={currency}
            items={financialItems}
            total={{
              label: tShared('cashFlow.netCashflow'),
              value: yearlyCashflows.netCashFlow,
            }}
          />
        </PageSection3>
        <PageSection3 title={tShared('cashFlow.expensesBreakdown')}>
          <div className={classes.pieChart}>
            <PieChart
              dataset={pieChartItems}
              labelFormatter={moneyFormatter}
              total={totalExpenses.monetized}
              showLegend
              highlight={{
                label: tShared('cashFlow.total'),
                value: totalExpenses.monetized,
                labelFormatter: moneyFormatter,
              }}
            />
          </div>
        </PageSection3>
      </TwoColumnsGridLayout>
    </>
  )
}
