import { TypeOf, yup } from '@guiker/shared-framework'

import { updateNEWPropertyBuildingDetailSchema } from './update-NEW-property-building-details'

export const updateNEWBuildingDetailsPayload = yup
  .object({
    details: updateNEWPropertyBuildingDetailSchema.required(),
  })
  .required()

export type UpdateNEWBuildingDetailsPayload = TypeOf<typeof updateNEWBuildingDetailsPayload>
