import { FinancialInfo, PhoneNumber, User } from '@guiker/base-entity'
import { DeepPartial } from '@guiker/ts-utils'

import { Developer } from './developer'
import { NEWProperty, Property, WithPropertyHasBuilding } from './property'

export type PropertyOwnership = {
  id: string
  property: DeepPartial<Property>
  owner: User | Developer
  effectiveAt: string
  transferedAt?: string
} //deprecate

//////

export enum PropertyOwnershipType {
  SOLE_OWNERSHIP = 'SOLE_OWNERSHIP',
  CO_OWNERSHIP = 'CO_OWNERSHIP',
  PROPSHARING = 'PROPSHARING',
}

export enum RepresentativeRole {
  OWNER = 'OWNER',
}

export type Representative = {
  userId: string
  firstName: string
  lastName: string
  emailAddress: string
  phone?: PhoneNumber
  ownershipPercentage?: number
  role: RepresentativeRole
}

export type HoldingPeriod = {
  from: string // ISODate
  to?: string // ISODate
}

export type NEWPropertyOwnership = {
  id: string
  property: DeepPartial<NEWProperty>
  type: PropertyOwnershipType
  representatives?: Representative[]
  financialInfo?: FinancialInfo.FinancialInfo
  holdingPeriod?: HoldingPeriod
  meta?: {
    legacyIds: number[]
  }
}

export type WithPropertyOwnership<T> = T & {
  propertyOwnership: WithPropertyHasBuilding<NEWPropertyOwnership>
}
