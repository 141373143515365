import { deprecatedGenerateEventsDefinition } from '@guiker/event'
import { ValueOf } from '@guiker/ts-utils'

import { Contract } from '../../entity'
import { baseEvent } from '../base'

export const ContractEventsDefinition = deprecatedGenerateEventsDefinition({
  ...baseEvent,
  entity: 'CONTRACT',
  data: null as Contract,
  eventTypes: {
    PARTICIPANT_ADDED: 'PARTICIPANT_ADDED',
    PROPSHARING_TAX_SLIP_ADDED: 'PROP_SHARING_TAX_SLIP_ADDED',
    PROPSHARING_BUYOUT_CREATED: 'PROPSHARING_BUYOUT_CREATED',
    PROPSHARING_SUBSCRIPTION_AGREEMENT_ADDED: 'PROPSHARING_SUBSCRIPTION_AGREEMENT_ADDED',
  } as const,
})

export const { eventTypes: ContractEventTypes, events: ContractEvents } = ContractEventsDefinition
export type ContractEventTypes = ValueOf<typeof ContractEventTypes>
