import React from 'react'

import { DisplayBody, ExpandableFadingBlock, Flex, PageSection2 } from '@guiker/react-framework'

import { usePropertyContext } from '../../context'
import { useT } from '../../i18n'
import { KpiGrid, KpiGridProps } from '../KpiGrid'
import { hasTwoOrMoreAttributes, UnitConfig } from '../UnitConfig'

type PropertyDescriptionProps = {
  kpiGridItems: KpiGridProps['items']
}

export const PropertyDescription: React.FC<PropertyDescriptionProps> = ({ kpiGridItems }) => {
  const { tMain } = useT({ screenName: 'screens.listingScreen' })
  const { property } = usePropertyContext()

  return (
    <Flex flexDirection='column' gap={8}>
      <KpiGrid items={kpiGridItems} />
      {property?.description && (
        <PageSection2 title={tMain(`tab1.sections.description.title`)} hasDivider={false}>
          {property && hasTwoOrMoreAttributes(property) && <UnitConfig property={property} />}
          <ExpandableFadingBlock maxHeight={100}>
            <DisplayBody>{property?.description}</DisplayBody>
          </ExpandableFadingBlock>
        </PageSection2>
      )}
    </Flex>
  )
}
