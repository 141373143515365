import { routesBuilder } from '@guiker/api-definition'

import { NEWUnit } from '../../../entity'
import { createUnitsSchema } from '../schemas'
import { paths } from './paths'

const base = routesBuilder<NEWUnit>()({ basePath: paths.NEWUnit.all.base() })

export const routes = {
  createUnits: base.buildPost({
    payloadSchema: createUnitsSchema,
    responseIsArray: true,
  }),
}
