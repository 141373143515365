import { routesBuilder } from '@guiker/shared-framework'

import { InvestorProfile } from '../../../../entity'
import { paths } from '../../paths'

const { buildGet } = routesBuilder<InvestorProfile>()({
  basePath: paths.users.withId.investorProfile.path,
})

export const routes = {
  readOneInvestorProfileByUserId: buildGet({}),
}
