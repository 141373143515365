import { CostArgs } from '../../../../utils'
import { OperationRevenues } from '../../../operation-revenues'
import { PurchaseBase } from '../../purchase-base'
import { OperationCost } from './operation-cost'
import { OperationTaxes } from './operation-taxes'
import { PurchaseCost } from './purchase-cost'
import { PurchaseTaxes } from './purchase-taxes'

export type Jurisdiction = 'montreal-qc'

export const Montreal = {
  purchase: (args: CostArgs) => ({
    ...PurchaseBase,
    costs: PurchaseCost,
    taxes: PurchaseTaxes(args),
  }),
  operation: {
    revenues: OperationRevenues,
    expenses: {
      costs: OperationCost,
      taxes: OperationTaxes,
    },
  },
} as const
