import { deprecatedGenerateEventsDefinition, ValueOfEventTypes } from '@guiker/shared-framework'

import { Booking, BookingLeaseParticipant } from '../../entity'
import { BaseBookingEvent } from '../base-event'

type SignerEventData = {
  lease: {
    participants: BookingLeaseParticipant[]
  }
}

export const definition = deprecatedGenerateEventsDefinition({
  ...BaseBookingEvent,
  entity: 'BOOKING',
  data: null as unknown as Booking,
  eventTypes: {
    LEASE_STARTED: 'LEASE_STARTED',
    LEASE_READY_TO_BE_SIGNED: 'LEASE_READY_TO_BE_SIGNED',
    LEASE_PARTICIPANT_SIGNED: {
      type: 'LEASE_PARTICIPANT_SIGNED',
      eventData: null as SignerEventData,
    },
    LEASE_SIGNED_BY_ALL_PARTIES: 'LEASE_SIGNED_BY_ALL_PARTIES',
  } as const,
})
export const { events: Events, eventTypes: EventTypes } = definition
export type EventTypes = ValueOfEventTypes<typeof definition>
