import React from 'react'

import { FormSection2, FormSection4, useWatch } from '@guiker/react-framework'
import { FinancialInfo } from '@guiker/shared-framework'

import { useT } from '../../i18n'
import { CalculationItemInput } from '../CalculationItem'

type FinancialPurchaseFormContentProps = {}

export const FinancialPurchaseCostsFormContent: React.FC<FinancialPurchaseFormContentProps> = () => {
  return (
    <FormSection4>
      <CalculationItemInput
        name='financialInfo.financials.purchase.costs.initialRenovation'
        withYearlyGrowthRate={false}
        frequency={null}
      />
      <CalculationItemInput
        name='financialInfo.financials.purchase.costs.cashReserve'
        withYearlyGrowthRate={false}
        frequency={null}
      />
      <CalculationItemInput
        name='financialInfo.financials.purchase.costs.closingCost'
        withYearlyGrowthRate={false}
        frequency={null}
      />
      <CalculationItemInput
        name='financialInfo.financials.purchase.costs.offeringCost'
        withYearlyGrowthRate={false}
        frequency={null}
      />
    </FormSection4>
  )
}

export const FinancialPurchaseTaxesFormContent: React.FC<FinancialPurchaseFormContentProps> = () => {
  const financialInfo = useWatch({ name: 'financialInfo' })
  const taxes = financialInfo.financials.purchase.taxes

  return (
    <FormSection4>
      {Object.keys(taxes).map((tax) => {
        return (
          <CalculationItemInput
            canChangeType={false}
            name={`financialInfo.financials.purchase.taxes.${tax}`}
            base={taxes[tax].value.base}
            allowedTypes={[FinancialInfo.CalculationMethodTypes.bracket]}
          />
        )
      })}
    </FormSection4>
  )
}

export const FinancialPurchaseFormSection: React.FC<FinancialPurchaseFormContentProps> = () => {
  const { tComponents } = useT({ componentName: 'FinancialPurchaseFormSection' })

  return (
    <FormSection2 title={tComponents('title')} subtitle={tComponents('description')}>
      <FinancialPurchaseCostsFormContent />
      <FinancialPurchaseTaxesFormContent />
    </FormSection2>
  )
}
