import { deprecatedGenerateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { BookingDocument, BookingDocumentParticipant } from '../../entity/booking-document'
import { BaseBookingEvent } from '../base-event'

export const definition = deprecatedGenerateEventsDefinition({
  ...BaseBookingEvent,
  entity: 'DOCUMENT',
  eventTypes: {
    CREATED: 'CREATED',
    UPDATED: 'UPDATED',
    SENT: 'SENT',
    COMPLETED: 'COMPLETED',
    PREFILL_TABS_UPDATED: {
      type: 'PREFILL_TABS_UPDATED',
      eventData: null as {
        document: BookingDocument
      },
    },
    PARTICIPANTS_UPDATED: 'PARTICIPANTS_UPDATED',
    PARTICIPANT_SIGNED: {
      type: 'PARTICIPANT_SIGNED',
      eventData: null as {
        participant: BookingDocumentParticipant
      },
    },
  } as const,
})

export const { events: Events, eventTypes: EventTypes } = definition
export type EventTypes = ValueOfEventTypes<typeof definition>
