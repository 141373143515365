import { routesBuilder } from '@guiker/shared-framework'

import { PropRental, PropRentalWriteModel } from '../../../../../entity'
import { paths } from '../../../paths'

const { buildGet } = routesBuilder<PropRental, PropRentalWriteModel>()({
  basePath: paths.listings.withId.propRentals.path,
})

export const routes = {
  /** @deprecated remove once booking knows of propRental */
  readOnePropRentalByListingId: buildGet({}),
}
