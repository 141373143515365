import { generateEventsDefinition } from '@guiker/event'

import { FundingAccountWithdrawal, FundingAccountWithdrawalWriteModel, WithChargeType } from '../../entity'
import { baseEvent } from '../base'

export const { FundingAccountWithdrawalEventsDefinition } = generateEventsDefinition({
  ...baseEvent,
  entity: 'FUNDING_ACCOUNT_WITHDRAWAL',
  data: null as FundingAccountWithdrawal,
  eventTypes: {
    PROCESSED: 'PROCESSED',
    FAILED: 'FAILED',
    COMPLETED: 'COMPLETED',
    PAYOUT_COMPLETED: 'PAYOUT_COMPLETED',
    CHARGE_COMPLETED: 'CHARGE_COMPLETED',
    CHARGE_FAILED: {
      type: 'CHARGE_FAILED',
      eventData: null as WithChargeType<FundingAccountWithdrawalWriteModel>['metadata'],
    },
  } as const,
  write: {
    data: null as FundingAccountWithdrawalWriteModel,
  },
})
