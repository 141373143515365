import { CountByStatusMetadata, routesBuilder } from '@guiker/shared-framework'

import {
  FundingAccountDeposit,
  FundingAccountDepositStatus,
  FundingAccountDepositWriteModel,
} from '../../../../entity/funding-account'
import { paths } from '../../paths'
import { getStatusesSchemas } from '../../schemas/status-schema'
import { createSystemDepositSchema, searchStringSchema } from '../schemas'

const { buildGet, buildPost } = routesBuilder<FundingAccountDeposit, FundingAccountDepositWriteModel>()({
  basePath: paths.fundingAccounts.deposits.path,
})

export const routes = {
  readAllDeposits: buildGet({
    paginated: true,
    queryParamsSchema: searchStringSchema.concat(getStatusesSchemas<FundingAccountDepositStatus>()),
    additionalMeta: {} as CountByStatusMetadata<FundingAccountDepositStatus>,
  }),
  exportDeposits: buildGet({
    path: '/export',
    responseIsArray: true,
    queryParamsSchema: searchStringSchema.concat(getStatusesSchemas<FundingAccountDepositStatus>()),
  }),
  createSystemDeposit: buildPost({
    payloadSchema: createSystemDepositSchema,
  }),
} as const
