import { routesBuilder } from '@guiker/shared-framework'

import { PropRental, PropRentalWriteModel } from '../../../../../entity'
import { paths } from '../../../paths'
import { addPropRentalManager, updatePropRentalManager } from './schema'

const basePath = paths.propRentals.withId.managers
const base = routesBuilder<PropRental, PropRentalWriteModel>()({ basePath: basePath.path })
const IdNested = routesBuilder<PropRental, PropRentalWriteModel>()({ basePath: basePath.withId.path })

export const routes = {
  addPropRentalManager: base.buildPost({ payloadSchema: addPropRentalManager }),
  editPropRentalManager: IdNested.buildPut({ payloadSchema: updatePropRentalManager }),
  removePropRentalManager: IdNested.buildDelete({}),
}
