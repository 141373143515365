import { TypeOf, yup } from '@guiker/shared-framework'

import { seriesDealSchema } from './series-deal'

export const updateClosedSeriesSchema = yup
  .object({
    final: yup.object({
      unitQuantity: yup.number().required(),
      equity: yup.number().required(),
      deal: seriesDealSchema.required(),
    }),
    closeDate: yup.string(),
  })
  .required()

export type UpdateClosedSeriesSchema = TypeOf<typeof updateClosedSeriesSchema>
