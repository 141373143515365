import { deprecatedGenerateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { UserMetadata } from '../../entity'
import { baseEvent, BaseEventTypes } from '../base'

const baseUserMetadataEvent = {
  ...baseEvent,
  entity: 'USER_METADATA',
  data: null as unknown as UserMetadata,
}

export const EventTypes = {
  ...BaseEventTypes,
}

export type EventTypes = typeof EventTypes
export const EventsDefinition = deprecatedGenerateEventsDefinition(baseUserMetadataEvent)
export const Events = generateEventsFromEnum(baseUserMetadataEvent)(EventTypes)
