import { FinancialInfo } from '@guiker/base-entity'

import { FinancialStatements } from './financial-statements'

export enum SeriesStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  COMING_SOON = 'COMING_SOON',
}

export enum SeriesType {
  NEW_PURCHASE = 'NEW_PURCHASE',
  REFINANCING = 'REFINANCING',
}

export enum LoanType {
  MORTGAGE = 'MORTGAGE',
}

export type SeriesDocument = Series & {}

export type Deal = {
  assets?: {
    propertyValue: number
    taxesAndFees: number
    roundCashValue: number
    cashValue: number
  }
  liabilities?: {
    loan: {
      loanType: LoanType
      rate: number
      amount: number
      termsInYear: number
    }
  }
}

export type Series = {
  name: string
  slug: string
  status: SeriesStatus
  seriesType: SeriesType
  startDate?: string
  closeDate?: string
  pricePerUnit: number
  financialInfo?: FinancialInfo.FinancialInfo
  latestFinancialStatement?: FinancialStatements
  target: {
    unitQuantity: number
    equity: number
    deal?: Deal
  }
  final?: {
    unitQuantity: number
    equity: number
    deal?: Deal
  }
}
