import { deprecatedGenerateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { PropertySaleInquiry } from '../../entity'
import { baseEvent, BaseEventTypes } from '../base'

const basePropertySaleInquiryEvent = {
  ...baseEvent,
  entity: 'PROPERTY_SALE_INQUIRY',
  data: null as unknown as PropertySaleInquiry,
}

export const PropertySaleInquiryEventTypes = {
  ...BaseEventTypes,
}

export const PropertySaleInquiryEventsDefinition = deprecatedGenerateEventsDefinition(basePropertySaleInquiryEvent)
export const PropertySaleInquiryEvents =
  generateEventsFromEnum(basePropertySaleInquiryEvent)(PropertySaleInquiryEventTypes)
