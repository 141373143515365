import React, { useState } from 'react'

import {
  CollapseWithHeader,
  Flex,
  FormSection2,
  Link,
  makeStyles,
  padding,
  SecondaryButton,
  theme,
  useFieldArray,
} from '@guiker/react-framework'
import { NEWUnit, Unit } from '@guiker/real-estate-shared'

import { useT } from '../../i18n'
import { UnitComponent } from '../UnitComponent'

type PropertyUnitsFormContentProps = {}

const useStyles = makeStyles(() => ({
  unit: {
    padding: padding(2),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.getColor('info', 'background'),
  },
}))

export const PropertyUnitsFormContent: React.FC<PropertyUnitsFormContentProps> = () => {
  const { tComponents } = useT({ componentName: 'PropertyUnitsFormContent' })
  const { tComponents: tComponentsShared } = useT({ componentName: 'shared' })
  const classes = useStyles()

  const [selectedUnit, setSelectedUnit] = useState(0)

  const { fields, append, remove } = useFieldArray({
    name: 'property.units',
    keyName: 'key',
  })

  const onAddUnitClick = () => {
    append({} as Unit)
    setSelectedUnit(selectedUnit + 1)
  }

  return (
    <FormSection2>
      <Flex gap={2} flexDirection='column'>
        {fields.map((item, index) => (
          <CollapseWithHeader
            in={index === selectedUnit}
            header={tComponentsShared('unitNumber', {
              unitNumber: (item as unknown as NEWUnit).label || index + 1,
            })}
          >
            <div className={classes.unit}>
              <UnitComponent
                name={`property.units.${index}`}
                key={item.key}
                canRemove={!(index === 0 && fields?.length <= 1)}
                onRemove={() => {
                  remove(index)
                  setSelectedUnit(null)
                }}
              />
            </div>
          </CollapseWithHeader>
        ))}
        <Link onClick={() => onAddUnitClick()} underline={false}>
          <SecondaryButton>{tComponents('addUnit')}</SecondaryButton>
        </Link>
      </Flex>
    </FormSection2>
  )
}
