import { deprecatedGenerateEventsDefinition } from '@guiker/event'

const basePaymentVerificationEvent = {
  context: 'PAYMENT_VERIFICATION',
  entity: 'PAYMENT_VERIFICATION',
  data: null as unknown as any,
}

export const PaymentVerificationEventsDefinition = deprecatedGenerateEventsDefinition({
  ...basePaymentVerificationEvent,
  eventTypes: {
    CONNECTION_TOKEN_EXPIRED: 'CONNECTION_TOKEN_EXPIRED',
  } as const,
})

export const Events = PaymentVerificationEventsDefinition.events
export const EventTypes = PaymentVerificationEventsDefinition.eventTypes
export type PaymentVerificationEventTypes = typeof EventTypes
