import React from 'react'

import { TextField } from '@guiker/react-framework'

type DescriptionProps = {
  defaultValue: string
  readOnly: boolean
}

const Description: React.FC<DescriptionProps> = ({ defaultValue, readOnly }) => {
  return <TextField name='description' defaultValue={defaultValue} readOnly={readOnly} multiline rowsMax={6} rows={6} />
}

export { Description }
