import { deprecatedGenerateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { InvestorProfile } from '../../entity'
import { baseEvent, BaseEventTypes } from '../base'

const baseInvestorProfileEvent = {
  ...baseEvent,
  entity: 'INVESTOR_PROFILE',
  data: null as unknown as InvestorProfile,
} as const

export enum InvestorProfileEventTypes {
  APPROVED = 'APPROVED',
  EMPLOYMENT_INFORMATION_UPDATED = 'EMPLOYMENT_INFORMATION_UPDATED',
  FINANCIAL_INFORMATION_UPDATED = 'FINANCIAL_INFORMATION_UPDATED',
  FINANCIAL_INFORMATION_TAX_ID_NUMBER_UPDATED = 'FINANCIAL_INFORMATION_TAX_ID_NUMBER_UPDATED',
  IDENTITY_VERIFICATION_INFORMATION_UPDATED = 'IDENTITY_VERIFICATION_INFORMATION_UPDATED',
  IDENTITY_VERIFICATION_INFORMATION_VERIFIED = 'IDENTITY_VERIFICATION_INFORMATION_VERIFIED',
  IDENTITY_VERIFICATION_INFORMATION_INCONCLUSIVE = 'IDENTITY_VERIFICATION_INFORMATION_INCONCLUSIVE',
  IDENTITY_VERIFICATION_INFORMATION_FRAUD_DETECTED = 'IDENTITY_VERIFICATION_INFORMATION_FRAUD_DETECTED',
  INFORMATION_COMPLETED = 'INFORMATION_COMPLETED',
  PERSONAL_INFORMATION_UPDATED = 'PERSONAL_INFORMATION_UPDATED',
  REJECTED = 'REJECTED',
  SERVICE_AGREEMENT_AGREED = 'SERVICE_AGREEMENT_AGREED',
  SERVICE_AGREEMENT_UPDATED = 'SERVICE_AGREEMENT_UPDATED',
  PAY_IN_METHOD_UPDATED = 'PAY_IN_METHOD_UPDATED',
  TAX_INFORMATION_ADDED = 'TAX_INFORMATION_ADDED',
}

export const InvestorProfileEventsDefinition = deprecatedGenerateEventsDefinition({
  ...baseInvestorProfileEvent,
  eventTypes: InvestorProfileEventTypes,
})

export const InvestorProfileEvents = generateEventsFromEnum(baseInvestorProfileEvent)({
  ...InvestorProfileEventTypes,
  ...BaseEventTypes,
})
