import { generateEventsDefinition } from '@guiker/event'

import { FundingAccountDeposit, FundingAccountDepositWriteModel } from '../../entity'
import { baseEvent } from '../base'

export const { FundingAccountDepositEventsDefinition } = generateEventsDefinition({
  ...baseEvent,
  entity: 'FUNDING_ACCOUNT_DEPOSIT',
  data: null as FundingAccountDeposit,
  eventTypes: {
    PROCESSED: 'PROCESSED',
    FAILED: 'FAILED',
    PAYMENT_SETTLED: 'PAYMENT_SETTLED',
    COMPLETED: 'COMPLETED',
    JOURNAL_ENTRY_INSERTION_FAILED: 'JOURNAL_ENTRY_INSERTION_FAILED',
  } as const,
  write: {
    data: null as FundingAccountDepositWriteModel,
  },
})
