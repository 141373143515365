import React from 'react'

import { FormSection2 } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { CalculationItemInput } from '../CalculationItem'

type FinancialNonOperatingFormContentProps = {}

export const FinancialNonOperatingFormContent: React.FC<FinancialNonOperatingFormContentProps> = () => {
  return (
    <>
      <CalculationItemInput
        name='financialInfo.financials.nonOperating.expenses.assetManagementFee'
        resultFrequency={'yearly'}
      />
      <CalculationItemInput
        name='financialInfo.financials.nonOperating.expenses.cashReserve'
        resultFrequency={'yearly'}
      />
      <CalculationItemInput
        name='financialInfo.financials.nonOperating.expenses.propertyImprovementReserve'
        resultFrequency={'yearly'}
      />
    </>
  )
}

export const FinancialNonOperatingFormSection: React.FC<FinancialNonOperatingFormContentProps> = () => {
  const { tComponents } = useT({ componentName: 'FinancialNonOperatingFormSection' })

  return (
    <FormSection2 title={tComponents('title')} subtitle={tComponents('description')}>
      <FinancialNonOperatingFormContent />
    </FormSection2>
  )
}
