import { DateTime } from 'luxon'
import * as yup from 'yup'

export { yup }

export const byScope = yup.object({
  scopeType: yup.string().required(),
  scopeId: yup.string().required(),
})

//prettier-ignore
export type TypeOf<T extends yup.Schema<unknown>> =
  T extends yup.Schema<infer P> ?
  P :
  never

export function validateSync<T>(schema: yup.Schema<T>, value: unknown): T {
  return schema.validateSync(value, {
    /**
     * Remove unspecified properties.
     * For example, sensitive information or implementation details.
     */
    stripUnknown: true,
  })
}

export function validate<T>(schema: yup.Schema<T>, value: unknown): yup.InferType<typeof schema> {
  return schema.validate(value, { stripUnknown: true }) as yup.InferType<typeof schema>
}

/**
 * A safer version of `yup.Schema.validateSync`
 */
export function validateExpectedSync<T>(schema: yup.Schema<T>, value: T): T {
  return validateSync(schema, value)
}

function validateUnion<U extends readonly yup.Schema<unknown>[], IsAsync extends boolean>(
  isAsync: IsAsync,
  ...schemas: U
) {
  type ReturnType = IsAsync extends true ? Promise<TypeOf<U[number]>> : TypeOf<U[number]>
  return (value: unknown): ReturnType => {
    const errors: string[] = []
    for (const schema of schemas) {
      try {
        return isAsync ? (validate(schema, value) as ReturnType) : (validateExpectedSync(schema, value) as ReturnType)
      } catch (err) {
        errors.push(err.message)
      }
    }
    throw new yup.ValidationError(errors.join(' | '), value, '')
  }
}

export function validateUnionSync<U extends readonly yup.Schema<unknown>[]>(
  ...schemas: U
): (value: unknown) => TypeOf<U[number]> {
  return validateUnion(false, ...schemas)
}

export function validateUnionAsync<U extends readonly yup.Schema<unknown>[]>(
  ...schemas: U
): (value: unknown) => Promise<TypeOf<U[number]>> {
  return validateUnion(true, ...schemas)
}

export const booleanFalseByDefault = yup.boolean().nullable().default(false)

export const booleanTrue = yup.boolean().nullable().default(false).oneOf([true], 'required').required()

export type PossibleRequiredSchema = yup.StringSchema | yup.ObjectSchema | yup.NumberSchema | yup.MixedSchema

export const conditionallyRequiredSchema = <T extends PossibleRequiredSchema>(schema: T, required: boolean): T => {
  return (required ? schema.required() : schema.nullable()) as T
}

export const ageVerification = ({ age = 18, date }: { age?: number; date: string }) => {
  const maxDate = DateTime.local().minus({ years: age })
  const birthdate = DateTime.fromISO(date)
  return birthdate <= maxDate
}

export const enumLike = <Values extends string>(e: Record<string, Values>) =>
  yup.mixed<Values>().oneOf(Object.values(e))

export const stringLiteral = <Value extends string>(s: Value) => yup.mixed<Value>().oneOf([s])

export const stringDate = () => yup.date() as unknown as yup.StringSchema

export const enumArrayLike = <Values extends string>(e: Record<string, Values>) =>
  yup.array().of<Values>(enumLike(e)).nullable()
