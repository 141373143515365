import { PropertyType } from '@guiker/real-estate-shared'
import { TypeOf, yup } from '@guiker/shared-framework'

const types = Object.values(PropertyType)

export const updateProperty = yup
  .object({
    property: yup.object({
      type: yup.string().oneOf(types).required(),
    }),
  })
  .required()

export type UpdateProperty = TypeOf<typeof updateProperty>
