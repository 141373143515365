import React from 'react'

import { computePercentageValue, FinancialInfo } from '@guiker/base-entity'
import {
  CLCurrencyTextField,
  Dropdown,
  numberFormatter,
  useGetInputPropsWithUseT,
  useWatch,
} from '@guiker/react-framework'
import { PercentNumberFormat } from '@guiker/rhf-components'

import { useFinancialInfoContext } from '../../context'
import { useT } from '../../i18n'

export type PercentageValueProps = {
  name: string
  label?: string
  base?: FinancialInfo.PercentageBase
  resultFrequency?: 'yearly' | 'monthly'
}

export const PercentageValueInput: React.FC<PercentageValueProps> = ({ base, name, label, resultFrequency }) => {
  const { tShared: tSharedCalculationItem } = useT({ entity: 'calculationItem' })
  const { tShared } = useT({ entity: 'calculationItem.percentage' })
  const { financials, currency } = useFinancialInfoContext()

  const getInputProps = useGetInputPropsWithUseT<{
    [key in typeof name]: FinancialInfo.CalculationItem<FinancialInfo.PercentageValue>
  }>()({
    t: tShared,
    formPrefix: name,
  })

  const { value } = useWatch({
    name: name,
  }) as FinancialInfo.CalculationItem<FinancialInfo.PercentageValue>

  return (
    <>
      <PercentNumberFormat
        {...getInputProps('value.rate')}
        condensed
        label={label || tShared('rate')}
        defaultValue={0}
      />
      <Dropdown
        {...getInputProps('value.base')}
        options={Object.values(FinancialInfo.PercentageBase).map((type) => ({
          label: tShared(`base.options.${type}`),
          value: type,
        }))}
        label={tShared('base.label')}
        condensed
        defaultValue={base || 'rentalIncome'}
        readOnly={!!base}
        menuMaxWidth={200}
      />
      <CLCurrencyTextField
        readOnly={true}
        label={'Computed'}
        condensed
        formatter={numberFormatter.currency(currency)}
        value={computePercentageValue({ financials, percentageValue: { value } }) || 0}
        endAdornment={resultFrequency && tSharedCalculationItem(`frequency.${resultFrequency}`)}
      />
    </>
  )
}
