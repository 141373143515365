import { Jurisdiction as OttawaJurisdiction, Ottawa } from './ottawa'
import { Jurisdiction as TorontoJurisdiction, Toronto } from './toronto'

export type Jurisdiction = 'ontario'
export type OntarioJurisdiction = Jurisdiction | OttawaJurisdiction | TorontoJurisdiction

export * from './operation-cost'
export * from './purchase-cost'
export * from './purchase-taxes'

export { Ottawa, Toronto }
