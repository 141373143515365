import { FinancialInfo } from '@guiker/base-entity'
import { CurrencyISO, enumLike, stringDate, TypeOf, yup } from '@guiker/shared-framework'

export const updateSeriesFinancialInfoRental = yup
  .object({
    series: yup.object({
      financialInfo: yup.object({
        occupancies: yup.array<FinancialInfo.Occupancy>().of(
          yup
            .object({
              unit: yup.string().required(),
              leases: yup
                .array()
                .of(
                  yup
                    .object({
                      price: yup.object({
                        amount: yup.number().required(),
                        currency: enumLike(CurrencyISO).required(),
                      }),
                      period: yup
                        .object({
                          from: stringDate(),
                          to: stringDate(),
                        })
                        .nullable(),
                    })
                    .required(),
                )
                .min(1)
                .required(),
            })
            .required(),
        ),
      }),
    }),
  })
  .required()

export type UpdateSeriesFinancialInfoRental = TypeOf<typeof updateSeriesFinancialInfoRental>
