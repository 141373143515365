import { EntityDocument, SequentialId } from '@guiker/base-entity'

import { Orientation } from './orientation'
import { OtherConfigurations } from './other-configurations'
import { Parking } from './parking'
import { Picture } from './picture'
import { Room } from './room'
import { FloorPlan, RoomConfigurations } from './room-configurations'
import { UnitAddress } from './unit-address'

export type Unit = EntityDocument & {
  type?: UnitType
  uniqueKey?: string
  propertyId: string
  buildingId: string
  address: UnitAddress
  squareFootage: number
  floorLevel: number
  orientation?: Orientation
  roomConfigurations: RoomConfigurations
  otherConfigurations: OtherConfigurations
}

export enum UnitType {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
}

export enum UnitAmenities {
  BACKYARD = 'BACKYARD',
  BALCONY = 'BALCONY',
  CAT_ALLOWED = 'CAT_ALLOWED',
  DISH_WASHER = 'DISH_WASHER',
  DOG_ALLOWED = 'DOG_ALLOWED',
  DRYER_LAUNDRY = 'DRYER_LAUNDRY',
  FURNITURE = 'FURNITURE',
  LOCKER = 'LOCKER',
  OVEN = 'OVEN',
  REFRIGERATOR = 'REFRIGERATOR',
  STOVE = 'STOVE',
  TV = 'TV',
  WASHER_LAUNDRY = 'WASHER_LAUNDRY',
}

export enum Utilities {
  WIFI = 'WIFI',
  HEATING = 'HEATING',
  AIR_CONDITIONER = 'AIR_CONDITIONER',
  WATER = 'WATER',
  ELECTRICITY = 'ELECTRICITY',
}

export type NEWUnit = SequentialId & {
  id: string
  type: UnitType
  suite?: string //but validate as only numeric (ie: 15)
  streetNumber?: string
  uuid?: string
  label?: string // if suite isn't just numeric, it would be stored here (ie. "15B")
  building: {
    id: string
  }
  property?: {
    id: string
  }
  floorLevel: number
  floorplan: FloorPlan
  squareFootage?: number
  orientation?: Orientation
  rooms?: Room[]
  amenities?: UnitAmenities[]
  utilities?: Utilities[]
  parkings?: Parking[]
  pictures?: Picture[]
}
