import { routeBuilderHelper, routesBuilder } from '@guiker/shared-framework'

import { StockOrder, StockOrderStatus, StockOrderWriteModel } from '../../../../../entity'
import { paths } from '../../../paths'

const { buildGet } = routesBuilder<StockOrder, StockOrderWriteModel>()({
  basePath: paths.investorProfiles.withId.stockOrders.path,
})

export const routes = {
  readAllInvestorProfileStockOrders: buildGet(routeBuilderHelper.withStatus(Object.values(StockOrderStatus))),
}
