import React from 'react'

import {
  Dropdown,
  FormSection2,
  FormSection3,
  Link,
  makeStyles,
  NumericInput,
  PlusSmallIcon,
  SecondaryButton,
  TextField,
  theme,
  TwoColumnsGridLayout,
  useFieldArray,
  useGetInputPropsWithUseT,
  useWatch,
} from '@guiker/react-framework'
import { BuildingTypes, NEWProperty, Orientation, UnitAmenities, UnitType } from '@guiker/real-estate-shared'
import { CheckboxGroup } from '@guiker/rhf-components'

import { useT } from '../../i18n'
import { ParkingComponent } from './Parking'

type UnitComponentProps = {
  readOnly?: boolean
  name: `property.units.${number}`
  onRemove: () => void
  canRemove: boolean
}

const useStyle = makeStyles({
  section: {
    marginTop: theme.spacing(3),
  },
})

export const UnitComponent: React.FC<UnitComponentProps> = ({ name, onRemove, canRemove = true }) => {
  const { tShared, tComponents, tBase } = useT({ componentName: 'PropertyUnitsFormContent', entity: 'unit' })
  const { tShared: tSharedOrientation } = useT({ entity: 'orientation' })
  const property = useWatch({ name: 'property' })

  const {
    fields: parkingFields,
    append,
    remove,
  } = useFieldArray({
    name: `${name}.parkings`,
  })

  const classes = useStyle()

  const getInputProps = useGetInputPropsWithUseT<{ property: NEWProperty }>()({
    t: tShared,
    formPrefix: name,
  })

  const enabledStreetNumberBuildingTypes = [BuildingTypes.MIXED, BuildingTypes.PLEX]
  const enabledUnitNumberBuildingTypes = [
    BuildingTypes.MIXED,
    BuildingTypes.COMMERCIAL,
    BuildingTypes.CONDO,
    BuildingTypes.SINGLE_FAMILY,
  ]

  return (
    <FormSection2 hasDivider={false}>
      <TwoColumnsGridLayout className={classes.section}>
        <Dropdown
          {...getInputProps('type', { i18nKey: 'type.label' })}
          options={Object.keys(UnitType).map((type) => ({
            value: UnitType[type],
            label: tShared(`type.options.${type}`),
          }))}
        />
      </TwoColumnsGridLayout>
      <FormSection3 title={tComponents('unitAddress')}>
        <TwoColumnsGridLayout>
          {enabledStreetNumberBuildingTypes.includes(property?.building?.type) && (
            <TextField {...getInputProps('streetNumber')} label={tBase('address.streetNumber')} />
          )}
          {enabledUnitNumberBuildingTypes.includes(property?.building?.type) && (
            <TextField {...getInputProps('suite')} label={tBase('address.unitNumber')} />
          )}
          <NumericInput {...getInputProps('floorLevel')} min={0} />
          <Dropdown
            {...getInputProps('orientation')}
            label={tSharedOrientation('label')}
            options={Object.keys(Orientation).map((direction) => ({
              value: Orientation[direction],
              label: tSharedOrientation(`options.${direction}`),
            }))}
          />
        </TwoColumnsGridLayout>
      </FormSection3>
      <FormSection3 title={tComponents('unitRooms')}>
        <TwoColumnsGridLayout>
          <TextField {...getInputProps('squareFootage')} type='number' />
          <div />
          <NumericInput {...getInputProps('floorplan.bedroom')} min={0} />
          <NumericInput {...getInputProps('floorplan.den')} min={0} />
          <NumericInput {...getInputProps('floorplan.bathroom')} min={0} />
          <NumericInput {...getInputProps('floorplan.halfBathroom')} min={0} />
          <NumericInput {...getInputProps('floorplan.kitchen')} min={0} />
        </TwoColumnsGridLayout>
      </FormSection3>
      <FormSection3 title={tComponents('parkings.label')}>
        {parkingFields.map((item, index) => (
          <ParkingComponent name={`${name}.parkings.${index}`} key={item.id} onRemove={() => remove(index)} />
        ))}
        {parkingFields.length < 3 && (
          <Link onClick={() => append({})} underline={false} adornment={<PlusSmallIcon />}>
            {tComponents('parkings.addParking')}
          </Link>
        )}
      </FormSection3>
      <CheckboxGroup
        {...getInputProps('amenities')}
        label={tComponents('amenities.label')}
        options={Object.keys(UnitAmenities).map((amenity) => ({
          value: amenity,
          label: tShared(`amenities.options.${amenity}`),
        }))}
      />
      {canRemove && (
        <Link onClick={onRemove} underline={false}>
          <SecondaryButton>{tComponents('removeUnit')}</SecondaryButton>
        </Link>
      )}
    </FormSection2>
  )
}
