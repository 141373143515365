import { CurrencyISO } from '@guiker/money'

import { CommonOperationCost } from '../operation-cost'

export const CanadaOperationCost = {
  ...CommonOperationCost,
  condoFee: {
    jurisdiction: 'canada',
    type: 'condoFee',
    frequency: 'monthly',
    value: {
      type: 'flat',
      amount: {
        value: 0,
        currency: CurrencyISO.CAD,
      },
    },
  },
  insurance: {
    jurisdiction: 'canada',
    type: 'insurance',
    frequency: 'yearly',
    value: {
      type: 'flat',
      amount: {
        currency: CurrencyISO.CAD,
        value: 100000,
      },
    },
  },
  utility: {
    jurisdiction: 'canada',
    type: 'utility',
    frequency: 'yearly',
    value: {
      type: 'flat',
      amount: {
        value: 50000,
        currency: CurrencyISO.CAD,
      },
    },
  },
  other: {
    jurisdiction: 'canada',
    type: 'other',
    frequency: 'yearly',
    value: {
      type: 'flat',
      amount: {
        value: 0,
        currency: CurrencyISO.CAD,
      },
    },
  },
} as const
