import React, { createContext, PropsWithChildren } from 'react'

import { generateUseContext, useQuery } from '@guiker/react-framework'
import { NEWProperty, ProjectWithDeveloper } from '@guiker/real-estate-shared'
import { Currency } from '@guiker/shared-framework'

import { usePublicRealEstateApiClient } from '../../hooks'
import { FinancialInfoContextProvider } from '../FinancialInfoContext'

type PropertyContext = {
  property: NEWProperty
  project: ProjectWithDeveloper
}

type PropertyContextProviderProps = PropsWithChildren & {
  currency: Currency
  property: NEWProperty
}

export const PropertyContext = createContext<PropertyContext>(null)

export const PropertyContextProvider: React.FC<PropertyContextProviderProps> = ({ children, currency, property }) => {
  const realEstateApiClient = usePublicRealEstateApiClient()

  const { data: project } = useQuery(
    `project-${property.id}`,
    (_) =>
      realEstateApiClient.readOneProject({
        pathParams: {
          id: property?.building?.construction?.project?.id,
        },
      }),
    {
      retry: 1,
      enabled: !!property?.building?.construction?.project?.id,
    },
  )

  const value = {
    project,
    property,
    currency,
  }

  return (
    <PropertyContext.Provider value={value}>
      <FinancialInfoContextProvider financials={property.financialInfo.financials} currency={currency}>
        {children}
      </FinancialInfoContextProvider>
    </PropertyContext.Provider>
  )
}

export const usePropertyContext = generateUseContext(PropertyContext)
