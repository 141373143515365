import { PhoneNumber } from '@guiker/base-entity'

import { ListingInquiry } from './listing-inquiry'

export enum RentalInquiryStatus {
  CREATED = 'CREATED',
  COMPLETED = 'COMPLETED',
  DROPPED = 'DROPPED',
}
export enum RentalInquiryQualificationStatus {
  PROCESSING = 'PROCESSING',
  QUALIFIED = 'QUALIFIED',
  DISQUALIFIED = 'DISQUALIFIED',
}

export type RentalInquiry = {
  id: string
  user: {
    id: string
    emailAddress: string
    firstName: string
    lastName?: string
    phone?: PhoneNumber
  }
  listingInquiries: Omit<ListingInquiry, 'user' | 'booking'>[]
  status: RentalInquiryStatus
  qualification: {
    status: RentalInquiryQualificationStatus
    note?: string
  }
}
