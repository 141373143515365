import React from 'react'

import { Flex } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { CommercialIcon, ResidentialIcon, SquareFootageIcon } from '@guiker/icons'
import { NEWProperty, NEWUnit, UnitType } from '@guiker/real-estate-shared'

import { findTotalNumberOfUnitType, findTotalSquareFootage } from '../../utils'
import { IconDescription } from './iconDescription'
import { findBuildingIcon } from './utils'

type MixedUnitConfigProp = {
  units: NEWUnit[]
  property: NEWProperty
}

export const MixedUnitConfig: React.FC<MixedUnitConfigProp> = ({ units, property }) => {
  const { t } = useTranslation('common-realEstate')

  const buildingType = property.building.type
  const BuildingIcon = findBuildingIcon(buildingType)
  const totalSquareFootage = findTotalSquareFootage(units)
  const totalResidentialUnits = findTotalNumberOfUnitType(units, UnitType.RESIDENTIAL)
  const totalCommercialUnits = findTotalNumberOfUnitType(units, UnitType.COMMERCIAL)

  return (
    <Flex padding={2} justifyContent='space-evenly' width='100%' flexWrap='wrap' gap={3}>
      <IconDescription icon={<BuildingIcon />} description={t(`unitConfiguration.building.${buildingType}`)} />
      <IconDescription
        icon={<ResidentialIcon />}
        description={t('unitConfiguration.residential', { count: totalResidentialUnits })}
        value={totalResidentialUnits.toLocaleString()}
      />
      <IconDescription
        icon={<CommercialIcon />}
        description={t('unitConfiguration.commercial', { count: totalCommercialUnits })}
        value={totalCommercialUnits.toLocaleString()}
      />
      <IconDescription
        icon={<SquareFootageIcon />}
        description={t('unitConfiguration.squareFootage')}
        value={totalSquareFootage.toLocaleString()}
      />
    </Flex>
  )
}
