import { deprecatedGenerateEventsDefinition } from '@guiker/event'
import { ValueOf } from '@guiker/ts-utils'

import { BlankContract } from '../../entity'
import { baseEvent } from '../base'

export const BlankContractEventsDefinition = deprecatedGenerateEventsDefinition({
  ...baseEvent,
  entity: 'BLANK_CONTRACT',
  data: null as BlankContract,
  eventTypes: {} as const,
})

export const { eventTypes: BlankContractEventTypes, events: BlankContractEvents } = BlankContractEventsDefinition
export type BlankContractEventTypes = ValueOf<typeof BlankContractEventTypes>
