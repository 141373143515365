import { CalculationItem, FlatValue, PercentageValue } from './calculation-item'
import { Canada, UnitedStates } from './jurisdiction'

export type Operation = {
  revenues: {
    rentalIncome: CalculationItem<FlatValue>
    vacancyRate: CalculationItem<PercentageValue>
  }
  expenses: {
    costs: {
      condoFee: CalculationItem
      insurance: CalculationItem
      utility: CalculationItem
      managementFee: CalculationItem
      repairAllowance: CalculationItem
      others?: CalculationItem
    }
    taxes: Record<string, CalculationItem>
  }
}

export const OperationConfiguration = {
  'toronto-on': Canada.Ontario.Toronto.operation,
  'ottawa-on': Canada.Ontario.Ottawa.operation,
  'gatineau-qc': Canada.Quebec.Gatineau.operation,
  'montreal-qc': Canada.Quebec.Montreal.operation,
  'quebec-city-qc': Canada.Quebec.QuebecCity.operation,
  'chicago-il': UnitedStates.Illinois.Chicago.operation,
} as const
