import { BracketValue, CalculationItem, FlatValue, FlatYearlyValue, PercentageValue } from '../../financial-info'

export const expenseValueTypeChecker = {
  isFlatValue: (expenseValue: CalculationItem): expenseValue is CalculationItem<FlatValue> => {
    return expenseValue?.value.type === 'flat'
  },
  isFlatYearlyValue: (expenseValue: CalculationItem): expenseValue is CalculationItem<FlatYearlyValue> => {
    return expenseValue?.value.type === 'flat-yearly'
  },
  isPercentageValue: (expenseValue: CalculationItem): expenseValue is CalculationItem<PercentageValue> => {
    return expenseValue?.value.type === 'percentage'
  },
  isBracketValue: (expenseValue: CalculationItem): expenseValue is CalculationItem<BracketValue> => {
    return expenseValue?.value.type === 'bracket'
  },
}
