import React from 'react'

import { computeBracketValue, FinancialInfo } from '@guiker/base-entity'
import {
  Box,
  CLCurrencyTextField,
  CLTextField,
  Dropdown,
  Flex,
  HiddenInput,
  InfoIcon,
  numberFormatter,
  PSmall,
  useGetInputPropsWithUseT,
  useWatch,
  WithTooltip,
} from '@guiker/react-framework'
import { money } from '@guiker/shared-framework'

import { useFinancialInfoContext } from '../../context'
import { useT } from '../../i18n'

export type BracketValueInputProps = {
  name: string
  label?: string
  base?: FinancialInfo.PercentageBase
}

export const BracketValueInput: React.FC<BracketValueInputProps> = ({ base, name }) => {
  const { financials, currency } = useFinancialInfoContext()
  const { tShared } = useT({ entity: 'calculationItem.bracket' })

  const getInputProps = useGetInputPropsWithUseT<{
    [key in typeof name]: FinancialInfo.CalculationItem<FinancialInfo.BracketValue>
  }>()({
    t: tShared,
    formPrefix: name,
  })

  const { value } = useWatch({
    name: name,
  }) as FinancialInfo.CalculationItem<FinancialInfo.BracketValue>

  const computedValue = computeBracketValue({ financials, bracketValue: { value } })

  const BracketTooltipContent = (
    <table>
      {value.brackets.map((bracket, index) => {
        const previousBracket = value.brackets[index - 1]

        const lowerRange = previousBracket ? previousBracket.threshold : 0

        const bracketString = !bracket.threshold
          ? `${money.fromPrice({ amount: lowerRange, currency: currency.iso }).toString({ noDecimal: true })}+`
          : `${money.fromPrice({ amount: lowerRange, currency: currency.iso }).toString({ noDecimal: true })} - ${money
              .fromPrice({ amount: bracket.threshold, currency: currency.iso })
              .toString({ noDecimal: true })}`

        return (
          <tr>
            <td>
              <PSmall color='white'>{bracketString}</PSmall>
            </td>
            <td>{`${numberFormatter.percentage(2).toDisplay(bracket.rate)}%`}</td>
          </tr>
        )
      })}
    </table>
  )

  return (
    <>
      <HiddenInput {...getInputProps('value.brackets')} />
      <Dropdown
        {...getInputProps('value.base')}
        options={Object.values(FinancialInfo.PercentageBase).map((type) => ({
          label: tShared(`base.options.${type}`),
          value: type,
        }))}
        defaultValue={value.base}
        label={tShared('base.label')}
        condensed
        readOnly={!!base}
        menuMaxWidth={200}
      />
      <Flex flexDirection='row' alignItems='flex-end' gap={2}>
        <CLTextField
          readOnly={true}
          label={tShared('effectiveRate')}
          condensed
          value={`${numberFormatter.percentage(2).toDisplay(computedValue.rate)}%`}
        />
        <WithTooltip title={BracketTooltipContent}>
          <Box mb={0.5}>
            <InfoIcon size='small' />
          </Box>
        </WithTooltip>
      </Flex>
      <CLCurrencyTextField
        readOnly={true}
        label={'Computed'}
        condensed
        formatter={numberFormatter.currency(currency)}
        value={computedValue.amount}
      />
    </>
  )
}
