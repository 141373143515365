import { deprecatedGenerateEventsDefinition } from '@guiker/event'

import { Ontario } from '../../entity'
import { eventTypes as LeaseEventTypes } from '../lease/event-types'
import { EventTypes as EventTypesEnum } from './event-types'

export const OntarioEventsDefinition = deprecatedGenerateEventsDefinition({
  context: 'LEASE',
  entity: 'LEASE',
  data: null as unknown as Ontario.Lease,
  eventTypes: { ...EventTypesEnum, ...LeaseEventTypes },
})

export const { eventTypes: OntarioEventTypes, events: OntarioEvents } = OntarioEventsDefinition
