import { money } from '@guiker/money'
import { math } from '@guiker/shared-framework'

import { useFinancialInfoContext } from '../../context'
import { useT } from '../../i18n'

export const buildKpiGrid = () => {
  const { tMain } = useT({ screenName: 'screens.listingScreen.tab1.sections.investmentPerformance.kpi' })
  const { results, financials, currency } = useFinancialInfoContext()
  const moneyFormatter = money.formatter(currency, 'onlySymbol', true)

  return [
    {
      label: tMain('firstYearCashflow'),
      description: moneyFormatter(money.fromAmount(results.yearlyCashflows[0].netCashFlow, currency).monetized),
    },
    {
      label: tMain('irr'),
      description: `${math.decimal.round(results.irr * 100, 2)}%`,
      tip: tMain('irrTip'),
    },
    {
      label: tMain('estimatedSalePrice'),
      description: moneyFormatter(money.fromAmount(results.estimatedSalesPrice, currency).monetized),
    },
    {
      label: tMain('holdingPeriod'),
      unit: tMain('year'),
      description: `${financials.exit.holdingPeriod}`,
    },
  ]
}
