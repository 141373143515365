import React from 'react'

import { FundingAccountWithdrawal } from '@guiker/payment-shared'
import { Table, TableProps } from '@guiker/react-framework'

import { useGetColumns } from './get-columns'

type UserFundingAccountWithdrawalsTableProps = {
  withdrawals: FundingAccountWithdrawal[]
  emptyState?: TableProps<unknown>['emptyState']
}

export const UserFundingAccountWithdrawalsTable: React.FC<UserFundingAccountWithdrawalsTableProps> = ({
  withdrawals,
  emptyState,
}) => {
  const columns = useGetColumns({
    columns: ['payoutMethod.id', 'amount', 'status', 'createdAt'],
    callbackArgs: { showNote: false },
  })

  return <Table data={withdrawals} columns={columns} emptyState={emptyState} />
}
