import { FinancialInfo } from '@guiker/base-entity'
import { getGraphColorKey, numberFormatter } from '@guiker/react-framework'
import { NEWProperty } from '@guiker/real-estate-shared'
import { Currency, math, money } from '@guiker/shared-framework'
export * from './financials'
export * from './use-get-input-props'
export * from './use-watch'
export * from './unitConfiguration'

import { useT } from '../i18n'

export type MinMax = {
  min: number
  max: number
}

export const findMinAndMax = (value: number, amount: number) => {
  return {
    min: value - value * amount,
    max: value + value * amount,
  }
}

export const findMinAndMaxWithRange = (value: number, amount: number, range: number) => {
  const min = value - value * amount
  const minRange = value - range < 0 ? 0 : value - range
  const max = value + value * amount
  const maxRange = value + range
  return {
    min: min < minRange ? math.decimal.round(min, 2) : math.decimal.round(minRange, 2),
    max: max > maxRange ? math.decimal.round(max, 2) : math.decimal.round(maxRange, 2),
  }
}

export const useRentalDetails = (args: {
  yearlyCashflows: FinancialInfo.YearlyCashflow
  currency: Currency
  property: NEWProperty
}) => {
  const { tShared } = useT({ entity: 'rentalDetails' })
  const { currency, yearlyCashflows, property } = args
  const { financials } = property.financialInfo
  const rentalIncome = financials.operation.revenues.rentalIncome.value

  const operatingExpenses = yearlyCashflows.operatingExpenses.filter((e) => e.computedValue !== 0)
  const nonOperatingExpenses = yearlyCashflows.nonOperatingExpenses.filter((e) => e.computedValue !== 0)
  const nonOperatingExpensesAmount = money.fromAmount(
    nonOperatingExpenses.reduce((t, e) => t + e.computedValue, 0),
    currency,
  )
  const totalExpenses = money.fromAmount(
    operatingExpenses.reduce((t, e) => t + e.computedValue, 0),
    currency,
  )

  return {
    yearlyCashflows,
    currency,
    totalExpenses,
    kpiItems: [
      {
        label: tShared('kpi.rentalStatus'),
        description: tShared('kpi.partiallyRented'),
        // description:
        //   occupancies.length === property.units.length && occupancies?.every((o) => o.isOccupied)
        //     ? tShared('kpi.fullyRented')
        //     : !isEstimatedRevenue
        //     ? tShared('kpi.partiallyRented')
        //     : tShared('kpi.vacant'),
      },
      {
        label: tShared('kpi.rentalIncome'),
        description: money.fromAmount(rentalIncome.amount, currency).toString('onlySymbol', true),
        // unit: isEstimatedRevenue ? t('common-investmentAssumption:revenue.occupancy.monthlyRent.estimated') : undefined,
      },
      {
        label: tShared('kpi.rentalGrowthRate'),
        description: `${numberFormatter.percentage(2).toDisplay(rentalIncome.yearlyGrowthRate)} %`,
      },
    ],
    financialItems: [
      {
        label: tShared('cashFlow.rentalIncome'),
        value: yearlyCashflows.rentalIncome,
      },
      {
        label: tShared('cashFlow.operatingExpenses'),
        value: -totalExpenses.amount,
        collapsibleItems: operatingExpenses.map((cost) => ({
          label: tShared(`cashFlow.${cost.type}`),
          value: cost.computedValue,
        })),
      },
      {
        label: tShared('cashFlow.netOperatingIncome'),
        value: yearlyCashflows.netOperatingIncome,
      },
      {
        label: tShared('cashFlow.debtService'),
        value: yearlyCashflows.totalDebtService,
        collapsibleItems: [
          {
            label: tShared('cashFlow.principalPayment'),
            value: yearlyCashflows.debtService.principalPaidSum,
          },
          {
            label: tShared('cashFlow.interestPayment'),
            value: yearlyCashflows.debtService.interestPaidSum,
          },
        ],
      },
      {
        label: tShared('cashFlow.nonOperatingExpenses'),
        value: -nonOperatingExpensesAmount,
        collapsibleItems: nonOperatingExpenses.map((cost) => ({
          label: tShared(`cashFlow.nonOperatingExpenses.${cost.type}`),
          value: cost.computedValue,
        })),
      },
    ],
    pieChartItems: [
      ...operatingExpenses.map((cost, index) => ({
        key: cost.type,
        label: tShared(`cashFlow.${cost.type}`),
        value: money.fromAmount(cost.computedValue, currency).monetized,
        color: getGraphColorKey(index),
      })),
    ],
  }
}
