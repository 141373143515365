import { TypeOf, yup } from '@guiker/shared-framework'

import { Document, Picture } from '../../../entity'

const photoSchema = yup.object<Picture>({ id: yup.string().required(), label: yup.string().nullable() })
const documentSchema = yup.object<Document>({ id: yup.string().required(), displayName: yup.string().nullable() })
const photosSchema = yup.array().of(photoSchema)
const documentsSchema = yup.array().of(documentSchema)

export const updatePropertyDetails = yup
  .object({
    pictures: photosSchema.min(1).required(),
    documents: documentsSchema.optional(),
  })
  .required()

export type UpdatePropertyDetails = TypeOf<typeof updatePropertyDetails>
