import { addressFormatter } from '@guiker/base-entity'
import { BuildingTypes, Project } from '@guiker/real-estate-shared'

import { PropSharingListing } from '../entity'

export const getDisplayName = (listing: PropSharingListing, project?: Project) => {
  if (listing.stock.label) {
    return listing.stock.label
  }

  const property = listing?.stock.property

  const isPreCon = property?.building?.type === BuildingTypes.CONDO && !!project

  const streetNumber = property?.units[0]?.streetNumber ?? property?.building?.mailingAddress?.streetNumber
  const suite = property?.units[0]?.suite

  return isPreCon
    ? suite
      ? `${project.name} - ${suite}`
      : project.name
    : addressFormatter.printShortAddress({
        ...property?.building?.mailingAddress,
        postalCode: property?.building?.mailingAddress?.zipcode,
        suite: suite,
        streetNumber,
      })
}
