import { BracketValue, CalculationItem, FlatValue, PercentageValue } from './calculation-item'
import { Canada, UnitedStates } from './jurisdiction'
import { CostArgs } from './utils'

export type Purchase = {
  price: CalculationItem<FlatValue>
  assessedValue?: CalculationItem<FlatValue>
  costs: {
    initialRenovation?: CalculationItem
    cashReserve?: CalculationItem
    closingCost?: CalculationItem
    offeringCost?: CalculationItem
  }
  taxes: {
    HST?: CalculationItem<PercentageValue>
    QST?: CalculationItem<PercentageValue>
    GST?: CalculationItem<PercentageValue>
    welcomeTax?: CalculationItem<BracketValue>
  }
}

export const buildPurchaseConfiguration = (args: CostArgs) => ({
  'toronto-on': Canada.Ontario.Toronto.purchase(args),
  'ottawa-on': Canada.Ontario.Ottawa.purchase(args),
  'gatineau-qc': Canada.Quebec.Gatineau.purchase(args),
  'montreal-qc': Canada.Quebec.Montreal.purchase(args),
  'quebec-city-qc': Canada.Quebec.QuebecCity.purchase(args),
  'chicago-il': UnitedStates.Illinois.Chicago.purchase(args),
})

export type PurchaseConfiguration = ReturnType<typeof buildPurchaseConfiguration>
