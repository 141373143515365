import { routes as listingNestedRoutes } from './listings'
import { routes as portfolioNestedRoutes } from './portfolio'
import { routes as resourceRoutes } from './routes'
import { routes as stockOrdersNestedRoutes } from './stock-orders'

export const routes = {
  ...listingNestedRoutes,
  ...stockOrdersNestedRoutes,
  ...portfolioNestedRoutes,
  ...resourceRoutes,
} as const
