import { FinancialInfo } from '@guiker/base-entity'
import { enumLike, stringLiteral, yup } from '@guiker/shared-framework'

const percentBase = enumLike(FinancialInfo.PercentageBase)

export const percentageValueMethod = yup.object<FinancialInfo.PercentageValue>({
  type: stringLiteral(FinancialInfo.CalculationMethodTypes.percentage).required(),
  rate: yup.number().required(),
  base: percentBase.required(),
})

const flatValueMethod = yup.object<FinancialInfo.FlatValue>({
  type: stringLiteral(FinancialInfo.CalculationMethodTypes.flat).required(),
  amount: yup.number().required(),
  yearlyGrowthRate: yup.number(),
})

export const flatYearlyValueMethod = yup.object<FinancialInfo.FlatYearlyValue>({
  type: stringLiteral(FinancialInfo.CalculationMethodTypes.flatYearly).required(),
  amount: yup.array().of(yup.number().required()).required(),
})

export const bracketValueMethod = yup.object<FinancialInfo.BracketValue>({
  type: stringLiteral(FinancialInfo.CalculationMethodTypes.bracket).required(),
  base: percentBase.required(),
  brackets: yup.array().of(
    yup.object({
      rate: yup.number().required(),
      threshold: yup.number(),
    }),
  ),
})

export const flatValue = yup.object({ value: flatValueMethod.required() })
export const percentageValue = yup.object({ value: percentageValueMethod.required() })
export const bracketValue = yup.object({ value: bracketValueMethod.required() })
export const flatYearlyValue = yup.object({ value: flatYearlyValueMethod.required() })

export const calculationItem = yup.object({
  value: yup
    .object({
      type: enumLike(FinancialInfo.CalculationMethodTypes)
        .default(FinancialInfo.CalculationMethodTypes.flat)
        .required(),
    })
    .when('$type', (_, __, { value }) => {
      switch (value?.type) {
        case FinancialInfo.CalculationMethodTypes.percentage:
          return percentageValueMethod
        case FinancialInfo.CalculationMethodTypes.bracket:
          return bracketValueMethod
        case FinancialInfo.CalculationMethodTypes.flatYearly:
          return flatYearlyValueMethod
        case FinancialInfo.CalculationMethodTypes.flat:
        default:
          return flatValueMethod
      }
    })
    .required(),
}) as yup.ObjectSchema<FinancialInfo.CalculationItem>
