import React from 'react'

import { Flex, P, PBold, PSmall } from '@guiker/components-library'
import {
  computeInvoiceFees,
  Payout,
  Transfer,
  TransferScopeType,
  WithPayoutMethod,
  WithTransfers,
} from '@guiker/payment-shared'
import { money } from '@guiker/shared-framework'

import { useT } from '../../../i18n'
import { transferHelper } from '../../../utils'

type BreakdownProps = {
  payout: WithPayoutMethod<WithTransfers<Payout, Transfer<TransferScopeType.INVOICE>>>
}

export const RentalPayoutBreakdown: React.FC<BreakdownProps> = ({ payout }) => {
  const { tShared } = useT({})
  const { amount, currency, transfers } = payout
  const totalFeeAmount = transfers.reduce(
    (acc, transfer) => acc + (transferHelper.hasScopedData(transfer) ? computeInvoiceFees(transfer.scope.data) : 0),
    0,
  )
  const rentAmount = amount + totalFeeAmount

  return (
    <>
      <Flex justifyContent='space-between' maxWidth={500}>
        <Flex flexDirection='column' gap={1}>
          <P fontWeight={300} mb={0}>
            {tShared('breakdown.rent')}
          </P>
          <P fontWeight={300} mb={0}>
            {tShared('breakdown.fees')}
          </P>
        </Flex>
        <Flex flexDirection='column' gap={1}>
          <PBold mb={0} textAlign='right'>
            {money.fromAmount(rentAmount, currency).toString()}
          </PBold>
          <PSmall mb={0} textAlign='right'>
            {`(${money.fromAmount(totalFeeAmount, currency).toString()})`}
          </PSmall>
        </Flex>
      </Flex>
      <Flex justifyContent='space-between' maxWidth={500}>
        <PBold mb={0}>{tShared('breakdown.receivable')}</PBold>
        <PBold textAlign='right' mb={0}>
          {money.fromAmount(amount, currency).toString()}
        </PBold>
      </Flex>
    </>
  )
}
