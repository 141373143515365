export const QuebecOperationTaxes = {
  municipalTax: {
    value: {
      type: 'percentage',
      base: 'assessedValue',
      rate: 0,
    },
  },
  schoolTax: {
    value: {
      type: 'percentage',
      base: 'assessedValue',
      rate: 0,
    },
  },
} as const
