import React from 'react'

import { FinancialInfo } from '@guiker/base-entity'
import { ColumnGridLayout, Dropdown, Flex, Label, useLayoutContext, useWatch } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { BracketValueInput } from '../BracketValue'
import { FlatValueInput } from '../FlatValue'
import { PercentageValueInput } from '../PercentageValue'

const ALLOWED_TYPES = [
  FinancialInfo.CalculationMethodTypes.flat,
  FinancialInfo.CalculationMethodTypes.percentage,
] as const

export type CalculationItemInputProps = {
  base?: FinancialInfo.PercentageBase
  canChangeType?: boolean
  allowedTypes?: FinancialInfo.CalculationMethodTypes[]
  frequency?: 'yearly' | 'monthly'
  hideTypeDropdown?: boolean
  name: string
  withYearlyGrowthRate?: boolean
  resultFrequency?: 'yearly' | 'monthly'
}

export const CalculationItemInput: React.FC<CalculationItemInputProps> = ({
  name,
  base,
  canChangeType = true,
  hideTypeDropdown = false,
  allowedTypes = ALLOWED_TYPES,
  resultFrequency,
  withYearlyGrowthRate,
  frequency = 'yearly',
}) => {
  const { tShared } = useT({ entity: 'calculationItem.type' })
  const { tShared: tSharedItem } = useT({ entity: name })
  const { isLargeScreenDesktop } = useLayoutContext()
  const selectedType = useWatch({ name: `${name}.value.type` }) || FinancialInfo.CalculationMethodTypes.flat

  const comp = (
    <ColumnGridLayout
      gridTemplateColumns={5}
      alignItems='flex-end'
      xs={{ gridTemplateColumns: 1, gridColumn: { start: 'auto', span: 1 } }}
      sm={{ gridTemplateColumns: 3, gridColumn: { start: 'auto', span: 1 } }}
      md={{ gridTemplateColumns: 4, gridColumn: { start: 'auto', span: 1 } }}
      lg={{ gridTemplateColumns: 5, gridColumn: { start: 'auto', span: 1 } }}
    >
      {isLargeScreenDesktop && (
        <Flex height={40} alignSelf='flex-end' alignItems='center'>
          <Label text={tSharedItem()} />
        </Flex>
      )}

      {canChangeType && !hideTypeDropdown && (
        <Dropdown
          label={tShared('label')}
          name={`${name}.value.type`}
          options={allowedTypes.map((type) => ({ label: tShared(`options.${type}`), value: type }))}
          condensed
          menuMaxWidth={200}
        />
      )}
      {selectedType === FinancialInfo.CalculationMethodTypes.flat ? (
        <FlatValueInput name={name} withYearlyGrowthRate={withYearlyGrowthRate} frequency={frequency} />
      ) : selectedType === FinancialInfo.CalculationMethodTypes.percentage ? (
        <PercentageValueInput name={name} base={base} resultFrequency={resultFrequency} />
      ) : selectedType === FinancialInfo.CalculationMethodTypes.bracket ? (
        <BracketValueInput name={name} base={base} />
      ) : (
        <></>
      )}
    </ColumnGridLayout>
  )

  if (isLargeScreenDesktop) {
    return comp
  }
}
