import { FinancialInfo, TypeOf, yup } from '@guiker/shared-framework'

import { Deal, FinancialStatements, Series, SeriesStatus, SeriesType } from '../../../entity'
import { seriesDealSchema } from './series-deal'

export const updateSeriesAndGenerateTargetSchema = yup.object({
  series: yup.array().of(
    yup
      .object<{
        name?: string
        status?: SeriesStatus
        seriesType?: SeriesType
        startDate: string
        closeDate?: string
        pricePerUnit?: number
        financialInfo?: FinancialInfo.FinancialInfo
        latestFinancialStatement?: FinancialStatements
        target: {
          unitQuantity: number
          equity?: number
          deal?: Deal
        }
      }>({
        name: yup.string().nullable(),
        pricePerUnit: yup.number().nullable(),
        target: yup
          .object<Series['target']>({
            unitQuantity: yup.number().required(),
            equity: yup.number().nullable(),
            deal: seriesDealSchema.nullable(),
          })
          .required(),
        startDate: yup.string().required(),
        closeDate: yup.string().nullable(),
        status: yup
          .mixed<SeriesStatus>()
          .oneOf([SeriesStatus.COMING_SOON, SeriesStatus.OPEN])
          .nullable()
          .default(SeriesStatus.COMING_SOON),
        seriesType: yup.mixed<SeriesType>().oneOf(Object.values(SeriesType)).nullable(),
      })
      .required(),
  ),
})

export type UpdateSeriesAndGenerateTargetSchema = TypeOf<typeof updateSeriesAndGenerateTargetSchema>
