import { Payment } from '@guiker/base-entity'
import { deprecatedGenerateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { PayInMethod } from '../../entity'

export const BasePayInMethodEvent = {
  context: 'PAYMENT',
  data: null as unknown as Payment.PayInMethod,
} as const

export const legacyDefinition = deprecatedGenerateEventsDefinition({
  ...BasePayInMethodEvent,
  data: {
    payInMethodId: null as number,
  },
  entity: 'LEGACY_PAY_IN_METHOD',
  eventTypes: {
    CREATED: 'CREATED',
    DELETED: 'DELETED',
    VERIFICATION_TOKEN_REFRESHED: 'VERIFICATION_TOKEN_REFRESHED',
    ACTION_REQUIRED: 'ACTION_REQUIRED',
    ENABLED: 'ENABLED',
    DISABLED: {
      type: 'DISABLED',
      eventData: null as { resolveUrl: string },
    },
    DELETED_BY_FAILED_VERIFICATION: 'DELETED_BY_FAILED_VERIFICATION',
  } as const,
})

export const { events: PayInMethodLegacyEvents, eventTypes: legacyPayInMethodEventTypes } = legacyDefinition
export type LegacyPayInMethodEventTypes = ValueOfEventTypes<typeof definition>

export const definition = deprecatedGenerateEventsDefinition({
  ...BasePayInMethodEvent,
  entity: 'PAY_IN_METHOD',
  data: null as PayInMethod,
  eventTypes: {
    CREATED: 'CREATED',
    DELETED: 'DELETED',
    VERIFICATION_TOKEN_REFRESHED: 'VERIFICATION_TOKEN_REFRESHED',
    ENABLED: 'ENABLED',
    DISABLED: {
      type: 'DISABLED',
      eventData: null as { resolveUrl: string },
    },
    DELETED_BY_FAILED_VERIFICATION: 'DELETED_BY_FAILED_VERIFICATION',
  } as const,
})

export const { events: PayInMethodEvents, eventTypes: EventTypes } = definition
export type PayInMethodEventTypes = ValueOfEventTypes<typeof definition>
