import { CountByStatusMetadata, routesBuilder } from '@guiker/shared-framework'

import {
  FundingAccountWithdrawal,
  FundingAccountWithdrawalStatus,
  FundingAccountWithdrawalWriteModel,
} from '../../../../entity/funding-account'
import { paths } from '../../paths'
import { getStatusesSchemas } from '../../schemas/status-schema'
import { createPayoutWithdrawalSchema, searchStringSchema } from '../schemas'

const { buildGet, buildPost } = routesBuilder<FundingAccountWithdrawal, FundingAccountWithdrawalWriteModel>()({
  basePath: paths.fundingAccounts.withdrawals.path,
})

export const routes = {
  readAllWithdrawals: buildGet({
    paginated: true,
    queryParamsSchema: searchStringSchema.concat(getStatusesSchemas<FundingAccountWithdrawalStatus>()),
    additionalMeta: {} as CountByStatusMetadata<FundingAccountWithdrawalStatus>,
  }),
  exportWithdrawals: buildGet({
    path: '/export',
    responseIsArray: true,
    queryParamsSchema: searchStringSchema.concat(getStatusesSchemas<FundingAccountWithdrawalStatus>()),
  }),
  createPayoutWithdrawal: buildPost({
    payloadSchema: createPayoutWithdrawalSchema,
  }),
} as const
