import React from 'react'

import { DataAndMeta, Paginate } from '@guiker/api-definition'
import { PropSharingListing } from '@guiker/propsharing-shared'
import { PaginatedTable } from '@guiker/react-query-components'

import { useGetListingColumns } from './get-listing-columns'

type PropSharingTableProps = {
  queryKey: string
  fetcher: (paginate: Paginate) => Promise<DataAndMeta<PropSharingListing[]>>
  onRowClick: (data: PropSharingListing) => void
  search?: { label?: string }
}

export const PropSharingTable: React.FC<PropSharingTableProps> = ({ onRowClick, search, queryKey, fetcher }) => {
  return (
    <PaginatedTable
      queryKey={queryKey}
      fetcher={fetcher}
      columns={useGetListingColumns()}
      onRowClick={(data) => {
        onRowClick(data)
      }}
      search={search}
    />
  )
}
