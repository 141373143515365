import React from 'react'

import { P, useDateFormatter } from '@guiker/react-framework'

import { useT } from '../../../i18n'

type Props = {
  period: {
    from?: string
    to?: string
  }
}

export const RentPeriod: React.FC<Props> = ({ period }) => {
  const { tShared } = useT({ entity: 'revenue.occupancy.leaseTerm' })
  const { formatDate } = useDateFormatter()

  if (period.from && period.to) {
    return (
      <P mb={0}>
        {tShared('range', {
          from: formatDate(period.from),
          to: formatDate(period.to),
        })}
      </P>
    )
  } else if (period.from && !period.to) {
    return <P mb={0}>{tShared('since', { date: formatDate(period.from) })}</P>
  } else if (!period.from && period.to) {
    return <P mb={0}>{tShared('until', { date: formatDate(period.to) })}</P>
  } else {
    return <></>
  }
}
