import { CalculationItem, FlatValue } from '../../calculation-item'

export const PurchaseBase = {
  price: {
    value: {
      type: 'flat',
      amount: 0,
    },
  } as CalculationItem<FlatValue>,
  topup: {
    value: {
      type: 'flat',
      amount: 0,
    },
  } as CalculationItem<FlatValue>,
} as const
