import { buildWebAppRoutes } from '@guiker/shared-framework'

import { config } from '../config'

export const { routes: officeRoutes, pathBuilder: officePathBuilder } = buildWebAppRoutes(
  {
    root: {
      path: '/',
      routes: {
        overview: {
          path: '/overview',
        },
        investorProfiles: {
          path: '/investor-profiles',
          routes: {
            listInvestorProfiles: '/',
            byInvestorProfileId: {
              path: '/:investorProfileId',
              routes: {
                reviewInvestorProfile: '/review',
              },
            },
          },
        },
        stocks: {
          path: '/stocks',
          routes: {
            createStock: '/new',
            listStocks: '/',
            byStockId: {
              path: '/:stockId',
              routes: {
                viewStock: '/',
                editStock: '/edit',
                listings: '/listings',
                transactions: '/transactions',
                positions: '/positions',
                series: {
                  path: '/series',
                  routes: {
                    createSeries: '/new',
                    bySeriesSlug: {
                      path: '/:seriesSlug',
                      routes: {
                        editSeries: '/edit',
                      },
                    },
                  },
                },
                stockOrder: {
                  path: '/stockOrder',
                  routes: {
                    byStockOrderId: {
                      path: '/:stockOrderId',
                      routes: {
                        viewStockOrder: '/',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  } as const,
  config.domainName,
)
