import { slugifyLowercase } from '@guiker/lodash'
import { addressFormatter, DeepPartial } from '@guiker/shared-framework'

import { BuildingTypes, NEWProperty, Project, Unit, UnitType } from '../entity'

export const slugifyListingProperty = (property: DeepPartial<NEWProperty>) => {
  if (!property?.building) return ''
  const slugable: string[] = []
  const { construction, mailingAddress: address } = property.building

  if (construction?.developer?.name) {
    slugable.push(construction.developer.name)
  }

  if (construction?.project?.name) {
    slugable.push(construction.project.name)
  }

  slugable.push(`${address.streetNumber}-${address.street}`)
  return slugifyLowercase(slugable.join('-'))
}

export const findRepartitionOfUnits = (units: Unit[]) => {
  let commercial = 0
  let residential = 0
  units?.map((unit) => (unit.type === UnitType.RESIDENTIAL ? residential++ : commercial++))

  return { commercial, residential }
}

export const getDisplayName = (property: DeepPartial<NEWProperty>, project?: Project) => {
  const isPreCon = property?.building?.type === BuildingTypes.CONDO && !!project
  const unit = property.units?.[0]

  return isPreCon
    ? `${project.name} - ${unit.suite}`
    : addressFormatter.printShortAddress({
        ...property?.building?.mailingAddress,
        postalCode: property?.building?.mailingAddress?.zipcode,
        suite: unit.suite,
      })
}
