import React from 'react'

import { FinancialInfo } from '@guiker/base-entity'
import { Divider, Flex, makeStyles, PBold, theme } from '@guiker/react-framework'
import { NEWUnit, unitOccupancyHelper } from '@guiker/real-estate-shared'
import { CurrencyISO } from '@guiker/shared-framework'

import { RentalInfo, UnitConfiguration } from './BaseComponents'

const useStyles = makeStyles(() => ({
  negativeMargin: {
    marginTop: ({ isOccupied, hasRent }: { isOccupied: boolean; hasRent: boolean }) =>
      !isOccupied && hasRent ? theme.spacing(-2) : undefined,
  },
}))

type Prop = {
  unit: NEWUnit
  occupancies: FinancialInfo.Occupancy[]
  currency: CurrencyISO
  index: number
}

type MobileUnitBreakdownProps = {
  units: NEWUnit[]
  occupancies: FinancialInfo.Occupancy[]
  currency: CurrencyISO
}

const UnitBreakdown: React.FC<Prop> = ({ unit, occupancies, currency, index }) => {
  const { monthlyRent, isOccupied } = unitOccupancyHelper(unit, occupancies)
  const classes = useStyles({ isOccupied: !!isOccupied, hasRent: !!monthlyRent })
  return (
    <Flex width='100%' flexDirection='column'>
      {index !== 0 && <Divider my={2} />}
      <Flex justifyContent='space-between'>
        <PBold mb={0}>{unit.label}</PBold>
        {!!monthlyRent && <RentalInfo unit={unit} occupancies={occupancies} currency={currency} />}
      </Flex>
      <UnitConfiguration unit={unit} occupancies={occupancies} className={classes.negativeMargin} />
    </Flex>
  )
}

export const MobileUnitBreakdown: React.FC<MobileUnitBreakdownProps> = ({ units, occupancies, currency }) => {
  return (
    <Flex width='100%' flexDirection='column'>
      {units.map((unit, index) => (
        <UnitBreakdown unit={unit} index={index} currency={currency} occupancies={occupancies} />
      ))}
    </Flex>
  )
}
