import React from 'react'

import { Flex, theme, useMediaQuery } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { CommercialIcon, ResidentialIcon, UnitsIcon } from '@guiker/icons'
import { NEWProperty, UnitType } from '@guiker/real-estate-shared'

import { showBuildingOrientation, showTotalNumberOfUnits } from '../../utils'
import { IconDescription } from './iconDescription'
import { findBuildingIcon, hasTwoOrMoreAttributes } from './utils'

type WholeBuildingConfigProp = {
  property: NEWProperty
}

export const WholeBuildingConfig: React.FC<WholeBuildingConfigProp> = ({ property }) => {
  const { t } = useTranslation('common-realEstate')

  const buildingType = property.building?.type
  const BuildingIcon = findBuildingIcon(buildingType)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const residential = property.units.filter((u) => u?.type === UnitType.RESIDENTIAL).length
  const commercial = property.units.filter((u) => u?.type === UnitType.COMMERCIAL).length
  const showSeperatedUnits = residential > 0 && commercial > 0

  if (!hasTwoOrMoreAttributes(property)) return <></>

  return (
    <Flex
      padding={2}
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'flex-start' : 'center'}
      justifyContent={'space-evenly'}
      width='100%'
      flexWrap='wrap'
      gap={3}
    >
      {buildingType && (
        <IconDescription
          icon={<BuildingIcon />}
          description={t(`unitConfiguration.building.${buildingType}`)}
          value={''}
        />
      )}
      {showSeperatedUnits ? (
        <>
          <IconDescription
            icon={<ResidentialIcon />}
            description={t('unitConfiguration.residential', { count: residential })}
            value={residential?.toLocaleString()}
          />
          <IconDescription
            icon={<CommercialIcon />}
            description={t('unitConfiguration.commercial', { count: commercial })}
            value={commercial?.toLocaleString()}
          />
        </>
      ) : showTotalNumberOfUnits(property) ? (
        <IconDescription
          icon={<UnitsIcon />}
          description={t('unitConfiguration.unit', { count: property.building.configuration?.totalNumberOfUnits })}
          value={property.building.configuration?.totalNumberOfUnits?.toLocaleString()}
        />
      ) : undefined}
      {/* {showTotalSquareFootage(property) && (
        <IconDescription
          icon={<SquareFootageIcon />}
          description={t('unitConfiguration.squareFootage')}
          value={property.totalSquareFootage?.toLocaleString()}
        />
      )} */}
      {showBuildingOrientation(property.building) && (
        <IconDescription
          description={t('unitConfiguration.orientation')}
          value={t(`unitConfiguration.${property.building.configuration?.orientation}`)}
        />
      )}
    </Flex>
  )
}
