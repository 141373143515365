import React from 'react'

import { FundingAccountWithdrawal } from '@guiker/payment-shared'
import { Payout } from '@guiker/payout-app-components'
import { getColumnsBuilder, Link, PSmall, PSmaller, useDateFormatter } from '@guiker/react-framework'
import { getUserFullName, money } from '@guiker/shared-framework'

import { useT } from '../../i18n'
import { WithdrawalStatusChip } from '../WithdrawalStatusChip'

export const useGetColumns = getColumnsBuilder<FundingAccountWithdrawal>()((args?: { showNote?: boolean }) => {
  const { tShared, tBase } = useT({ entity: 'fundingAccount.withdrawal' })
  const { formatDate } = useDateFormatter()

  return [
    {
      label: tBase('user.name'),
      name: 'user.lastName',
      size: 1,
      verticalAlign: 'top',
      renderValue: (withdrawal) => getUserFullName(withdrawal.user),
    },
    {
      label: tShared('payoutMethod'),
      name: 'payoutMethod.id',
      size: 2,
      verticalAlign: 'top',
      renderValue: (withdrawal) => [
        withdrawal.payoutMethod ? (
          <Payout.PayoutMethodLabel payoutMethod={withdrawal.payoutMethod} />
        ) : (
          tShared(`noPayoutMethod.${withdrawal.type.toLowerCase()}`, { reason: withdrawal.metadata?.note })
        ),
        args?.showNote ? (
          withdrawal.metadata?.note ? (
            <PSmaller>{withdrawal.metadata?.note}</PSmaller>
          ) : undefined
        ) : withdrawal.metadata?.invoiceId ? (
          <Link to={`/payment/invoice/${withdrawal?.metadata.invoiceId}`} underline>
            <PSmall>{withdrawal.metadata.note ?? tBase('actions.seeDetails')}</PSmall>
          </Link>
        ) : undefined,
      ],
    },
    {
      label: tShared('amount'),
      name: 'amount',
      headerOptions: { textAlign: 'right' },
      verticalAlign: 'top',
      renderValue: (withdrawal) =>
        money
          .fromAmount(withdrawal.amount, money.currency[withdrawal.fundingAccount.currency])
          .toString({ currencySymbol: 'onlySymbol' }),
    },
    {
      label: tShared('status.title'),
      name: 'status',
      renderValue: (withdrawal) => {
        return <WithdrawalStatusChip withdrawal={withdrawal} />
      },
    },
    {
      label: tShared('createdAt'),
      name: 'createdAt',
      verticalAlign: 'top',
      renderValue: (withdrawal) => formatDate(withdrawal.createdAt),
    },
  ] as const
})
