import { routesBuilder } from '@guiker/shared-framework'

import { Position } from '../../../../../entity'
import { paths } from '../../../paths'

const { buildGet } = routesBuilder<Position>()({
  basePath: paths.stock.withId.portfolio.path,
})

export const routes = {
  readStockPositions: buildGet({
    paginated: true,
  }),
  readStockPositionsCSVData: buildGet({
    path: '/csv',
    response: null as string[][],
  }),
}
