import * as React from 'react'

import { LabeledMonetaryValue, LabeledPercentValue } from '@guiker/base-listing-components'
import { buildNestedListingRoutes, PropSharingListing, SeriesStatus } from '@guiker/propsharing-shared'
import { Button, CardWithMedia, Flex, makeStyles, P, PSmall, RouterLink } from '@guiker/react-framework'

import { useGetHelpers } from '../../hooks'
import { useT } from '../../i18n'
import { PricePerUnit } from '../PricePerUnit'
import { PropSharingListingCardChip } from './PropSharingListingCardChip'

const useStyles = makeStyles(
  () => ({
    container: {
      position: 'relative',
      height: '100%',
    },
  }),
  { name: 'PropSharingListingCard' },
)

export type PropSharingListingCardProps = {
  shadow?: boolean
  listing: PropSharingListing
}

type PropSharingListingCardContent = PropSharingListingCardProps

export const PropSharingListingCardContent: React.FC<PropSharingListingCardProps> = ({ listing }) => {
  const {
    stock: { percentFunded, isFullyFunded, latestSeries, availableEquities },
  } = useGetHelpers(listing)

  const { tMain, MainTrans, tShared } = useT({
    entity: 'listing',
    screenName: 'components.propsharingListingCard',
  })

  const { tShared: tSharedSeries } = useT({
    entity: 'stock',
  })

  const currency = listing.stock.currency

  return (
    <Flex flexDirection='column' gap={3}>
      <Flex alignItems='flex-start' justifyContent='space-between'>
        <Flex flexDirection='column' gap={0.5}>
          <P fontWeight={500} mb={0}>
            {listing.stock?.tickerSymbol}
          </P>
          <PSmall mb={0} color={60}>
            {listing.stock?.label}
          </PSmall>
        </Flex>
        <PricePerUnit listing={listing} />
      </Flex>
      <Flex justifyContent='space-between'>
        {latestSeries.status === SeriesStatus.CLOSED || availableEquities === 0 ? (
          <LabeledMonetaryValue
            label={tSharedSeries('series.final.equity')}
            currencySymbol='onlySymbol'
            value={{
              value: latestSeries.final?.equity || latestSeries.target?.equity,
              currency,
            }}
          />
        ) : (
          <LabeledMonetaryValue
            label={tSharedSeries('availableEquities')}
            currencySymbol='onlySymbol'
            value={{
              value: availableEquities,
              currency,
            }}
          />
        )}
        <LabeledPercentValue
          align='right'
          label={tShared('results.irr')}
          alignItems='flex-end'
          value={listing.stock.property.financialInfo.results.irr}
        />
      </Flex>
      <Flex gap={2} justifyContent='space-between'>
        <PSmall mb={0} mt={0.5}>
          <MainTrans
            i18nKey='percentFundedby'
            values={{
              percent: Math.round(percentFunded * 100),
              investorCount: listing.fundingMetrics.investorCount,
            }}
          >
            <PSmall mb={0} component='span' fontWeight={500} />
          </MainTrans>
        </PSmall>
        {!isFullyFunded && (
          <div>
            <Button size='small'>
              {latestSeries.status === SeriesStatus.COMING_SOON ? tMain('comingSoonSeriesCTA') : tMain('openSeriesCTA')}
            </Button>
          </div>
        )}
      </Flex>
    </Flex>
  )
}

export const PropSharingListingCard: React.FC<PropSharingListingCardProps> = ({ listing, shadow = false }) => {
  const classes = useStyles()

  const {
    stock: { percentFunded, isFullyFunded },
  } = useGetHelpers(listing)

  return (
    <RouterLink to={buildNestedListingRoutes(listing).listing} className={classes.container} fullWidth>
      <CardWithMedia
        shadow={shadow}
        media={{ images: listing.stock.property.pictures as { url: string }[] }}
        progress={!isFullyFunded && percentFunded}
        Chip={(props) => <PropSharingListingCardChip listing={listing} {...props} />}
      >
        <PropSharingListingCardContent listing={listing} />
      </CardWithMedia>
    </RouterLink>
  )
}
