import { idNestedParamsValidator, routesBuilder, validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { PropertyOwnership } from '../../../entity'
import { findOnePropertyOwnershipByPropertyId } from '../schemas'
import { upsertRealOwnerSchema } from '../schemas/upsert-real-owner'
import { paths } from './paths'

const basePath = paths.propertyOwnership.all.withId(':id')
const { buildPut } = routesBuilder<PropertyOwnership>()({ basePath })

export const propertyOwnershipRoutes = {
  updateToRealOwner: buildPut({
    path: '/update-to-real-owner',
    payloadSchema: upsertRealOwnerSchema,
  }),
  readOnePropertyOwnership: {
    path: paths.propertyOwnership.all.withId(),
    method: HttpMethod.GET,
    authenticated: true,
    pathParamsValidator: idNestedParamsValidator,
    responseValidator: validatorBuilder.buildResponseValidator<PropertyOwnership>(),
  },
  readOneActivePropertyOwnershipByPropertyId: {
    path: `${paths.propertyOwnership.all.base()}/property`,
    method: HttpMethod.GET,
    authenticated: false,
    queryParamsValidator: (payload: unknown) => findOnePropertyOwnershipByPropertyId.validate(payload),
    responseValidator: validatorBuilder.buildResponseValidator<PropertyOwnership>(),
  },
}
