import { BaseRestClient, Headers, Logger, RequestOptions, Response } from '@guiker/base-rest-client'
import { v4 as uuidv4 } from '@guiker/uuid'

import { headerManager } from './header-manager'

export { Headers, Logger, Response, RequestOptions }

export type ApiClientArguments = {
  baseUrl: string
  accessToken?: string
  correlatedRequestId?: string
  logger?: Logger
}

type RestClientArgs = {
  url: string
  options?: RequestOptions
}
type RestClientArgsWithPayload = RestClientArgs & { payload: unknown }

type RestClient = {
  updateAccessToken: (accessToken: string) => void
  get: <T>(args: RestClientArgs) => Promise<Response<T>>
  post: <T>(args: RestClientArgsWithPayload) => Promise<Response<T>>
  patch: <T>(args: RestClientArgsWithPayload) => Promise<Response<T>>
  put: <T>(args: RestClientArgsWithPayload) => Promise<Response<T>>
  delete: <T>(args: RestClientArgs) => Promise<Response<T>>
}

const getHeaders = (accessToken: string) => {
  return accessToken
    ? {
        ...headerManager.authorizationBearer.build(accessToken),
        ...headerManager.accessToken.build(accessToken),
      }
    : {}
}

export const getRestClient = ({
  baseUrl,
  accessToken,
  correlatedRequestId = uuidv4(),
  logger,
}: ApiClientArguments): RestClient => {
  const baseApiClient = new BaseRestClient(logger, correlatedRequestId, getHeaders(accessToken))

  const fullUrl = (baseUrl: string, url: string): string => {
    return `${baseUrl}${url.startsWith('/') ? '' : '/'}${url}`
  }

  return {
    updateAccessToken: (accessToken: string) => {
      baseApiClient.updateHeaders(getHeaders(accessToken))
    },
    get: <T>(args: RestClientArgs) => {
      return baseApiClient.get<T>(fullUrl(baseUrl, args.url), args.options)
    },
    post: <T>(args: RestClientArgsWithPayload) => {
      return baseApiClient.post<T>(fullUrl(baseUrl, args.url), args.payload, args.options)
    },
    patch: <T>(args: RestClientArgsWithPayload) => {
      return baseApiClient.patch<T>(fullUrl(baseUrl, args.url), args.payload, args.options)
    },
    put: <T>(args: RestClientArgsWithPayload) => {
      return baseApiClient.put<T>(fullUrl(baseUrl, args.url), args.payload, args.options)
    },
    delete: <T>(args: RestClientArgs) => {
      return baseApiClient.delete<T>(fullUrl(baseUrl, args.url), args.options)
    },
  }
}
