import { idNestedParamsValidator } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { PropSharingListing, WithInquiry } from '../../entity'
import { inquiryRoutes } from './inquiry-routes'
import { investorProfileRoutes } from './investor-profile-routes'
import { paths } from './paths'
import { routes as portfolioRoutes } from './portfolio-routes'
import { stockOrderRoutes } from './stock-order-routes'

export const routes = {
  readOneListing: {
    path: paths.listing.all.withId(),
    method: HttpMethod.GET,
    pathParamsValidator: idNestedParamsValidator,
    responseValidator: (res: unknown) => Promise.resolve(res as WithInquiry<PropSharingListing>),
  },
  ...portfolioRoutes,
  ...inquiryRoutes,
  ...inquiryRoutes,
  ...investorProfileRoutes,
  ...stockOrderRoutes,
}
