import React from 'react'

import { BuildingTypes, NEWProperty } from '@guiker/real-estate-shared'

import { MixedUnitConfig } from './mixedUnitConfig'
import { MultiUnitConfig } from './multiUnitConfig'
import { SingleUnitConfig } from './singleUnitConfig'
import { WholeBuildingConfig } from './wholeBuildingConfig'

type UnitConfigProps = {
  property: NEWProperty
}

export const UnitConfig: React.FC<UnitConfigProps> = ({ property }) => {
  if (!property || !property.building || !property.units) return <></>
  return (
    <>
      {!property.units?.length ? (
        <WholeBuildingConfig property={property} />
      ) : property.building.type !== BuildingTypes.COMMERCIAL && property.units?.length === 1 ? (
        <SingleUnitConfig unit={property.units[0]} buildingType={property.building.type} />
      ) : property.building.type === BuildingTypes.MIXED && property.units?.length >= 1 ? (
        <MixedUnitConfig units={property.units} property={property} />
      ) : (
        <MultiUnitConfig units={property.units} buildingType={property.building?.type} />
      )}
    </>
  )
}
