import { ValueOf } from '@guiker/ts-utils'

export const Frequency = {
  yearly: 'yearly',
  monthly: 'monthly',
} as const
export type Frequency = ValueOf<typeof Frequency>

export const CalculationMethodTypes = {
  percentage: 'percentage',
  flat: 'flat',
  flatYearly: 'flat-yearly',
  bracket: 'bracket',
} as const
export type CalculationMethodTypes = ValueOf<typeof CalculationMethodTypes>

export const PercentageBase = {
  rentalIncome: 'rentalIncome',
  purchasePrice: 'purchasePrice',
  assessedValue: 'assessedValue',
  maxPropertyValue: 'maxPropertyValue',
} as const
export type PercentageBase = ValueOf<typeof PercentageBase>

export type PercentageValue<Base extends PercentageBase = PercentageBase> = {
  type: 'percentage'
  rate: number
  base: Base
  exemption?: number
}

export type FlatValue = {
  type: 'flat'
  yearlyGrowthRate?: number
  amount: number
  frequency?: Frequency
}

export type FlatYearlyValue = {
  type: 'flat-yearly'
  amount: number[]
}

export type Bracket = {
  rate: number
  threshold?: number
}

export type BracketValue<Base extends PercentageBase = PercentageBase> = {
  type: 'bracket'
  base: Base
  brackets: Bracket[] | readonly Bracket[]
}

export type CalculationMethod = PercentageValue | FlatValue | FlatYearlyValue | BracketValue

export type CalculationItem<CM extends CalculationMethod = CalculationMethod> = {
  value: CM
}

export type Revenue<CM extends CalculationMethod = CalculationMethod> = CalculationItem<CM>
export type Tax<CM extends CalculationMethod = CalculationMethod> = CalculationItem<CM>
export type Cost<CM extends CalculationMethod = CalculationMethod> = CalculationItem<CM>

export interface ComputedExpense<Type extends string = string> extends CalculationItem {
  type: Type
  computedValue: number
}
