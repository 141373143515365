import { generateEventsDefinition } from '@guiker/shared-framework'

import { PropSharingListing, PropSharingListingWriteModel } from '../../entity'
import { baseEvent } from '../base'

export const { FractionalOwnershipListingEventsDefinition: PropSharingListingEventsDefinition } =
  generateEventsDefinition({
    ...baseEvent,
    entity: 'FRACTIONAL_OWNERSHIP_LISTING',
    data: null as unknown as PropSharingListing,
    eventTypes: {
      DISPLAY_INFORMATION_UPDATED: 'DISPLAY_INFORMATION_UPDATED',
      PROPERTY_UPDATED: 'PROPERTY_UPDATED',
      FINANCIAL_ASSUMPTIONS_UPDATED: 'FINANCIAL_ASSUMPTIONS_UPDATED',
      PUBLISHED: 'PUBLISHED',
      UNPUBLISHED: 'UNPUBLISHED',
    },
    write: {
      data: null as unknown as PropSharingListingWriteModel,
    },
  })
