import React, { useEffect, useState } from 'react'

import { addressFormatter, DEFAULT_NEIGHBOURHOOD, Geocoordinates, getLocationService } from '@guiker/base-entity'
import { Address } from '@guiker/base-entity'
import { clsx, TextField, TextFieldProps, useUtilityStyle } from '@guiker/components-library'
import { Autocomplete } from '@guiker/react-google-maps'
import { safelyGetWindow } from '@guiker/react-utils'

export type SearchAddress = {
  address: Omit<Address, 'postalCode'> & {
    zipcode?: string
    postalCode?: string
    operatingCity?: string
    neighbourhood?: string
  }
  geocoordinates: Geocoordinates
  externalId: string
}

type SearchAddressProps = Omit<TextFieldProps, 'onChange'> & {
  fields?: string[]
  countryRestrictions?: string[]
  stateRestrictions?: string[]
  onChange: (externalAddress: SearchAddress) => void
}

const BaseTextField: React.FC<TextFieldProps> = (props) => {
  return <TextField placeholder='' type='search' inputProps={{ autoComplete: 'disabled' }} {...props} />
}

const GoogleSearchAddress: React.FC<SearchAddressProps> = (props) => {
  const utilityStyles = useUtilityStyle(props)

  const { fields, countryRestrictions, defaultValue, disabled, onChange, className, ...textFieldProps } = props
  const [inputValue, setInputValue] = useState(defaultValue)
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>(null)

  const onLoad = (autocompleteInstance: google.maps.places.Autocomplete) => {
    setAutocomplete(autocompleteInstance)
  }

  useEffect(() => {
    setInputValue(defaultValue)
  }, [defaultValue])

  const onPlaceChanged = () => {
    if (autocomplete) {
      const foundAddress = {
        address: {},
      } as SearchAddress
      const place = autocomplete.getPlace()
      const addressComponents = place.address_components ?? []

      foundAddress.externalId = place.place_id

      // Place types https://developers.google.com/maps/documentation/places/web-service/supported_types
      addressComponents.forEach(({ types, short_name, long_name }) => {
        if (types.includes('postal_code')) {
          foundAddress.address.zipcode = long_name
        }
        if (types.includes('country')) {
          foundAddress.address.country = short_name
        }
        if (types.includes('administrative_area_level_1')) {
          foundAddress.address.state = short_name
        }
        if (types.includes('locality') || types.includes('sublocality')) {
          foundAddress.address.city = long_name
          foundAddress.address.cityName = long_name
        }
        if (types.includes('route')) {
          foundAddress.address.street = long_name
        }
        if (types.includes('street_number')) {
          foundAddress.address.streetNumber = long_name
        }
      })

      foundAddress.geocoordinates = place.geometry
        ? { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
        : null

      const location = getLocationService().getLocationFromAddress({
        ...foundAddress.address,
        postalCode: foundAddress.address.zipcode,
      })

      if (location?.city) {
        foundAddress.address.neighbourhood = location.neighbourhood?.slug
        foundAddress.address.operatingCity = location.city.slug
      } else {
        foundAddress.address.neighbourhood = DEFAULT_NEIGHBOURHOOD
        foundAddress.address.operatingCity = undefined
      }

      if (addressComponents.length) {
        setInputValue(addressFormatter.printShortAddress(foundAddress.address as Address))
        onChange(foundAddress)
      }
    }
  }

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      restrictions={countryRestrictions && { country: countryRestrictions }}
      className={clsx(className, utilityStyles.maxWidth)}
    >
      <BaseTextField
        {...textFieldProps}
        disabled={disabled}
        defaultValue={defaultValue}
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
      />
    </Autocomplete>
  )
}

const DefaultSearchAddress: React.FC<SearchAddressProps> = (props) => {
  const { fields, countryRestrictions, onChange, ...textFieldProps } = props

  return <BaseTextField {...textFieldProps} />
}

export const SearchAddress: React.FC<SearchAddressProps> = ({
  fields = ['address_component'],
  countryRestrictions,
  defaultValue = '',
  ...otherProps
}) => {
  const [isSearchable, setIsSearchable] = useState(false)
  const google = safelyGetWindow()?.google
  const props = { fields, countryRestrictions, defaultValue, ...otherProps }

  useEffect(() => {
    setIsSearchable(!!google?.maps)
  }, [google])

  return isSearchable ? <GoogleSearchAddress {...props} /> : <DefaultSearchAddress {...props} />
}
