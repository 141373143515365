import { capitalize } from 'lodash'
import { default as slugify } from 'slugify'

const slugifyLowercase = (str: string) =>
  slugify(str, {
    lower: true,
    strict: true,
  })

export const unslugify = (str: string, shouldCapitalize = false) => {
  const unslugified = str.replace(/-/g, ' ')

  if (shouldCapitalize) {
    return capitalize(unslugified)
  }

  return unslugified
}

export { slugify, slugifyLowercase }
