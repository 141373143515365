import React from 'react'

import { AssetUploader } from '@guiker/asset-uploader'

type Props = {
  readOnly: boolean
}

const Pictures: React.FC<Props> = ({ readOnly }) => {
  return <AssetUploader readOnly={readOnly} withThumbnail name='pictures' displayDirection='row' maxSizeMB={20} />
}

export { Pictures }
