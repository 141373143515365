import React from 'react'

import { FormSection2, PercentNumberFormat, ThreeColumnsGridLayout } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { FlatValueInput } from '../FlatValue'

type FinancialBaseFormContentProps = {}

export const FinancialBaseFormContent: React.FC<FinancialBaseFormContentProps> = () => {
  const { tShared } = useT({ entity: 'property.financialInfo.financials' })

  return (
    <>
      <ThreeColumnsGridLayout>
        <FlatValueInput
          name='financialInfo.financials.purchase.price'
          label={tShared('purchase.price')}
          withYearlyGrowthRate={false}
        />
        <FlatValueInput
          name='financialInfo.financials.purchase.assessedValue'
          label={tShared('purchase.assessedValue')}
          withYearlyGrowthRate={false}
        />
        <PercentNumberFormat
          name={'financialInfo.financials.base.yearlyGrowthRate'}
          condensed
          label={tShared('base.yearlyGrowthRate')}
        />
      </ThreeColumnsGridLayout>
    </>
  )
}

export const FinancialBaseFormSection: React.FC<FinancialBaseFormContentProps> = () => {
  const { tComponents } = useT({ componentName: 'FinancialBaseFormSection' })

  return (
    <FormSection2 title={tComponents('title')}>
      <FinancialBaseFormContent />
    </FormSection2>
  )
}
