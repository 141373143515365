import React, { Component } from 'react'

import { flattenObject } from '@guiker/lodash'

import { FunctionalErrorBoundary } from '.'

class ErrorBoundary extends Component<React.PropsWithChildren> {
  state: {
    error: Error | undefined
    errorInfo: any
    hasError: boolean
    eventId: string
  }

  constructor(props) {
    super(props)
    this.state = { error: undefined, errorInfo: null, hasError: false, eventId: '' }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({
      error,
      errorInfo: flattenObject(errorInfo),
      hasError: true,
    })
  }

  render() {
    return this.state.hasError ? <FunctionalErrorBoundary {...this.state} /> : this.props.children
  }
}

export { ErrorBoundary }
