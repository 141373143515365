import React from 'react'

import { Box, FormSection1, useWatch } from '@guiker/react-framework'
import { money } from '@guiker/shared-framework'

import { FinancialInfoContextProvider } from '../../context'
import { FinancialBaseFormSection } from './FinancialBase'
import { FinancialExitFormSection } from './FinancialExit'
import { FinancialFinancingFormSection } from './FinancialFinancing'
import { FinancialNonOperatingFormSection } from './FinancialNonOperating'
import { FinancialOperationFormSection } from './FinancialOperation'
import { FinancialPurchaseFormSection } from './FinancialPurchase'
import { FloatingFinancialKPIs } from './FinancialsKPIs'

type PropertyFinancialFormContentProps = {}

export const PropertyFinancialFormContent: React.FC<PropertyFinancialFormContentProps> = () => {
  const { financials } = useWatch({ name: 'financialInfo' })

  return (
    <FinancialInfoContextProvider financials={financials} currency={money.currency.CAD}>
      <FormSection1 hasDivider>
        <FinancialBaseFormSection />
        <FinancialFinancingFormSection />
        <FinancialPurchaseFormSection />
        <FinancialOperationFormSection />
        <FinancialNonOperatingFormSection />
        <FinancialExitFormSection />
      </FormSection1>
      <Box mb={12} />
      <FloatingFinancialKPIs />
    </FinancialInfoContextProvider>
  )
}
