export enum DealType {
  PRECON = 'pre-construction',
  NEW = 'new',
  RESALE = 'resale',
}

export type PreConstructionDeal = {
  type: DealType.PRECON
}

export type NewDeal = {
  type: DealType.NEW
}

export type ResaleDeal = {
  type: DealType.RESALE
}

export type Deal = {
  type: DealType
}
