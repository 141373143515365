import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { computeInvestmentResult, FinancialInfo } from '@guiker/base-entity'
import { Currency } from '@guiker/money'
import { throttle } from '@guiker/react-throttle-debounce'

import { findMinAndMax, findMinAndMaxWithRange, MinMax } from '../../utils'

type FinancialInfoContext = {
  financials: FinancialInfo.Financials
  results: FinancialInfo.Results
  currency: Currency
  setInvestmentFinancials: (financials: FinancialInfo.Financials) => void
  rentalGrowthRateMinMax: MinMax
  exitRateMinMax: MinMax
  rentalIncomeMinMax: MinMax
}

type Props = React.PropsWithChildren & {
  financials: FinancialInfo.Financials
  currency: Currency
}

export const FinancialInfoContext = createContext<FinancialInfoContext>(null)

export const FinancialInfoContextProvider: React.FC<Props> = ({ children, currency, financials }) => {
  const [results, setResults] = useState<FinancialInfo.Results>(useMemo(() => computeInvestmentResult(financials), []))

  const { rentalIncomeMinMax, rentalGrowthRateMinMax, exitRateMinMax } = useMemo(
    () => ({
      rentalIncomeMinMax: findMinAndMax(financials.operation.revenues.rentalIncome.value.amount, 0.5),
      rentalGrowthRateMinMax: findMinAndMaxWithRange(
        financials.operation.revenues.rentalIncome.value.yearlyGrowthRate,
        0.25,
        0.05,
      ),
      exitRateMinMax: findMinAndMaxWithRange(financials.exit.rate, 0.25, 0.05),
    }),
    [
      financials.operation.revenues.rentalIncome.value.yearlyGrowthRate,
      financials.exit.rate,
      financials.operation.revenues.rentalIncome.value.amount,
    ],
  )

  const debouncedComputation = useCallback(
    throttle(10, (financials) => setResults(computeInvestmentResult(financials))),
    [JSON.stringify(financials)],
  )

  useEffect(() => debouncedComputation(financials), [JSON.stringify(financials)])

  const value = {
    currency,
    results,
    financials,
    setInvestmentFinancials: () => {},
    rentalGrowthRateMinMax,
    exitRateMinMax,
    rentalIncomeMinMax,
  }

  return <FinancialInfoContext.Provider value={value}>{children}</FinancialInfoContext.Provider>
}

export const useFinancialInfoContext = () => {
  const context = useContext(FinancialInfoContext)

  if (context === undefined) {
    throw new Error('useFinancialInfoContext can only be used inside FinancialInfoContextProvider')
  }

  return context
}
