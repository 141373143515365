import React from 'react'

import { addressFormatter, CountryCode, getLocationService } from '@guiker/base-entity'
import { PageSection2, TwoColumnsGridLayout } from '@guiker/components-library'
import { getLocalizedCity, getLocalizedNeighbourhood, getLocalizedState, useT } from '@guiker/i18n'
import { get, useFormContext, useWatch } from '@guiker/react-hook-form'
import { useGetInputPropsWithUseT } from '@guiker/react-utils'
import { Dropdown, TextField } from '@guiker/rhf-components'

import { CountryDropdown } from '../CountryDropdown'
import { SearchAddress } from '../SearchAddress'

type SearchAddressWithFieldsProps = {
  concatWhenReadOnly?: boolean
  countryRestrictions?: CountryCode[]
  label?: string
  name: string
  onAddressChange?: (adress: SearchAddress) => unknown
  fieldRestrictions?: string[]
  runDefaultAddressChange?: boolean
  readOnly?: boolean
  withZipcode?: boolean
  withOperatingCity?: boolean
  withSelectableCountry?: boolean
}

const isFieldDisplayable = (fieldRestrictions: string[], field: string) =>
  !fieldRestrictions.length || fieldRestrictions.includes(field)

export const SearchAddressWithFields: React.FC<SearchAddressWithFieldsProps> = ({
  concatWhenReadOnly = true,
  countryRestrictions,
  label,
  name,
  onAddressChange: propsOnAddressChange,
  runDefaultAddressChange = true,
  fieldRestrictions = [],
  readOnly: readOnlyProps,
  withOperatingCity = false,
  withSelectableCountry = false,
  withZipcode = false,
}) => {
  const { t, tBase, tShared } = useT({
    domain: 'components',
    basePrefix: 'address',
    entity: 'addressComponent',
  })
  const { setValue, formState, readOnly } = useFormContext()
  const address = useWatch({ name }) || {}

  const onAddressChange = ({ address, externalId, geocoordinates }: SearchAddress) => {
    const containerFieldName = name.split('.')
    const addressFieldName = containerFieldName.pop()

    if (runDefaultAddressChange) {
      if (containerFieldName.length > 0) {
        setValue(
          containerFieldName.join('.'),
          {
            externalId,
            geocoordinates,
            [addressFieldName]: address,
          },
          {
            shouldDirty: true,
            shouldValidate: true,
          },
        )
      } else {
        setValue('externalId', externalId, { shouldDirty: true })
        setValue('geocoordinates', geocoordinates)

        Object.entries(address).forEach(([key, value]) => {
          setValue(`${addressFieldName}.${key}`, value)
        })
      }
    }

    propsOnAddressChange?.({ externalId, geocoordinates, address })
  }

  const getInputProps = useGetInputPropsWithUseT<{ [key in typeof name]: SearchAddress['address'] }>()({
    t: tBase,
    formPrefix: name,
  } as const)

  if (readOnly && concatWhenReadOnly) {
    return (
      <TextField
        name={`${name}.fullAddress`}
        value={addressFormatter.printFullAddress(address)}
        label={label || tShared('label')}
        maxWidth='100%'
        readOnly
      />
    )
  }

  const { country, state, city, operatingCity } = address
  const errors = get(formState.errors, name)
  const zipcode = withZipcode ? 'zipcode' : 'postalCode'

  return (
    <PageSection2>
      <TwoColumnsGridLayout>
        {!(readOnly || readOnlyProps) && (
          <>
            <SearchAddress
              error={!!errors}
              errorMessage={
                !!errors ? (errors.type === 'AddressMismatch' ? errors.message : t('errors:required')) : undefined
              }
              readOnly={readOnly || readOnlyProps}
              label={label || tShared('label')}
              onChange={onAddressChange}
              countryRestrictions={countryRestrictions || country}
              required
            />
            {withSelectableCountry ? (
              <CountryDropdown {...getInputProps('country')} supportedCountries={countryRestrictions} required />
            ) : (
              <div />
            )}
          </>
        )}
        {address && (
          <>
            {isFieldDisplayable(fieldRestrictions, 'streetNumber') && (
              <TextField {...getInputProps('streetNumber')} readOnly={true} />
            )}
            {isFieldDisplayable(fieldRestrictions, 'street') && (
              <TextField {...getInputProps('street')} readOnly={true} />
            )}
            {isFieldDisplayable(fieldRestrictions, 'city') && <TextField {...getInputProps('city')} readOnly={true} />}
            {withOperatingCity && isFieldDisplayable(fieldRestrictions, 'operatingCity') && (
              <Dropdown
                {...getInputProps('operatingCity')}
                options={
                  country &&
                  state &&
                  Object.values(getLocationService().getCitiesByState(country, state) || []).map((city) => ({
                    value: city.slug,
                    label: getLocalizedCity(t, { countryCode: country, citySlug: city.slug }),
                  }))
                }
              />
            )}
            {isFieldDisplayable(fieldRestrictions, 'state') && (
              <TextField
                {...getInputProps('state')}
                readOnly={true}
                formatValue={(value) =>
                  getLocalizedState(t, {
                    countryCode: country,
                    stateCode: value,
                  })
                }
              />
            )}
            {isFieldDisplayable(fieldRestrictions, zipcode) && (
              <TextField {...getInputProps(zipcode)} readOnly={true} />
            )}
            {withOperatingCity && isFieldDisplayable(fieldRestrictions, 'neighbourhood') && (
              <TextField
                {...getInputProps('neighbourhood')}
                readOnly={true}
                formatValue={(value) =>
                  getLocalizedNeighbourhood(t, {
                    countryCode: country,
                    citySlug: operatingCity || city,
                    neighbourhoodSlug: value,
                  })
                }
              />
            )}
          </>
        )}
      </TwoColumnsGridLayout>
    </PageSection2>
  )
}
