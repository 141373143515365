import React, { useMemo, useState } from 'react'

import { Carousel, Gallery } from '@guiker/picture-gallery-components'
import { PropertySaleListing } from '@guiker/property-sale-shared'

type ListingPictures = {
  listing: PropertySaleListing
  showGallery?: boolean
}

export const ListingPictures: React.FC<ListingPictures> = ({ listing, showGallery = true }) => {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false)
  const openCarousel = () => setIsCarouselOpen(true)
  const closeCarousel = () => setIsCarouselOpen(false)

  const pictures = useMemo(() => {
    if (!listing) return []

    return listing.pictures.map((p) => ({ url: p.url }))
  }, [listing?.pictures])

  return (
    <div>
      <Gallery pictures={showGallery ? pictures : [pictures[0]]} onClick={showGallery && openCarousel} />
      {showGallery && <Carousel pictures={pictures} open={isCarouselOpen} closeModal={closeCarousel} />}
    </div>
  )
}
