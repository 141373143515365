import { TypeOf, yup } from '@guiker/shared-framework'

const pictureSchema = yup.object({ id: yup.string().required(), label: yup.string().nullable() })
const documentSchema = yup.object({ id: yup.string().required(), displayName: yup.string().nullable() })
const picturesSchema = yup.array().of(pictureSchema)
const documentsSchema = yup.array().of(documentSchema)

export const updatePropertyDetails = yup
  .object({
    property: yup.object({
      description: yup.string().nullable(),
      pictures: picturesSchema.min(1).required(),
      documents: documentsSchema.optional(),
    }),
  })
  .required()

export type UpdatePropertyDetails = TypeOf<typeof updatePropertyDetails>
