import { StatusCodes } from '@guiker/http'
import { routesBuilder } from '@guiker/shared-framework'

import { Stock, StockOrderStats, StockWriteModel } from '../../../entity'
import { paths } from '../paths'
import { createStock } from '../schemas'

const { buildPost, buildGet } = routesBuilder<Stock, StockWriteModel>()({
  basePath: paths.stocks.path,
})

export const routes = {
  getStatsOverview: buildGet({
    path: '/stats-overview',
    response: null as StockOrderStats,
    responseIsArray: true,
  }),
  createStock: buildPost({
    successStatusCode: StatusCodes.CREATED,
    payloadSchema: createStock,
  }),
  readAllStocks: buildGet({
    paginated: true,
  }),
}
