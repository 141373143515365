import { routesBuilder } from '@guiker/shared-framework'

import { StockOrder, StockOrderWriteModel } from '../../../../../../entity'
import { paths } from '../../../../paths'

const { buildPut, buildGet } = routesBuilder<StockOrder, StockOrderWriteModel>()({
  basePath: paths.stock.withId.stockOrders.withId.path,
})

export const routes = {
  cancelReservedIpoStockOrder: buildPut({
    path: '/cancel',
    responseIsArray: true,
  }),
  forceOfflinePaymentStockOrder: buildPut({
    path: '/force-offline-payment',
  }),
  readOneStockOrder: buildGet(),
}
