import React, { useState } from 'react'

import { addressFormatter } from '@guiker/base-entity'
import { CountryDropdown } from '@guiker/country-dropdown'
import {
  ColumnGridItem,
  ColumnGridLayout,
  SearchAddress,
  TextField,
  useFormContext,
  useGetInputProps,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'
import { compact, yup } from '@guiker/shared-framework'
import { BaseAddress } from '@guiker/tenant-application-shared'

import { hasCompleteAddress } from '../../helper'

type AddressFormProps = {
  defaultValue: BaseAddress
  dependency?: Record<string, string | boolean | number>
  formPrefix?: string
  schema?: yup.ObjectSchema
  readOnly?: boolean
  disabled?: boolean
  className?: string
  label?: string
}

const AddressForm: React.FC<AddressFormProps> = ({
  defaultValue,
  dependency,
  formPrefix,
  schema,
  readOnly = false,
  disabled = false,
  className,
  label,
}) => {
  const namespaces = ['common', 'main-tenantApplication']
  const { t } = useTranslation(namespaces)
  const { setValue, formState } = useFormContext()
  const value = useWatch({ name: formPrefix, defaultValue })
  const [addressSearched, setAddressSearched] = useState(false || !!defaultValue)

  const hasFullAddress = value && hasCompleteAddress(value)

  const addressInputProps = useGetInputProps({
    namespaces,
    formPrefix,
    tPrefix: 'common:address',
    defaultValue,
    schema,
    schemaPrefix: formPrefix,
    readOnly,
    style: {
      mb: 0,
      maxWidth: '100%',
    },
  })

  const countryInputProps = {
    ...addressInputProps('country', { dependency }),
    defaultValue: value?.country,
  }

  if (readOnly && hasFullAddress) {
    return (
      <TextField
        value={addressFormatter.printFullAddress({ ...value, street: value?.streetName })}
        name={`${formPrefix}.fullAddress`}
        label={label || t('common:address.label')}
        maxWidth='100%'
        readOnly
        multiline
      />
    )
  }

  const onAddressChange = ({ address }: SearchAddress) => {
    setValue(
      formPrefix,
      { ...defaultValue, ...address, streetName: address.street },
      { shouldDirty: true, shouldValidate: true },
    )

    setAddressSearched(true)
  }

  const hasError =
    formState.errors?.[formPrefix] &&
    (formState.errors[formPrefix]['streetName'] ||
      formState.errors[formPrefix]['streetNumber'] ||
      formState.errors[formPrefix]['city'] ||
      formState.errors[formPrefix]['state'] ||
      formState.errors[formPrefix]['postalCode'])

  return (
    <div className={className}>
      <ColumnGridLayout
        xs={{ gridTemplateColumns: 1, gridColumn: { span: 1 } }}
        md={{ gridTemplateColumns: 12, gridColumn: { start: 'auto', span: 6 } }}
      >
        <ColumnGridItem md={8}>
          <SearchAddress
            disabled={disabled}
            error={hasError}
            errorMessage={hasError && t('main-tenantApplication:addressForm.searchAddress.error')}
            readOnly={readOnly}
            label={label || t('main-tenantApplication:addressForm.searchAddress.label')}
            onChange={onAddressChange}
            countryRestrictions={compact([value?.country])}
            defaultValue={
              defaultValue && addressFormatter.printShortAddress({ ...defaultValue, street: defaultValue.streetName })
            }
            required
          />
        </ColumnGridItem>
        <ColumnGridItem md={{ start: 'auto', span: 4 }}>
          <CountryDropdown disabled={disabled} {...countryInputProps} />
        </ColumnGridItem>
        <ColumnGridItem md={{ start: 'auto', span: 4 }}>
          <TextField disabled={disabled || !addressSearched} {...addressInputProps('streetNumber', { dependency })} />
        </ColumnGridItem>
        <ColumnGridItem md={{ start: 'auto', span: 8 }}>
          <TextField disabled={disabled || !addressSearched} {...addressInputProps('streetName', { dependency })} />
        </ColumnGridItem>
        <ColumnGridItem md={{ start: 'auto', span: 4 }}>
          <TextField disabled={disabled || !addressSearched} {...addressInputProps('suite', { dependency })} />
        </ColumnGridItem>
        <ColumnGridItem md={{ start: 'auto', span: 8 }}>
          <TextField disabled={disabled || !addressSearched} {...addressInputProps('city', { dependency })} />
        </ColumnGridItem>
        <TextField disabled={disabled || !addressSearched} {...addressInputProps('state', { dependency })} />
        <TextField disabled={disabled || !addressSearched} {...addressInputProps('postalCode', { dependency })} />
      </ColumnGridLayout>
    </div>
  )
}

export { AddressForm }
