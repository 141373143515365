export enum EstimatedSalePriceCalculationMethod {
  APPRECIATION_RATE = 'APPRECIATION_RATE',
  CAP_RATE = 'CAP_RATE',
}

export type Exit = {
  estimatedSalePriceCalculationMethod: EstimatedSalePriceCalculationMethod
  holdingPeriod: number
  rate: number
}
