import { FlatValue, PercentageValue } from '../calculation-item'

export const OperationRevenues = {
  rentalIncome: {
    value: {
      type: 'flat',
      amount: 0,
      yearlyGrowthRate: 0.03,
    } as FlatValue,
  },
  vacancyRate: {
    value: {
      type: 'percentage',
      rate: 0.03,
    } as PercentageValue,
  },
} as const
