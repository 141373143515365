import { TFunction } from '@guiker/i18n'
import { NEWBuilding, NEWProperty, NEWUnit, Orientation, UnitType } from '@guiker/real-estate-shared'

export const findTotalSquareFootage = (units: NEWUnit[]): number =>
  units?.reduce((sum, unit) => sum + unit.squareFootage, 0)

export const findTotalNumberOfUnitType = (units: NEWUnit[], type: UnitType): number =>
  units?.filter((unit) => unit.type === type).length

export const totalNumberOfUnits = (property: NEWProperty): number => {
  const totalCalculatedUnits =
    findTotalNumberOfUnitType(property.units, UnitType.COMMERCIAL) +
    findTotalNumberOfUnitType(property.units, UnitType.RESIDENTIAL)
  return totalCalculatedUnits
}

export const showTotalNumberOfUnits = (property: NEWProperty): boolean => totalNumberOfUnits(property) > 0

export const showTotalStoreys = (building: NEWBuilding): boolean =>
  building.configuration?.totalStoreys && building.configuration.totalStoreys > 0

export const showBuildingOrientation = (building: NEWBuilding): boolean =>
  building.configuration?.orientation && building.configuration.orientation !== Orientation.UNKNOWN

export const showTotalSquareFootage = (property: NEWProperty): boolean => {
  return property.units?.length > 0 && findTotalSquareFootage(property.units) > 0
}

export const printRoomConfiguration = ({ unit, t }: { t: TFunction; unit: NEWUnit }) => {
  const bedroomCount = (unit as NEWUnit).floorplan.bedroom
  const bathroomCount = (unit as NEWUnit).floorplan.bathroom

  const bedroomCountLabel =
    bedroomCount === 0
      ? t('listing:bedroomCount.studio')
      : t('listing:bedroomCount.nonStudio', {
          count: bedroomCount,
        })

  const bathroomCountLabel = t('listing:bathroomCount', {
    count: bathroomCount,
  })

  const roomCountLabel = `${bedroomCountLabel}${!!bathroomCount ? ` · ${bathroomCountLabel}` : ''}`
  return unit.squareFootage ? `${roomCountLabel} · ${unit.squareFootage} sq.ft` : roomCountLabel
}
